import React, { useState, useEffect } from 'react';
import { Box, Stack, Input, Button, Text, Flex, FormControl, FormErrorMessage, useToast } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom'; // Для извлечения токена из URL

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const PasswordChangePage = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [token, setToken] = useState('');
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate(); 

  useEffect(() => {
    // Предполагается, что URL будет иметь вид /password-reset/<token>
    const tokenFromUrl = location.pathname.split('/password-reset/')[1];
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    }
  }, [location]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    checkPasswordMatch(e.target.value, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    checkPasswordMatch(password, e.target.value);
  };

  const checkPasswordMatch = (password, confirmPassword) => {
    setIsPasswordMatch(password === confirmPassword);
  };

  const updatePassword = async () => {
    try {
        const response = await fetch(`${BACKEND_DOMAIN}/auth/change-forgotten-password`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token, // Отправка токена
                newPassword: password,
              }),
      });
      if (response.ok) {
        const data = await response.json();
        // Покажите зеленое уведомление о успешном изменении пароля
        toast({
          title: "Success",
          description: "Your password has been successfully set.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        // Дополнительная логика после успешного изменения пароля, если требуется
        navigate('/login');
       } else {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to change password');
       }
    } catch (error) {
        let errorMessage = "An error has occurred."; // Сообщение по умолчанию
        if (error.response && error.response.data && error.response.data.message) {
          // Пытаемся получить сообщение об ошибке из ответа сервера
          errorMessage = error.response.data.message;
        } else if (error.message) {
          // Или используем сообщение ошибки из самого исключения
          errorMessage = error.message;
        }
        toast({
          title: "Error",
          description: errorMessage,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
  };

  const handleChangePassword = async () => {
    if (!isPasswordMatch || !password || !confirmPassword) {
      toast({
        title: "Error",
        description: "Please check the passwords.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    // Вызываем функцию обновления пароля
    await updatePassword();
  };

  return (
    <Flex direction="column">
      <Box flex="1" textAlign="center" p="10">
        <Stack spacing="6" maxW="md" mx="auto">
          <Text color="blue.500" fontSize="md" mb="4">Create a new password:</Text>
          <Input
            placeholder="New Password"
            variant="filled"
            mb="1"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            borderColor={password && confirmPassword ? (isPasswordMatch ? 'green.500' : 'red.500') : null}
          />
          <FormControl isInvalid={!isPasswordMatch && password && confirmPassword}>
            <Input
              placeholder="Repeat New Password"
              variant="filled"
              mb="1"
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              borderColor={password && confirmPassword ? (isPasswordMatch ? 'green.500' : 'red.500') : null}
            />
            {!isPasswordMatch && <FormErrorMessage>Password doesn't match</FormErrorMessage>}
          </FormControl>
          <Button colorScheme="yellow" size="lg" mb="2" onClick={handleChangePassword}>
          Set new password now
          </Button>
        </Stack>
      </Box>
    </Flex>
  );
};

export default PasswordChangePage;


