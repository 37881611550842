import React, { useState, useEffect } from 'react';
import { Box, VStack, Text, Heading, Select, Spinner } from '@chakra-ui/react';
import SmsButton from '../../components/buttons/SmsButton';
import WhatsappButton from '../../components/buttons/WhatsappButton';
import TelegramButton from '../../components/buttons/TelegramButton';
import VkButton from '../../components/buttons/VkButton';
import InstagramButton from '../../components/buttons/InstagramButton';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const ButtonsTab = () => {
  const [actions, setActions] = useState([]);
  const [phoneContacts, setPhoneContacts] = useState([]);
  const [nicknameContacts, setNicknameContacts] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [selectedMessage, setSelectedMessage] = useState('');
  const [selectedPhone, setSelectedPhone] = useState('');
  const [selectedNickname, setSelectedNickname] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchActions = async () => {
    try {
      const actionsRes = await axios.get(`${BACKEND_DOMAIN}/tableActions`, { withCredentials: true });
      setActions(actionsRes.data);
    } catch (error) {
      console.error('Error fetching actions:', error);
    }
  };

  const fetchPhoneContacts = async () => {
    try {
      const phoneContactsRes = await axios.get(`${BACKEND_DOMAIN}/phoneContacts`, { withCredentials: true });
      setPhoneContacts(phoneContactsRes.data);
    } catch (error) {
      console.error('Error fetching phone contacts:', error);
    }
  };

  const fetchNicknameContacts = async () => {
    try {
      const nicknameContactsRes = await axios.get(`${BACKEND_DOMAIN}/nicknameContacts`, { withCredentials: true });
      setNicknameContacts(nicknameContactsRes.data);
    } catch (error) {
      console.error('Error fetching nickname contacts:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([fetchActions(), fetchPhoneContacts(), fetchNicknameContacts()]);
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleEventChange = (event) => {
    setSelectedEvent(event.target.value);
    setSelectedMessage('');
  };

  const getMessagesForEvent = (eventId) => {
    const event = actions.find(action => action._id === eventId);
    if (!event) return [];

    return [
      { text: event.promotionText7Days, date: event.sendingDate7Days },
      { text: event.promotionText5Days, date: event.sendingDate5Days },
      { text: event.promotionText3Days, date: event.sendingDate3Days },
      { text: event.promotionText1Day, date: event.sendingDate1Day },
      { text: event.promotionTextEventDay, date: event.sendingDateEventDay },
      { text: event.promotionTextAfter2Days, date: event.sendingDateAfter2Days }
    ].filter(message => message.text && message.text !== 'none');
  };

  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="ml" mb={6}>
        Выбрать событие:
      </Heading>
      {loading ? (
        <Spinner size="xl" />
      ) : (
        <VStack spacing={4} align="stretch" w="full" maxW="md">
          <Select
            placeholder="Выберите событие"
            value={selectedEvent}
            onChange={handleEventChange}
            onFocus={fetchActions}
            width="full"
          >
            <option value="" disabled hidden>Выберите событие</option>
            {actions.map((action) => (
              <option key={action._id} value={action._id}>
                {action.eventName}
              </option>
            ))}
          </Select>
          <Select
            placeholder="Выберите сообщение"
            value={selectedMessage}
            onChange={(e) => setSelectedMessage(e.target.value)}
            onFocus={() => selectedEvent && fetchActions()}
            width="full"
            disabled={!selectedEvent}
            mb={10}
          >
            <option value="" disabled hidden>Выберите сообщение</option>
            {selectedEvent && getMessagesForEvent(selectedEvent).map((message, index) => (
              <option key={index} value={message.text}>
                {message.text} - {new Date(message.date).toLocaleDateString('ru-RU')}
              </option>
            ))}
          </Select>
        </VStack>
      )}
      <Heading as="h1" size="ml" mt={6} mb={6}>
        Отправьте рекламные сообщения:
      </Heading>
      <Select
        placeholder="Выберите номер телефона"
        value={selectedPhone}
        onChange={(e) => setSelectedPhone(e.target.value)}
        onFocus={fetchPhoneContacts}
        mb={4}
        width="full"
        maxW="md"
      >
        <option value="" disabled hidden>Выберите номер телефона</option>
        {phoneContacts.map((contact) => (
          <option key={contact._id} value={contact.phone}>
            {contact.phone} - {contact.info}
          </option>
        ))}
      </Select>
      <VStack spacing={4} align="stretch" w="full" maxW="md">
        <SmsButton phone={selectedPhone} message={selectedMessage} />
        <WhatsappButton phone={selectedPhone} message={selectedMessage} />
        <TelegramButton phone={selectedPhone} message={selectedMessage} />
      </VStack>
      <Select
        placeholder="Выберите никнейм"
        value={selectedNickname}
        onChange={(e) => setSelectedNickname(e.target.value)}
        onFocus={fetchNicknameContacts}
        mt={8}
        width="full"
        maxW="md"
      >
        <option value="" disabled hidden>Выберите никнейм</option>
        {nicknameContacts.map((contact) => (
          <option key={contact._id} value={contact.nickname}>
            {contact.nickname} - {contact.info}
          </option>
        ))}
      </Select>
      <Text fontSize="sm" color="gray.600" mb={4} mt={4} align="stretch" w="full" maxW="md" >
        Сообщение для <i>VK и *instagram</i> копируется в кеш - после перехода в <i>VK и *instagram</i> просто вставьте сообщение.
      </Text>
      <VStack spacing={4} align="stretch" w="full" maxW="md">
        <VkButton nickname={selectedNickname} message={selectedMessage} />
        <InstagramButton nickname={selectedNickname} message={selectedMessage} />
      </VStack>
      <Text fontSize="sm" color="gray.600" mb={4} mt={4} align="stretch" w="full" maxW="md" >
       *Интерент-сервис <i>instagram</i> принадлежит компании Meta, которая признана экстримистской организации на территори РФ.
      </Text>
    </Box>
  );
};

export default ButtonsTab;



//src/pages/tabs/ButtonsTab.jsx
//рабочий код с подключенными выпадающими списками
/*import React, { useState, useEffect } from 'react';
import { Box, VStack, Text, Heading, Select, Spinner } from '@chakra-ui/react';
import SmsButton from '../../components/buttons/SmsButton';
import WhatsappButton from '../../components/buttons/WhatsappButton';
import TelegramButton from '../../components/buttons/TelegramButton';
import VkButton from '../../components/buttons/VkButton';
import InstagramButton from '../../components/buttons/InstagramButton';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const ButtonsTab = () => {
  const [actions, setActions] = useState([]);
  const [phoneContacts, setPhoneContacts] = useState([]);
  const [nicknameContacts, setNicknameContacts] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [selectedMessage, setSelectedMessage] = useState('');
  const [selectedPhone, setSelectedPhone] = useState('');
  const [selectedNickname, setSelectedNickname] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchActions = async () => {
    try {
      const actionsRes = await axios.get(`${BACKEND_DOMAIN}/tableActions`, { withCredentials: true });
      setActions(actionsRes.data);
    } catch (error) {
      console.error('Error fetching actions:', error);
    }
  };

  const fetchPhoneContacts = async () => {
    try {
      const phoneContactsRes = await axios.get(`${BACKEND_DOMAIN}/phoneContacts`, { withCredentials: true });
      setPhoneContacts(phoneContactsRes.data);
    } catch (error) {
      console.error('Error fetching phone contacts:', error);
    }
  };

  const fetchNicknameContacts = async () => {
    try {
      const nicknameContactsRes = await axios.get(`${BACKEND_DOMAIN}/nicknameContacts`, { withCredentials: true });
      setNicknameContacts(nicknameContactsRes.data);
    } catch (error) {
      console.error('Error fetching nickname contacts:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([fetchActions(), fetchPhoneContacts(), fetchNicknameContacts()]);
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleEventChange = (event) => {
    setSelectedEvent(event.target.value);
    setSelectedMessage('');
  };

  const getMessagesForEvent = (eventId) => {
    const event = actions.find(action => action._id === eventId);
    if (!event) return [];

    return [
      { text: event.promotionText7Days, date: event.sendingDate7Days },
      { text: event.promotionText5Days, date: event.sendingDate5Days },
      { text: event.promotionText3Days, date: event.sendingDate3Days },
      { text: event.promotionText1Day, date: event.sendingDate1Day },
      { text: event.promotionTextEventDay, date: event.sendingDateEventDay },
      { text: event.promotionTextAfter2Days, date: event.sendingDateAfter2Days }
    ].filter(message => message.text && message.text !== 'none');
  };

  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="ml" mb={6}>
        Выбрать событие
      </Heading>
      {loading ? (
        <Spinner size="xl" />
      ) : (
        <VStack spacing={4} align="stretch" w="full" maxW="md">
          <Select
            placeholder="Выберите событие"
            value={selectedEvent}
            onChange={handleEventChange}
            onFocus={fetchActions}
            width="full"
          >
            <option value="" disabled hidden>Выберите событие</option>
            {actions.map((action) => (
              <option key={action._id} value={action._id}>
                {action.eventName}
              </option>
            ))}
          </Select>
          <Select
            placeholder="Выберите сообщение"
            value={selectedMessage}
            onChange={(e) => setSelectedMessage(e.target.value)}
            onFocus={() => selectedEvent && fetchActions()}
            width="full"
            disabled={!selectedEvent}
            mb={10}
          >
            <option value="" disabled hidden>Выберите сообщение</option>
            {selectedEvent && getMessagesForEvent(selectedEvent).map((message, index) => (
              <option key={index} value={message.text}>
                {message.text} - {new Date(message.date).toLocaleDateString('ru-RU')}
              </option>
            ))}
          </Select>
        </VStack>
      )}
      <Heading as="h1" size="ml" mt={6} mb={6}>
        Отправьте рекламные сообщения:
      </Heading>
      <Select
        placeholder="Выберите номер телефона"
        value={selectedPhone}
        onChange={(e) => setSelectedPhone(e.target.value)}
        onFocus={fetchPhoneContacts}
        mb={4}
        width="full"
        maxW="md"
      >
        <option value="" disabled hidden>Выберите номер телефона</option>
        {phoneContacts.map((contact) => (
          <option key={contact._id} value={contact._id}>
            {contact.phone} - {contact.info}
          </option>
        ))}
      </Select>
      <VStack spacing={4} align="stretch" w="full" maxW="md">
        <SmsButton />
        <WhatsappButton />
        <TelegramButton />
      </VStack>
      <Select
        placeholder="Выберите никнейм"
        value={selectedNickname}
        onChange={(e) => setSelectedNickname(e.target.value)}
        onFocus={fetchNicknameContacts}
        mb={4}
        mt={6}
        width="full"
        maxW="md"
      >
        <option value="" disabled hidden>Выберите никнейм</option>
        {nicknameContacts.map((contact) => (
          <option key={contact._id} value={contact._id}>
            {contact.nickname} - {contact.info}
          </option>
        ))}
      </Select>
      <VStack spacing={4} align="stretch" w="full" maxW="md">
        <VkButton />
        <InstagramButton />
      </VStack>
      <Text fontSize="sm" color="gray.600" mt={4} mb={10}>
        Сообщение для <i>*instagram</i> копируется в кеш - после перехода в <i>*instagram</i> просто вставьте сообщение.
      </Text>
    </Box>
  );
};

export default ButtonsTab;*/




//сделаны зависимые выпадающие списки 
/*import React, { useState, useEffect } from 'react';
import { Box, VStack, Text, Heading, Select, Spinner } from '@chakra-ui/react';
import SmsButton from '../../components/buttons/SmsButton';
import WhatsappButton from '../../components/buttons/WhatsappButton';
import TelegramButton from '../../components/buttons/TelegramButton';
import VkButton from '../../components/buttons/VkButton';
import InstagramButton from '../../components/buttons/InstagramButton';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const ButtonsTab = () => {
  const [actions, setActions] = useState([]);
  const [phoneContacts, setPhoneContacts] = useState([]);
  const [nicknameContacts, setNicknameContacts] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [selectedMessage, setSelectedMessage] = useState('');
  const [selectedPhone, setSelectedPhone] = useState('');
  const [selectedNickname, setSelectedNickname] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [actionsRes, phoneContactsRes, nicknameContactsRes] = await Promise.all([
          axios.get(`${BACKEND_DOMAIN}/tableActions`, { withCredentials: true }),
          axios.get(`${BACKEND_DOMAIN}/phoneContacts`, { withCredentials: true }),
          axios.get(`${BACKEND_DOMAIN}/nicknameContacts`, { withCredentials: true }),
        ]);
        setActions(actionsRes.data);
        setPhoneContacts(phoneContactsRes.data);
        setNicknameContacts(nicknameContactsRes.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleEventChange = (event) => {
    setSelectedEvent(event.target.value);
    setSelectedMessage('');
  };

  const getMessagesForEvent = (eventId) => {
    const event = actions.find(action => action._id === eventId);
    if (!event) return [];

    return [
      { text: event.promotionText7Days, date: event.sendingDate7Days },
      { text: event.promotionText5Days, date: event.sendingDate5Days },
      { text: event.promotionText3Days, date: event.sendingDate3Days },
      { text: event.promotionText1Day, date: event.sendingDate1Day },
      { text: event.promotionTextEventDay, date: event.sendingDateEventDay },
      { text: event.promotionTextAfter2Days, date: event.sendingDateAfter2Days }
    ].filter(message => message.text && message.text !== 'none');
  };

  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="ml" mb={6}>
        Выбрать событие:
      </Heading>
      {loading ? (
        <Spinner size="xl" />
      ) : (
        <VStack spacing={4} align="stretch" w="full" maxW="md">
          <Select
            placeholder="Выберите событие"
            value={selectedEvent}
            onChange={handleEventChange}
            width="full"
          >
            <option value="" disabled hidden>Выберите событие</option>
            {actions.map((action) => (
              <option key={action._id} value={action._id}>
                {action.eventName}
              </option>
            ))}
          </Select>
          <Select
            placeholder="Выберите сообщение"
            value={selectedMessage}
            onChange={(e) => setSelectedMessage(e.target.value)}
            width="full"
            disabled={!selectedEvent}
            mb={10}
          >
            <option value="" disabled hidden>Выберите сообщение</option>
            {selectedEvent && getMessagesForEvent(selectedEvent).map((message, index) => (
              <option key={index} value={message.text}>
                {message.text} - {new Date(message.date).toLocaleDateString('ru-RU')}
              </option>
            ))}
          </Select>
        </VStack>
      )}
      <Heading as="h1" size="ml" mt={6} mb={6}>
        Отправьте рекламные сообщения:
      </Heading>
      <Select
        placeholder="Выберите номер телефона"
        value={selectedPhone}
        onChange={(e) => setSelectedPhone(e.target.value)}
        mb={4}
        width="full"
        maxW="md"
      >
        <option value="" disabled hidden>Выберите номер телефона</option>
        {phoneContacts.map((contact) => (
          <option key={contact._id} value={contact._id}>
            {contact.phone} - {contact.info}
          </option>
        ))}
      </Select>
      <VStack spacing={4} align="stretch" w="full" maxW="md">
        <SmsButton />
        <WhatsappButton />
        <TelegramButton />
      </VStack>
      <Select
        placeholder="Выберите никнейм"
        value={selectedNickname}
        onChange={(e) => setSelectedNickname(e.target.value)}
        mb={4}
        mt={6}
        width="full"
        maxW="md"
      >
        <option value="" disabled hidden>Выберите никнейм</option>
        {nicknameContacts.map((contact) => (
          <option key={contact._id} value={contact._id}>
            {contact.nickname} - {contact.info}
          </option>
        ))}
      </Select>
      <VStack spacing={4} align="stretch" w="full" maxW="md">
        <VkButton />
        <InstagramButton />
      </VStack>
      <Text fontSize="sm" color="gray.600" mt={4} mb={10}>
        Сообщение для <i>*instagram</i> копируется в кеш - после перехода в <i>*instagram</i> просто вставьте сообщение.
      </Text>
    </Box>
  );
};

export default ButtonsTab;*/



//выпадающие списки подключены к базе данных
/*import React, { useState, useEffect } from 'react';
import { Box, VStack, Text, Heading, Select, Spinner } from '@chakra-ui/react';
import SmsButton from '../../components/buttons/SmsButton';
import WhatsappButton from '../../components/buttons/WhatsappButton';
import TelegramButton from '../../components/buttons/TelegramButton';
import VkButton from '../../components/buttons/VkButton';
import InstagramButton from '../../components/buttons/InstagramButton';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const ButtonsTab = () => {
  const [actions, setActions] = useState([]);
  const [phoneContacts, setPhoneContacts] = useState([]);
  const [nicknameContacts, setNicknameContacts] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedEvent, setSelectedEvent] = useState('');
  const [selectedMessage, setSelectedMessage] = useState('');
  const [selectedPhone, setSelectedPhone] = useState('');
  const [selectedNickname, setSelectedNickname] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [actionsRes, phoneContactsRes, nicknameContactsRes] = await Promise.all([
          axios.get(`${BACKEND_DOMAIN}/tableActions`, { withCredentials: true }),
          axios.get(`${BACKEND_DOMAIN}/phoneContacts`, { withCredentials: true }),
          axios.get(`${BACKEND_DOMAIN}/nicknameContacts`, { withCredentials: true }),
        ]);
        setActions(actionsRes.data);
        setPhoneContacts(phoneContactsRes.data);
        setNicknameContacts(nicknameContactsRes.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="ml" mb={6}>
        Выбрать событие
      </Heading>
      {loading ? (
        <Spinner size="xl" />
      ) : (
        <VStack spacing={4} align="stretch" w="full" maxW="md">
          <Select
            placeholder="Выберите дату"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            width="full"
          >
            <option value="" disabled hidden>Выберите дату</option>
            {actions.map((action) => (
              <option key={action._id} value={action._id}>
                {new Date(action.eventDate).toLocaleDateString('ru-RU')}
              </option>
            ))}
          </Select>
          <Select
            placeholder="Выберите событие"
            value={selectedEvent}
            onChange={(e) => setSelectedEvent(e.target.value)}
            width="full"
          >
            <option value="" disabled hidden>Выберите событие</option>
            {actions.map((action) => (
              <option key={action._id} value={action._id}>
                {action.eventName}
              </option>
            ))}
          </Select>
          <Select
            placeholder="Выберите сообщение"
            value={selectedMessage}
            onChange={(e) => setSelectedMessage(e.target.value)}
            width="full"
          >
            <option value="" disabled hidden>Выберите сообщение</option>
            {actions.map((action) => (
              <option key={action._id} value={action._id}>
                {action.promotionTextEventDay}
              </option>
            ))}
          </Select>
        </VStack>
      )}
      <Heading as="h1" size="ml" mt={6} mb={6}>
        Отправьте рекламные сообщения:
      </Heading>
      <Select
        placeholder="Выберите номер телефона"
        value={selectedPhone}
        onChange={(e) => setSelectedPhone(e.target.value)}
        mb={4}
        width="full"
        maxW="md"
      >
        <option value="" disabled hidden>Выберите номер телефона</option>
        {phoneContacts.map((contact) => (
          <option key={contact._id} value={contact._id}>
            {contact.phone} - {contact.info}
          </option>
        ))}
      </Select>
      <VStack spacing={4} align="stretch" w="full" maxW="md">
        <SmsButton />
        <WhatsappButton />
        <TelegramButton />
      </VStack>
      <Select
        placeholder="Выберите никнейм"
        value={selectedNickname}
        onChange={(e) => setSelectedNickname(e.target.value)}
        mb={4}
        mt={6}
        width="full"
        maxW="md"
      >
        <option value="" disabled hidden>Выберите никнейм</option>
        {nicknameContacts.map((contact) => (
          <option key={contact._id} value={contact._id}>
            {contact.nickname} - {contact.info}
          </option>
        ))}
      </Select>
      <VStack spacing={4} align="stretch" w="full" maxW="md">
        <VkButton />
        <InstagramButton />
      </VStack>
      <Text fontSize="sm" color="gray.600" mt={4} mb={10}>
        Сообщение для <i>*instagram</i> копируется в кеш - после перехода в <i>*instagram</i> просто вставьте сообщение.
      </Text>
    </Box>
  );
};

export default ButtonsTab;*/





// src/pages/tabs/ButtonsTab.jsx
//базово рабочий UI c выпадающими списками
/*import React, { useState, useEffect } from 'react';
import { Box, VStack, Text, Heading, Select, Spinner } from '@chakra-ui/react';
import SmsButton from '../../components/buttons/SmsButton';
import WhatsappButton from '../../components/buttons/WhatsappButton';
import TelegramButton from '../../components/buttons/TelegramButton';
import VkButton from '../../components/buttons/VkButton';
import InstagramButton from '../../components/buttons/InstagramButton';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const ButtonsTab = () => {
  const [dates, setDates] = useState([]);
  const [events, setEvents] = useState([]);
  const [messages, setMessages] = useState([]);
  const [phoneContacts, setPhoneContacts] = useState([]);
  const [nicknameContacts, setNicknameContacts] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedEvent, setSelectedEvent] = useState('');
  const [selectedMessage, setSelectedMessage] = useState('');
  const [selectedPhone, setSelectedPhone] = useState('');
  const [selectedNickname, setSelectedNickname] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [datesRes, eventsRes, messagesRes, phoneContactsRes, nicknameContactsRes] = await Promise.all([
          axios.get(`${BACKEND_DOMAIN}/dates`, { withCredentials: true }),
          axios.get(`${BACKEND_DOMAIN}/events`, { withCredentials: true }),
          axios.get(`${BACKEND_DOMAIN}/messages`, { withCredentials: true }),
          axios.get(`${BACKEND_DOMAIN}/phoneContacts`, { withCredentials: true }),
          axios.get(`${BACKEND_DOMAIN}/nicknameContacts`, { withCredentials: true }),
        ]);
        setDates(datesRes.data);
        setEvents(eventsRes.data);
        setMessages(messagesRes.data);
        setPhoneContacts(phoneContactsRes.data);
        setNicknameContacts(nicknameContactsRes.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="ml" mb={6}>
        Выбрать событие
      </Heading>
      {loading ? (
        <Spinner size="xl" />
      ) : (
        <VStack spacing={4} align="stretch" w="full" maxW="md">
          <Select
            placeholder="Выберите дату"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            width="full"
          >
            <option value="" disabled hidden>Выберите дату</option>
            {dates.map((date) => (
              <option key={date.id} value={date.id}>
                {date.name}
              </option>
            ))}
          </Select>
          <Select
            placeholder="Выберите событие"
            value={selectedEvent}
            onChange={(e) => setSelectedEvent(e.target.value)}
            width="full"
          >
            <option value="" disabled hidden>Выберите событие</option>
            {events.map((event) => (
              <option key={event.id} value={event.id}>
                {event.name}
              </option>
            ))}
          </Select>
          <Select
            placeholder="Выберите сообщение"
            value={selectedMessage}
            onChange={(e) => setSelectedMessage(e.target.value)}
            width="full"
          >
            <option value="" disabled hidden>Выберите сообщение</option>
            {messages.map((message) => (
              <option key={message.id} value={message.id}>
                {message.name}
              </option>
            ))}
          </Select>
        </VStack>
      )}
      <Heading as="h1" size="ml" mt={6} mb={6}>
        Отправьте рекламные сообщения:
      </Heading>
      <Select
        placeholder="Выберите номер телефона"
        value={selectedPhone}
        onChange={(e) => setSelectedPhone(e.target.value)}
        mb={4}
        width="full"
        maxW="md"
      >
        <option value="" disabled hidden>Выберите номер телефона</option>
        {phoneContacts.map((contact) => (
          <option key={contact.id} value={contact.id}>
            {contact.phone}
          </option>
        ))}
      </Select>
      <VStack spacing={4} align="stretch" w="full" maxW="md">
        <SmsButton />
        <WhatsappButton />
        <TelegramButton />
      </VStack>
      <Select
        placeholder="Выберите никнейм"
        value={selectedNickname}
        onChange={(e) => setSelectedNickname(e.target.value)}
        mb={4}
        mt={6}
        width="full"
        maxW="md"
      >
        <option value="" disabled hidden>Выберите никнейм</option>
        {nicknameContacts.map((contact) => (
          <option key={contact.id} value={contact.id}>
            {contact.nickname}
          </option>
        ))}
      </Select>
      <VStack spacing={4} align="stretch" w="full" maxW="md">
        <VkButton />
        <InstagramButton />
      </VStack>
      <Text fontSize="sm" color="gray.600" mt={4} mb={10}>
        Сообщение для <i>*instagram</i> копируется в кеш - после перехода в <i>*instagram</i> просто вставьте сообщение.
      </Text>
    </Box>
  );
};

export default ButtonsTab;*/




//src/pages/tabs/ButtonsTab.jsx
/*import React from 'react';
import { Box, VStack, Text, Heading  } from '@chakra-ui/react';
import SmsButton from '../../components/buttons/SmsButton';
import WhatsappButton from '../../components/buttons/WhatsappButton';
import TelegramButton from '../../components/buttons/TelegramButton';
import VkButton from '../../components/buttons/VkButton';
import InstagramButton from '../../components/buttons/InstagramButton';

const ButtonsTab = () => {
  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="ml" mb={6}>
        Отправьте рекламные сообщения:
      </Heading>
      <VStack spacing={4} align="stretch" w="full" maxW="md">
        <SmsButton />
        <WhatsappButton />
        <TelegramButton />
        <VkButton />
        <InstagramButton />
      </VStack>
      <Text fontSize="sm" color="gray.600" mt={4} mb={10}>
        Сообщение для <i>*instagram</i> копируется в кеш - после перехода в <i>*instagram</i> просто вставьте сообщение.
      </Text>
    </Box>
  );
};

export default ButtonsTab;*/
