import React from 'react';
import { Box, Link, Text } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Box as="footer" py={4} textAlign="center" width="100%">
      <Box borderTop="0px" borderColor="gray.700" />
      <Text color="gray.600" mt={3} mb={5} fontSize="xs">
        Developed by{' '}
        <Link href="https://novergeme.com" isExternal color="blue.300">
          Novergeme.com
        </Link>
      </Text>
    </Box>
  );
};

export default Footer;
