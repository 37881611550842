import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
  },
  colors: {
    customGreen: "#9AE6B4", // Custom color
    headerBg: "#242F3D",   // Добавляем цвет фона для компонента Header
  },
  styles: {
    global: {
      body: {
        bg: 'gray.800',
        color: 'white',
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold',
      },
      variants: {
        register: {
          bg: 'yellow.400',
          color: 'black',
        },
      },
    },
    Box: {
      variants: {
        header: {
          bg: 'headerBg',   // Используем цвет, добавленный выше
          p: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
          h: "60px",
        }
      }
    }
  },
});

export default theme;





/*import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
  },
  colors: {
    customGreen: "#9AE6B4", // Custom color
  },
  styles: {
    global: {
      body: {
        bg: 'gray.800',
        color: 'white',
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold',
      },
      variants: {
        register: {
          bg: 'yellow.400',
          color: 'black',
        },
      },
    },
  },
});

export default theme;*/
