import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/react';
import ButtonsTab from '../pages/tabs/ButtonsTab';
import AboutCompanyTab from '../pages/tabs/AboutCompanyTab';
import ActionGenerateTab from './tabs/ActionGenerateTab';
import AddNewAction from './tabs/AddNewAction';
import ContactsTablesTab from './tabs/ContactsTablesTab';
import FileUploadTab from './tabs/FileUploadTab';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { useBreakpointValue } from '@chakra-ui/react';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const Dashboard = () => {
  const [holidays, setHolidays] = useState([]);
  const { isAuthenticated, checkAuth } = useAuth();
  const tabSize = useBreakpointValue({ base: 'xs', md: 'md' });
  const paddingX = useBreakpointValue({ base: 2, md: 9 });
  const tabFontSize = useBreakpointValue({ base: 'xs', md: 'md' });

  useEffect(() => {
    if (isAuthenticated) {
      axios.get(`${BACKEND_DOMAIN}/tableActions`, { withCredentials: true })
        .then(response => setHolidays(response.data))
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [isAuthenticated]);

  const handleAddHoliday = async () => {
    const newAction = {
      eventName: 'New Event',
      eventDate: new Date(),
      commentForAi: 'none',
      promotionText7Days: 'none',
      sendingDate7Days: getDateWithOffset(new Date(), -7),
      promotionText5Days: 'none',
      sendingDate5Days: getDateWithOffset(new Date(), -5),
      promotionText3Days: 'none',
      sendingDate3Days: getDateWithOffset(new Date(), -3),
      promotionText1Day: 'none',
      sendingDate1Day: getDateWithOffset(new Date(), -1),
      promotionTextEventDay: 'none',
      sendingDateEventDay: new Date(),
      promotionTextAfter2Days: 'none',
      sendingDateAfter2Days: getDateWithOffset(new Date(), 2)
    };

    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/tableActions`, newAction, { withCredentials: true });
      setHolidays([...holidays, response.data]);
    } catch (error) {
      console.error('Error adding action:', error);
    }
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate;
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header />
      <Box flex="1">
        <Tabs variant="enclosed">
          <TabList isFitted px={paddingX} size={tabSize}>
            <Tab fontSize={tabFontSize}>Ai Инфо</Tab>
            <Tab fontSize={tabFontSize}>Ai Акции</Tab>
            <Tab fontSize={tabFontSize}>Контакты</Tab>
            <Tab fontSize={tabFontSize}>Рассылки</Tab>
            <Tab fontSize={tabFontSize}>Ai DOC</Tab>
          </TabList>

          <TabPanels px={paddingX}>
            <TabPanel overflow="auto">
              <AboutCompanyTab />
            </TabPanel>
            <TabPanel overflow="auto">
              <Box overflowX="auto">
                <ActionGenerateTab holidays={holidays} setHolidays={setHolidays} />
              </Box>
              <Box overflowX="hidden" mt={4}>
                <AddNewAction handleAddHoliday={handleAddHoliday} />
              </Box>
            </TabPanel>
            <TabPanel overflow="auto">
              <ContactsTablesTab />
            </TabPanel>
            <TabPanel overflow="auto">
              <ButtonsTab />
            </TabPanel>
            <TabPanel overflow="auto">
              <FileUploadTab />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <Footer />
    </Box>
  );
};

export default Dashboard;





// src/pages/Dashboard.jsx
//С адаптацией под мобтльное устройство 
/*import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/react';
import ButtonsTab from '../pages/tabs/ButtonsTab';
import AboutCompanyTab from '../pages/tabs/AboutCompanyTab';
import ActionGenerateTab from './tabs/ActionGenerateTab';
import AddNewAction from './tabs/AddNewAction';
import ContactsTablesTab from './tabs/ContactsTablesTab';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { useBreakpointValue } from '@chakra-ui/react';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const Dashboard = () => {
  const [holidays, setHolidays] = useState([]);
  const { isAuthenticated, checkAuth } = useAuth();
  const tabSize = useBreakpointValue({ base: 'sm', md: 'md' });
  const paddingX = useBreakpointValue({ base: 2, md: 9 });

  useEffect(() => {
    if (isAuthenticated) {
      axios.get(`${BACKEND_DOMAIN}/tableActions`, { withCredentials: true })
        .then(response => setHolidays(response.data))
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [isAuthenticated]);

  const handleAddHoliday = async () => {
    const newAction = {
      eventName: 'New Event',
      eventDate: new Date(),
      commentForAi: 'none',
      promotionText7Days: 'none',
      sendingDate7Days: getDateWithOffset(new Date(), -7),
      promotionText5Days: 'none',
      sendingDate5Days: getDateWithOffset(new Date(), -5),
      promotionText3Days: 'none',
      sendingDate3Days: getDateWithOffset(new Date(), -3),
      promotionText1Day: 'none',
      sendingDate1Day: getDateWithOffset(new Date(), -1),
      promotionTextEventDay: 'none',
      sendingDateEventDay: new Date(),
      promotionTextAfter2Days: 'none',
      sendingDateAfter2Days: getDateWithOffset(new Date(), 2)
    };

    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/tableActions`, newAction, { withCredentials: true });
      setHolidays([...holidays, response.data]);
    } catch (error) {
      console.error('Error adding action:', error);
    }
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate;
  };

  return (
    <div>
      <Header />
      <Tabs variant="enclosed">
        <TabList isFitted px={paddingX} size={tabSize}>
          <Tab>Ai Инфо</Tab>
          <Tab>Ai Акции</Tab>
          <Tab>Контакты</Tab>
          <Tab>Рассылки</Tab>
        </TabList>
      
        <TabPanels px={paddingX}>
          <TabPanel overflow="auto">
            <AboutCompanyTab />
          </TabPanel>
          <TabPanel overflow="auto">
            <Box overflowX="auto">
              <ActionGenerateTab holidays={holidays} setHolidays={setHolidays} />
            </Box>
            <Box overflowX="hidden" mt={4}>
              <AddNewAction handleAddHoliday={handleAddHoliday} />
            </Box>
          </TabPanel>
          <TabPanel overflow="auto">
            <ContactsTablesTab />
          </TabPanel>
          <TabPanel overflow="auto">
            <ButtonsTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default Dashboard;*/



// src/pages/Dashboard.jsx
//рабочий стабильный вариант
/*import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/react';
import ButtonsTab from '../pages/tabs/ButtonsTab';
import AboutCompanyTab from '../pages/tabs/AboutCompanyTab';
import ActionGenerateTab from './tabs/ActionGenerateTab';
import AddNewAction from './tabs/AddNewAction';
import ContactsTablesTab from './tabs/ContactsTablesTab';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const Dashboard = () => {
  const [holidays, setHolidays] = useState([]);
  const { isAuthenticated, checkAuth } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      axios.get(`${BACKEND_DOMAIN}/tableActions`, { withCredentials: true })
        .then(response => setHolidays(response.data))
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [isAuthenticated]);

  const handleAddHoliday = async () => {
    const newAction = {
      eventName: 'New Event',
      eventDate: new Date(),
      commentForAi: 'none',
      promotionText7Days: 'none',
      sendingDate7Days: getDateWithOffset(new Date(), -7),
      promotionText5Days: 'none',
      sendingDate5Days: getDateWithOffset(new Date(), -5),
      promotionText3Days: 'none',
      sendingDate3Days: getDateWithOffset(new Date(), -3),
      promotionText1Day: 'none',
      sendingDate1Day: getDateWithOffset(new Date(), -1),
      promotionTextEventDay: 'none',
      sendingDateEventDay: new Date(),
      promotionTextAfter2Days: 'none',
      sendingDateAfter2Days: getDateWithOffset(new Date(), 2)
    };

    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/tableActions`, newAction, { withCredentials: true });
      setHolidays([...holidays, response.data]);
    } catch (error) {
      console.error('Error adding action:', error);
    }
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate;
  };

  return (
    <div>
      <Header />
      <Tabs variant="enclosed">
        <TabList isFitted px={9}>
          <Tab>Ai Инфо</Tab>
          <Tab>Ai Акции</Tab>
          <Tab>Контакты</Tab>
          <Tab>Рассылки</Tab>
        </TabList>
      
        <TabPanels px={9}>
          <TabPanel overflow="auto">
            <AboutCompanyTab />
          </TabPanel>
          <TabPanel overflow="auto">
            <Box overflowX="auto">
              <ActionGenerateTab holidays={holidays} setHolidays={setHolidays} />
            </Box>
            <Box overflowX="hidden" mt={4}>
              <AddNewAction handleAddHoliday={handleAddHoliday} />
            </Box>
          </TabPanel>
          <TabPanel overflow="auto">
            <ContactsTablesTab />
          </TabPanel>
          <TabPanel overflow="auto">
            <ButtonsTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default Dashboard;*/



//src/pages/Dashboard.jsx
/*import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/react';
import ButtonsTab from '../pages/tabs/ButtonsTab';
import AboutCompanyTab from '../pages/tabs/AboutCompanyTab';
import ActionGenerateTab from './tabs/ActionGenerateTab';
import AddNewAction from './tabs/AddNewAction';
import ContactsTablesTab from './tabs/ContactsTablesTab';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const Dashboard = () => {
  const [holidays, setHolidays] = useState([]);
  const { isAuthenticated, checkAuth } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      axios.get(`${BACKEND_DOMAIN}/tableActions`, { withCredentials: true })
        .then(response => setHolidays(response.data))
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [isAuthenticated]);

  const handleAddHoliday = () => {
    const newAction = {
      eventName: 'New Event',
      eventDate: new Date(),
      commentForAi: 'none',
      promotionText7Days: 'none',
      sendingDate7Days: getDateWithOffset(new Date(), -7),
      promotionText5Days: 'none',
      sendingDate5Days: getDateWithOffset(new Date(), -5),
      promotionText3Days: 'none',
      sendingDate3Days: getDateWithOffset(new Date(), -3),
      promotionText1Day: 'none',
      sendingDate1Day: getDateWithOffset(new Date(), -1),
      promotionTextEventDay: 'none',
      sendingDateEventDay: new Date(),
      promotionTextAfter2Days: 'none',
      sendingDateAfter2Days: getDateWithOffset(new Date(), 2)
    };

    axios.post(`${BACKEND_DOMAIN}/tableActions`, newAction, { withCredentials: true })
      .then(response => {
        setHolidays([...holidays, response.data]);
      })
      .catch(error => console.error('Error adding action:', error));
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate;
  };

  return (
    <div>
      <Header />
      <Tabs variant="enclosed">
        <TabList isFitted px={9}>
          <Tab>Ai Инфо</Tab>
          <Tab>Ai Акции</Tab>
          <Tab>Контакты</Tab>
          <Tab>Рассылки</Tab>
        </TabList>
      
        <TabPanels px={9}>
          <TabPanel overflow="auto">
            <AboutCompanyTab />
          </TabPanel>
          <TabPanel overflow="auto">
            <Box overflowX="auto">
              <ActionGenerateTab holidays={holidays} setHolidays={setHolidays} />
            </Box>
            <Box overflowX="hidden" mt={4}>
              <AddNewAction handleAddHoliday={handleAddHoliday} />
            </Box>
          </TabPanel>
          <TabPanel overflow="auto">
            <ContactsTablesTab />
          </TabPanel>
          <TabPanel overflow="auto">
            <ButtonsTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default Dashboard;*/






/*import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/react';
import ButtonsTab from '../pages/tabs/ButtonsTab';
import AboutCompanyTab from '../pages/tabs/AboutCompanyTab';
import ActionGenerateTab from './tabs/ActionGenerateTab';
import AddNewAction from './tabs/AddNewAction';
import ContactsTablesTab from './tabs/ContactsTablesTab';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const Dashboard = () => {
  const [holidays, setHolidays] = useState([]);
  const { isAuthenticated, checkAuth } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      axios.get(`${BACKEND_DOMAIN}/tableActions`, { withCredentials: true })
        .then(response => setHolidays(response.data))
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [isAuthenticated]);

  const handleAddHoliday = () => {
    const newAction = {
      eventName: 'New Event',
      eventDate: new Date(),
      commentForAi: 'none',
      promotionText7Days: 'none',
      sendingDate7Days: new Date(),
      promotionText5Days: 'none',
      sendingDate5Days: new Date(),
      promotionText3Days: 'none',
      sendingDate3Days: new Date(),
      promotionText1Day: 'none',
      sendingDate1Day: new Date(),
      promotionTextEventDay: 'none',
      sendingDateEventDay: new Date(),
      promotionTextAfter2Days: 'none',
      sendingDateAfter2Days: new Date()
    };

    axios.post(`${BACKEND_DOMAIN}/tableActions`, newAction, { withCredentials: true })
      .then(response => {
        setHolidays([...holidays, response.data]);
      })
      .catch(error => console.error('Error adding action:', error));
  };

  return (
    <div>
      <Header />
      <Tabs variant="enclosed">
        <TabList isFitted px={9}>
          <Tab>Ai Инфо</Tab>
          <Tab>Ai Акции</Tab>
          <Tab>Контакты</Tab>
          <Tab>Рассылки</Tab>
        </TabList>
      
        <TabPanels px={9}>
          <TabPanel overflow="auto">
            <AboutCompanyTab />
          </TabPanel>
          <TabPanel overflow="auto">
            <Box overflowX="auto">
              <ActionGenerateTab holidays={holidays} setHolidays={setHolidays} />
            </Box>
            <Box overflowX="hidden" mt={4}>
              <AddNewAction handleAddHoliday={handleAddHoliday} />
            </Box>
          </TabPanel>
          <TabPanel overflow="auto">
            <ContactsTablesTab />
          </TabPanel>
          <TabPanel overflow="auto">
            <ButtonsTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default Dashboard;*/






/*import React, { useState } from 'react';
import Header from '../components/Header';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box, Flex } from '@chakra-ui/react';
import ButtonsTab from '../pages/tabs/ButtonsTab';
import AboutCompanyTab from '../pages/tabs/AboutCompanyTab';
import ActionGenerateTab from './tabs/ActionGenerateTab';
import AddNewAction from './tabs/AddNewAction';
import ContactsTablesTab from './tabs/ContactsTablesTab'
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const Dashboard = () => {
  const [holidays, setHolidays] = useState([]);

  const handleAddHoliday = () => {
    setHolidays([...holidays, { name: "Новый праздник", date: new Date() }]);
  };

  const sendPostRequest = async () => {
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/test`, {}, {
        withCredentials: true
      });
      console.log(response.data);
    } catch (error) {
      console.error('Error during the post request:', error);
    }
  };

  return (
    <div>
      <Header />
      <Tabs variant="enclosed">
        <TabList isFitted px={9}>
          <Tab>Ai Инфо</Tab>
          <Tab>Ai Акции</Tab>
          <Tab>Контакты</Tab>
          <Tab>Рассылки</Tab>
        </TabList>
      
        <TabPanels px={9}>
          <TabPanel overflow="auto">
            <AboutCompanyTab />
          </TabPanel>
          <TabPanel overflow="auto">
            <Box overflowX="auto">
              <ActionGenerateTab holidays={holidays} />
            </Box>
            <Box overflowX="hidden" mt={4}>
              <AddNewAction handleAddHoliday={handleAddHoliday} />
            </Box>
          </TabPanel>
          <TabPanel overflow="auto">
            <ContactsTablesTab />
          </TabPanel>
          <TabPanel overflow="auto">
            <ButtonsTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default Dashboard;*/












/*// Dashboard.jsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//import { useAuth } from '../contexts/AuthContext';
import Header from '../components/Header';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Grid, Box, Stack, Button } from '@chakra-ui/react';
import AddPixelFB from '../components/App_FB_CAPI/AddPixelFB';
import LandingError from '../components/App_FB_CAPI/LandingError';
import PostbackError from '../components/App_FB_CAPI/PostbackError';
import LogOfClicks from '../components/App_FB_CAPI/LogOfClicks';
import ButtonsTab from '../pages/tabs/ButtonsTab';
import AboutCompanyTab from '../pages/tabs/AboutCompanyTab';
import ActionGenerateTab from './tabs/ActionGenerateTab';
import AddNewAction from './tabs/AddNewAction';

import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const Dashboard = () => {

  const navigate = useNavigate();
  const { isAuthenticated, checkAuth } = useAuth();

  useEffect(() => {
    console.log('Начало авторизации на странице... (ответ страницы dashboard)' + isAuthenticated);
    if (!isAuthenticated) {
      checkAuth().catch(() => {
        console.log('Авторизация на странице не прошла :( (ответ страницы dashboard)' + isAuthenticated);
        //navigate('/login');
      });
    }
    else{
      console.log('Авторизация на странице успешна :) (ответ страницы dashboard)' + isAuthenticated);
    }
  }, [isAuthenticated, checkAuth, navigate]);


  // Используем useEffect для выполнения запроса к серверу при монтировании компонента
  useEffect(() => {
    // Определение асинхронной функции внутри useEffect
    const checkAuth = async () => {
      try {
        const response = await axios.post('https://server.postbackrouter.com/test', {}, {
          withCredentials: true
        });
        // Выводим результат в консоль
        console.log(response.data);
      } catch (error) {
        // В случае ошибки выводим её в консоль
        console.error('Error fetching auth check:', error);
      }
    };

    // Вызываем функцию
    checkAuth();
  }, []); // Пустой массив зависимостей, чтобы вызов произошел только один раз 
 
  // Функция для выполнения POST-запроса по нажатию на кнопку
  const sendPostRequest = async () => {
    try {
      // Делаем POST-запрос и получаем ответ
      const response = await axios.post(`${BACKEND_DOMAIN}/test`, {}, {
        withCredentials: true
      });
      // Выводим ответ в консоль
      console.log(response.data);
    } catch (error) {
      // В случае ошибки выводим её в консоль
      console.error('Error during the post request:', error);
    }
  };

  return (
    <div>
      <Header />
      <Tabs variant="enclosed">
        <TabList isFitted px={9}>
          <Tab>Ai Инфо</Tab>
          <Tab>Ai Акции</Tab>
          <Tab>Рассылки</Tab>
        </TabList>
      
        <TabPanels px={9}>
          <TabPanel overflow="auto">
            <AboutCompanyTab />
          </TabPanel>
          <TabPanel overflow="auto">
            <ActionGenerateTab />
          </TabPanel>
          <TabPanel overflow="auto">
            <ButtonsTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
}

export default Dashboard; */
