import React, { useState, useContext } from 'react';
import {
  Box, Stack, Input, Button, Text, Flex, FormControl,
  FormErrorMessage, useToast
} from '@chakra-ui/react';
import { AuthContext } from '../contexts/AuthContext';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const ProfileUser = () => {
  const { setIsAuthenticated } = useContext(AuthContext);
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const toast = useToast();

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    checkPasswordMatch(e.target.value, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    checkPasswordMatch(password, e.target.value);
  };

  const checkPasswordMatch = (password, confirmPassword) => {
    setIsPasswordMatch(password === confirmPassword);
  };

  const updatePassword = async () => {
    try {
      const response = await fetch(`${BACKEND_DOMAIN}/auth/change-password`, { // Замените '/api/change-password' на ваш реальный эндпоинт
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Добавьте любые необходимые заголовки, например токен авторизации
        },
        credentials: 'include', // для отправки cookies с токеном JWT, если используется
        body: JSON.stringify({
          oldPassword, // Уберите это, если не требуется ввод старого пароля
          newPassword: password,
        }),
      });
      if (response.ok) {
        const data = await response.json();
        // Покажите зеленое уведомление о успешном изменении пароля
        toast({
          title: "Успех",
          description: "Ваш пароль был успешно изменен.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        // Дополнительная логика после успешного изменения пароля, если требуется
       } else {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Не удалось изменить пароль');
       }
    } catch (error) {
        let errorMessage = "Произошла ошибка."; // Сообщение по умолчанию
        if (error.response && error.response.data && error.response.data.message) {
          // Пытаемся получить сообщение об ошибке из ответа сервера
          errorMessage = error.response.data.message;
        } else if (error.message) {
          // Или используем сообщение ошибки из самого исключения
          errorMessage = error.message;
        }
        toast({
          title: "Ошибка",
          description: errorMessage,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
  };

  const handleChangePassword = async () => {
    if (!isPasswordMatch || !password || !confirmPassword) {
      toast({
        title: "Ошибка",
        description: "Пожалуйста, проверьте пароли.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    // Вызываем функцию обновления пароля
    await updatePassword();
  };

  return (
    <Flex direction="column">
      <Box flex="1" textAlign="center" p="10" bg="#2d3748">
        <Stack spacing="6" maxW="md" mx="auto">
          <Text color="gray.400" fontSize="lg" mb="6">Ваш профиль</Text>
          <Text color="blue.500" fontSize="md" mb="4">Измените пароль, если это вам нужно:</Text>
          <Input
            placeholder="Введите старый пароль"
            variant="filled"
            mb="1"
            type="password"
            value={oldPassword}
            onChange={handleOldPasswordChange}
            borderColor={oldPassword ? 'gray.500' : null}
          />
          <Input
            placeholder="Придумайте новый пароль"
            variant="filled"
            mb="1"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            borderColor={password && confirmPassword ? (isPasswordMatch ? 'green.500' : 'red.500') : null}
          />
          <FormControl isInvalid={!isPasswordMatch && password && confirmPassword}>
            <Input
              placeholder="Повторите новый пароль"
              variant="filled"
              mb="1"
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              borderColor={password && confirmPassword ? (isPasswordMatch ? 'green.500' : 'red.500') : null}
            />
            {!isPasswordMatch && <FormErrorMessage>Пароли не совпадают</FormErrorMessage>}
          </FormControl>
          <Button colorScheme="yellow" size="lg" mb="2" onClick={handleChangePassword}>
            Изменить пароль
          </Button>
        </Stack>
      </Box>
    </Flex>
  );
};

export default ProfileUser;




/*import React, { useState, useContext } from 'react';
import {
  Box, Stack, Heading, Input, Button, Text, Flex, FormControl,
  FormErrorMessage, useToast
} from '@chakra-ui/react';
import { AuthContext } from '../contexts/AuthContext';

const ProfileUser = () => {
  const { setIsAuthenticated } = useContext(AuthContext);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const toast = useToast();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    checkPasswordMatch(e.target.value, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    checkPasswordMatch(password, e.target.value);
  };

  const checkPasswordMatch = (password, confirmPassword) => {
    setIsPasswordMatch(password === confirmPassword);
  };

  const handleChangePassword = async () => {
    if (!isPasswordMatch) {
      toast({
        title: "Error",
        description: "Passwords do not match.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Handle password change logic here
    // ...
  };

  return (
    <Flex direction="column">
      <Box flex="1" textAlign="center" p="10" bg="#2d3748">
        <Stack spacing="6" maxW="md" mx="auto">
          <Text color="gray.400" fontSize="lg" mb="6">Your login: loginname</Text>
          <Text color="blue.500" fontSize="md" mb="4">Сhange the password if you need it:</Text>
          <Input
            placeholder="Password"
            variant="filled"
            mb="1"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            borderColor={password && confirmPassword ? (isPasswordMatch ? 'green.500' : 'red.500') : null}
          />
          <FormControl isInvalid={!isPasswordMatch && password && confirmPassword}>
            <Input
              placeholder="Repeat your password"
              variant="filled"
              mb="1"
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              borderColor={password && confirmPassword ? (isPasswordMatch ? 'green.500' : 'red.500') : null}
            />
            <FormErrorMessage>Password doesn't match</FormErrorMessage>
          </FormControl>
          <Button colorScheme="yellow" size="lg" mb="2" onClick={handleChangePassword}>
            Change password now
          </Button>
        </Stack>
      </Box>
    </Flex>
  );
};

export default ProfileUser;*/
