// App.jsx
//рабочий
import React from 'react';
import { ChakraProvider, CSSReset, ColorModeScript } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import RegistrationPage from './pages/RegistrationPage';
import Dashboard from './pages/Dashboard'; // Изменим название для избежания путаницы
import theme from './components/theme';
import ProtectedRoute from './ProtectedRoute';
import PasswordRecoveryPage from './pages/PasswordRecoveryPage';
import ChangeForgottenPassword from './pages/PasswordChangePage';
import TransactionsPage from './pages/TransactionsPage';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Router>
        <Routes>
          <Route path="/jjjjjjjjj" element={<>  </>} />
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/registration" element={<RegistrationPage />} />
          <Route path="/password-reset/:token" element={<ChangeForgottenPassword />} />
          <Route path="/recovery-pass" element={<PasswordRecoveryPage />} />
          <Route path="/dashboard" element={<ProtectedRoute />}>
            <Route path="" element={<Dashboard />} />
          </Route>
          <Route path="/transactions" element={<TransactionsPage />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;




/*import React from 'react';
import { ChakraProvider, CSSReset, ColorModeScript } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import RegistrationPage from './pages/RegistrationPage';
import Dashboard from './pages/Dashboard';
import theme from './components/theme';
import LeftColumn from './components/LeftColumn';
import LandingError from './components/LandingError';
import { AuthProvider, useAuth } from './contexts/AuthContext'; // Импорт AuthProvider и useAuth

function App() {
  const { isAuthenticated } = useAuth(); // Используйте useAuth для доступа к состоянию аутентификации

  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Router>
        <Routes>
          <Route path="/" element={<>  </>} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/registration" element={<RegistrationPage />} />
          <Route path="/dashboard" element={ isAuthenticated ? <Dashboard /> : <Navigate to="/login" /> } />
          <Route path="/leftcolumn" element={<LeftColumn />} />
          <Route path="/lr" element={<LandingError />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

const AppWithAuth = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default AppWithAuth;*/


/*import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ChakraProvider, CSSReset, ColorModeScript } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import RegistrationPage from './pages/RegistrationPage';
import Dashboard from './pages/Dashboard';
import theme from './components/theme';
import LeftColumn from './components/LeftColumn';
import LandingError from './components/LandingError';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('https://server.postbackrouter.com/auth/check', { withCredentials: true });
        setIsAuthenticated(response.data.authenticated);
      } catch (error) {
        console.error('Authentication check failed', error);
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Router>
        <Routes>
          <Route path="/" element={<>  </>} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/registration" element={<RegistrationPage />} />
          <Route path="/dashboard" element={ isAuthenticated ? <Dashboard /> : <Navigate to="/login" /> } />
          <Route path="/leftcolumn" element={<LeftColumn />} />
          <Route path="/lr" element={<LandingError />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App; */


/*import React from 'react';
import { ChakraProvider, CSSReset, ColorModeScript } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import RegistrationPage from './pages/RegistrationPage';
import Dashboard from './pages/Dashboard';
import theme from './components/theme';
import LeftColumn from './components/LeftColumn';
import LandingError from './components/LandingError';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Router>
        <Routes>
          <Route path="/" element={<>  </>} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/registration" element={<RegistrationPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/leftcolumn" element={<LeftColumn />} />
          <Route path="/lr" element={<LandingError />} />
          
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;*/


/*import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './components/LoginPage'; // Путь относительно текущего файла App.js
import Dashboard from './pages/Dashboard';
import theme from './components/theme'; 

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<> {// Здесь можно поместить компонент домашней страницы } </>} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          {// Добавьте больше маршрутов здесь, если нужно }
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;*/





/* изначальный код
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
*/