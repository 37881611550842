// AuthContext.jsx
import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

export const AuthContext = createContext();


export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // добавлено состояние для отслеживания загрузки

  useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(true); // начало загрузки
      try {
        console.log('Авторизация шаг React');
        const response = await axios.get(`${BACKEND_DOMAIN}/auth/check`, { withCredentials: true });
        console.log('Авторизация есть ' + response);
        setIsAuthenticated(response.data.authenticated);
      } catch (error) {
        console.error('Authentication check failed', error);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false); // конец загрузки
      }
    };

    checkAuth();
  }, []);

  const logOut = async () => {
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/auth/logout`, {}, { withCredentials: true });
      if (response.status === 200) {
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, isLoading, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

// AuthContext.jsx
/*mport React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // добавлено состояние для отслеживания загрузки

  useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(true); // начало загрузки
      try {
        console.log('Авторизация шаг React');
        const response = await axios.get('https://server.postbackrouter.com/auth/check', { withCredentials: true });
        console.log('Авторизация есть ' + response);
        setIsAuthenticated(response.data.authenticated);
      } catch (error) {
        console.error('Authentication check failed', error);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false); // конец загрузки
      }
    };

    checkAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);*/


/*import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const checkAuth = async () => {
    try {
      const response = await axios.get('https://server.postbackrouter.com/auth/check', { withCredentials: true });
      setIsAuthenticated(response.data.authenticated);
      console.log("Авторизован (ответ от AuthContext.jsx)" + response.data.authenticated);
    } catch (error) {
      console.error('Authentication check failed', error);
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, checkAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);*/

