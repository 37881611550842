import React, { useState, useEffect, useRef } from 'react';
import { Box, InputGroup, Textarea, InputRightElement, IconButton, Heading, Flex, Text } from '@chakra-ui/react';
import { FiMic, FiCheck, FiX } from 'react-icons/fi';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const checkMediaRecorderSupport = () => {
  const formats = [
    'audio/webm',
    'audio/mp4',
    'audio/mpeg',
    'audio/ogg',
    'audio/wav',
    'audio/x-m4a',
    'audio/mpga'
  ];

  const supportedFormats = formats.filter(format => MediaRecorder.isTypeSupported(format));
  return supportedFormats;
};

const generateRandomFileName = (extension) => {
  const randomString = Math.random().toString(36).substring(2, 15);
  return `${randomString}.${extension}`;
};

const AboutCompanyTab = () => {
  const [value, setValue] = useState('');
  const [animatedText, setAnimatedText] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);
  const [isMultiLine, setIsMultiLine] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [audioBlob, setAudioBlob] = useState(null);
  const [stream, setStream] = useState(null);  // Добавлено состояние для потока
  const { isAuthenticated } = useAuth();
  const textareaRef = useRef(null);
  const intervalRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const supportedFormats = checkMediaRecorderSupport();
  const audioFormat = supportedFormats.length > 0 ? supportedFormats[0] : 'audio/webm'; // Используем первый поддерживаемый формат или 'audio/webm' по умолчанию

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      setIsMultiLine(textareaRef.current.scrollHeight > textareaRef.current.clientHeight);
    }
  }, [value, animatedText]);

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated) {
        try {
          const response = await axios.get(`${BACKEND_DOMAIN}/aboutCompany`, { withCredentials: true });
          setValue(response.data.companyInfo === 'none' ? '' : response.data.companyInfo);
        } catch (error) {
          console.error('Error fetching company info:', error);
        }
      }
    };
    fetchData();
  }, [isAuthenticated]);

  const animateText = (text) => {
    setIsAnimating(true);
    setAnimatedText('');
    let index = 0;
    const interval = setInterval(() => {
      if (index < text.length) {
        setAnimatedText(prev => prev + text.charAt(index));
        index++;
      } else {
        clearInterval(interval);
        setIsAnimating(false);
      }
    }, 50);
  };

  const handleChange = (event) => {
    if (!isAnimating) {
      const newValue = event.target.value;
      setValue(newValue);
      saveCompanyInfo(newValue === '' ? 'none' : newValue);
    }
  };

  const saveCompanyInfo = async (info) => {
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/aboutCompany`, { companyInfo: info }, { withCredentials: true });
      console.log('Save response:', response);
    } catch (error) {
      console.error('Error saving company info:', error);
    }
  };

  useEffect(() => {
    if (audioBlob && !isRecording) {
      const formData = new FormData();
      const fileExtension = audioFormat.split('/')[1];
      const fileName = generateRandomFileName(fileExtension); // Генерируем случайное имя для файла с расширением
      formData.append("audio", audioBlob, fileName);
      axios.post(`${BACKEND_DOMAIN}/audioToText`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true
      })
      .then(response => {
          const transcribedText = response.data.transcription;
          setValue(''); // Очистить текущее значение
          setTimeout(() => setValue(transcribedText), 0); 
          animateText(transcribedText); // Запуск анимации после получения текста
      })
      .catch(error => {
          console.error("Error in audio transcription:", error.response ? error.response.data : error.message);
      });
    }
  }, [audioBlob, isRecording]);

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        setStream(stream);  // Сохранение ссылки на поток
        console.log('Формат используется в браузере ' + audioFormat);
        const mediaRecorder = new MediaRecorder(stream, { mimeType: audioFormat });
        const audioChunks = [];
        mediaRecorder.ondataavailable = event => audioChunks.push(event.data);
        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunks, { type: audioFormat });
          console.log("Blob created:", audioBlob);
          setAudioBlob(audioBlob);
        };
        mediaRecorder.start();

        intervalRef.current = setInterval(() => {
          setRecordingTime(prevTime => prevTime + 1);
        }, 1000);
        setIsRecording(true);
        mediaRecorderRef.current = mediaRecorder;
      })
      .catch(error => console.error("Error accessing audio devices:", error));
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      stream.getTracks().forEach(track => track.stop());  // Остановка всех треков потока
    }
    setIsRecording(false);
    clearInterval(intervalRef.current);
  };

  const resetRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== "inactive") {
      mediaRecorderRef.current.stop();
      stream.getTracks().forEach(track => track.stop());  // Остановка всех треков потока
    }
    clearInterval(intervalRef.current);
    setRecordingTime(0);
    setValue('');
    setAudioBlob(null);
  };

  const confirmRecording = () => {
    stopRecording();
  };

  const formatTime = (time) => {
    const minutes = String(Math.floor(time / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="ml" mb={6} color="white">
        Расскажите о компании/продукте:
      </Heading>
      {isRecording ? (
        <Flex
          width="full"
          maxW="sm"
          borderRadius="full"
          borderWidth="1px"
          p={3}
          alignItems="center"
          bg="blue.900"
          color="white"
          minWidth="227px"
        >
          {/*<IconButton
            aria-label="Cancel"
            icon={<FiX />}
            variant="solid"
            borderRadius="full"
            size="sm"
            onClick={resetRecording}
            colorScheme="blue"
            bg="blue.600"
            flexShrink={0}
          />  */}
          <Flex flex="1" mx={3} alignItems="center" justifyContent="center" minWidth="52px">
            <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="1rem">
              {Array.from({ length: 25 }).map((_, index) => (
                <Box
                  key={index}
                  width="2px"
                  height={`${Math.random() * 100}%`}
                  backgroundColor="blue.400"
                  mx="1px"
                />
              ))}
            </Box>
          </Flex>
          <Text ml={3} mr={3} size="sm" color="blue.400" minWidth="max-content" fontFamily="monospace" width="48px" textAlign="center">{formatTime(recordingTime)}</Text>
          <IconButton
            aria-label="Confirm"
            icon={<FiCheck />}
            variant="solid"
            borderRadius="full"
            size="sm"
            onClick={confirmRecording}
            colorScheme="blue"
            bg="blue.600"
            flexShrink={0}
          /> 
        </Flex>
      ) : (
        <InputGroup size="lg" width="full" maxW="md" position="relative">
          <Textarea
            ref={textareaRef}
            value={isAnimating ? animatedText : value}
            onChange={handleChange}
            placeholder="Сообщение"
            borderRadius={isMultiLine ? 'md' : 'full'}
            borderColor="gray.400"
            _placeholder={{ color: 'gray.500' }}
            resize="none"
            pr="4rem"
            minH="3rem"
            overflow="hidden"
            lineHeight="1.5"
            rows={1}
            _focus={{ boxShadow: "0 0 0 1px #3182ce" }}
          />
          <InputRightElement width="4rem">
            <IconButton
              aria-label="Microphone"
              icon={<FiMic />}
              variant="solid"
              borderRadius="full"
              size="lg"
              backgroundColor="transparent"
              _hover={{ backgroundColor: 'transparent' }}
              _active={{ backgroundColor: 'transparent' }}
              onClick={isRecording ? stopRecording : startRecording}
            />
          </InputRightElement>
        </InputGroup>
      )}
    </Box>
  );
};

export default AboutCompanyTab;



/*import React, { useState, useEffect, useRef } from 'react';
import { Box, InputGroup, Textarea, InputRightElement, IconButton, Heading, Flex, Text } from '@chakra-ui/react';
import { FiMic, FiCheck, FiX } from 'react-icons/fi';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const checkMediaRecorderSupport = () => {
  const formats = [
    'audio/webm',
    'audio/mp4',
    'audio/mpeg',
    'audio/ogg',
    'audio/wav',
    'audio/x-m4a',
    'audio/mpga'
  ];

  const supportedFormats = formats.filter(format => MediaRecorder.isTypeSupported(format));
  return supportedFormats;
};

const AboutCompanyTab = () => {
  const [value, setValue] = useState('');
  const [animatedText, setAnimatedText] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);
  const [isMultiLine, setIsMultiLine] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [audioBlob, setAudioBlob] = useState(null);
  const [stream, setStream] = useState(null);  // Добавлено состояние для потока
  const { isAuthenticated } = useAuth();
  const textareaRef = useRef(null);
  const intervalRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const supportedFormats = checkMediaRecorderSupport();
  const audioFormat = supportedFormats.length > 0 ? supportedFormats[0] : 'audio/webm'; // Используем первый поддерживаемый формат или 'audio/webm' по умолчанию

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      setIsMultiLine(textareaRef.current.scrollHeight > textareaRef.current.clientHeight);
    }
  }, [value, animatedText]);

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated) {
        try {
          const response = await axios.get(`${BACKEND_DOMAIN}/aboutCompany`, { withCredentials: true });
          setValue(response.data.companyInfo === 'none' ? '' : response.data.companyInfo);
        } catch (error) {
          console.error('Error fetching company info:', error);
        }
      }
    };
    fetchData();
  }, [isAuthenticated]);

  const animateText = (text) => {
    setIsAnimating(true);
    setAnimatedText('');
    let index = 0;
    const interval = setInterval(() => {
      if (index < text.length) {
        setAnimatedText(prev => prev + text.charAt(index));
        index++;
      } else {
        clearInterval(interval);
        setIsAnimating(false);
      }
    }, 50);
  };

  const handleChange = (event) => {
    if (!isAnimating) {
      const newValue = event.target.value;
      setValue(newValue);
      saveCompanyInfo(newValue === '' ? 'none' : newValue);
    }
  };

  const saveCompanyInfo = async (info) => {
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/aboutCompany`, { companyInfo: info }, { withCredentials: true });
      console.log('Save response:', response);
    } catch (error) {
      console.error('Error saving company info:', error);
    }
  };

  useEffect(() => {
    if (audioBlob && !isRecording) {
      const formData = new FormData();
      formData.append("audio", audioBlob, audioFormat);
      axios.post(`${BACKEND_DOMAIN}/audioToText`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true
      })
      .then(response => {
          const transcribedText = response.data.transcription;
          setValue(''); // Очистить текущее значение
          setTimeout(() => setValue(transcribedText), 0); 
          animateText(transcribedText); // Запуск анимации после получения текста
      })
      .catch(error => {
          console.error("Error in audio transcription:", error.response ? error.response.data : error.message);
      });
    }
  }, [audioBlob, isRecording]);

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        setStream(stream);  // Сохранение ссылки на поток
        console.log('Формат использкется в браузере ' + audioFormat);
        const mediaRecorder = new MediaRecorder(stream, { mimeType: audioFormat });
        const audioChunks = [];
        mediaRecorder.ondataavailable = event => audioChunks.push(event.data);
        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunks, { type: audioFormat });
          console.log("Blob created:", audioBlob);
          setAudioBlob(audioBlob);
        };
        mediaRecorder.start();

        intervalRef.current = setInterval(() => {
          setRecordingTime(prevTime => prevTime + 1);
        }, 1000);
        setIsRecording(true);
        mediaRecorderRef.current = mediaRecorder;
      })
      .catch(error => console.error("Error accessing audio devices:", error));
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      stream.getTracks().forEach(track => track.stop());  // Остановка всех треков потока
    }
    setIsRecording(false);
    clearInterval(intervalRef.current);
  };

  const resetRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== "inactive") {
      mediaRecorderRef.current.stop();
      stream.getTracks().forEach(track => track.stop());  // Остановка всех треков потока
    }
    clearInterval(intervalRef.current);
    setRecordingTime(0);
    setValue('');
    setAudioBlob(null);
  };

  const confirmRecording = () => {
    stopRecording();
  };

  const formatTime = (time) => {
    const minutes = String(Math.floor(time / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="ml" mb={6} color="white">
        Расскажите о компании/продукте:
      </Heading>
      {isRecording ? (
        <Flex
          width="full"
          maxW="sm"
          borderRadius="full"
          borderWidth="1px"
          p={3}
          alignItems="center"
          bg="blue.900"
          color="white"
          minWidth="227px"
        >
          <IconButton
            aria-label="Cancel"
            icon={<FiX />}
            variant="solid"
            borderRadius="full"
            size="sm"
            onClick={resetRecording}
            colorScheme="blue"
            bg="blue.600"
            flexShrink={0}
          />
          <Flex flex="1" mx={3} alignItems="center" justifyContent="center" minWidth="52px">
            <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="1rem">
              {Array.from({ length: 25 }).map((_, index) => (
                <Box
                  key={index}
                  width="2px"
                  height={`${Math.random() * 100}%`}
                  backgroundColor="blue.400"
                  mx="1px"
                />
              ))}
            </Box>
          </Flex>
          <Text ml={3} mr={3} size="sm" color="blue.400" minWidth="max-content" fontFamily="monospace" width="48px" textAlign="center">{formatTime(recordingTime)}</Text>
          <IconButton
            aria-label="Confirm"
            icon={<FiCheck />}
            variant="solid"
            borderRadius="full"
            size="sm"
            onClick={confirmRecording}
            colorScheme="blue"
            bg="blue.600"
            flexShrink={0}
          />
        </Flex>
      ) : (
        <InputGroup size="lg" width="full" maxW="md" position="relative">
          <Textarea
            ref={textareaRef}
            value={isAnimating ? animatedText : value}
            onChange={handleChange}
            placeholder="Сообщение"
            borderRadius={isMultiLine ? 'md' : 'full'}
            borderColor="gray.400"
            _placeholder={{ color: 'gray.500' }}
            resize="none"
            pr="4rem"
            minH="3rem"
            overflow="hidden"
            lineHeight="1.5"
            rows={1}
            _focus={{ boxShadow: "0 0 0 1px #3182ce" }}
          />
          <InputRightElement width="4rem">
            <IconButton
              aria-label="Microphone"
              icon={<FiMic />}
              variant="solid"
              borderRadius="full"
              size="lg"
              backgroundColor="transparent"
              _hover={{ backgroundColor: 'transparent' }}
              _active={{ backgroundColor: 'transparent' }}
              onClick={isRecording ? stopRecording : startRecording}
            />
          </InputRightElement>
        </InputGroup>
      )}
    </Box>
  );
};

export default AboutCompanyTab;*/




/*import React, { useState, useEffect, useRef } from 'react';
import { Box, InputGroup, Textarea, InputRightElement, IconButton, Heading, Flex, Text } from '@chakra-ui/react';
import { FiMic, FiCheck, FiX } from 'react-icons/fi';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const AboutCompanyTab = () => {
  const [value, setValue] = useState('');
  const [animatedText, setAnimatedText] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);
  const [isMultiLine, setIsMultiLine] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [audioBlob, setAudioBlob] = useState(null);
  const [stream, setStream] = useState(null);  // Добавлено состояние для потока
  const { isAuthenticated } = useAuth();
  const textareaRef = useRef(null);
  const intervalRef = useRef(null);
  const mediaRecorderRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      setIsMultiLine(textareaRef.current.scrollHeight > textareaRef.current.clientHeight);
    }
  }, [value, animatedText]);

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated) {
        try {
          const response = await axios.get(`${BACKEND_DOMAIN}/aboutCompany`, { withCredentials: true });
          setValue(response.data.companyInfo === 'none' ? '' : response.data.companyInfo);
        } catch (error) {
          console.error('Error fetching company info:', error);
        }
      }
    };
    fetchData();
  }, [isAuthenticated]);

  const animateText = (text) => {
    setIsAnimating(true);
    setAnimatedText('');
    let index = 0;
    const interval = setInterval(() => {
      if (index < text.length) {
        setAnimatedText(prev => prev + text.charAt(index));
        index++;
      } else {
        clearInterval(interval);
        setIsAnimating(false);
      }
    }, 50);
  };

  const handleChange = (event) => {
    if (!isAnimating) {
      const newValue = event.target.value;
      setValue(newValue);
      saveCompanyInfo(newValue === '' ? 'none' : newValue);
    }
  };

  const saveCompanyInfo = async (info) => {
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/aboutCompany`, { companyInfo: info }, { withCredentials: true });
      console.log('Save response:', response);
    } catch (error) {
      console.error('Error saving company info:', error);
    }
  };

  useEffect(() => {
    if (audioBlob && !isRecording) {
      const formData = new FormData();
      formData.append("audio", audioBlob, "audio.webm");
      axios.post(`${BACKEND_DOMAIN}/audioToText`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true
      })
      .then(response => {
          const transcribedText = response.data.transcription;
          setValue(''); // Очистить текущее значение
          setTimeout(() => setValue(transcribedText), 0); 
          animateText(transcribedText); // Запуск анимации после получения текста
      })
      .catch(error => {
          console.error("Error in audio transcription:", error.response ? error.response.data : error.message);
      });
    }
  }, [audioBlob, isRecording]);

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        setStream(stream);  // Сохранение ссылки на поток
        const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
        const audioChunks = [];
        mediaRecorder.ondataavailable = event => audioChunks.push(event.data);
        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
          console.log("Blob created:", audioBlob);
          setAudioBlob(audioBlob);
        };
        mediaRecorder.start();

        intervalRef.current = setInterval(() => {
          setRecordingTime(prevTime => prevTime + 1);
        }, 1000);
        setIsRecording(true);
        mediaRecorderRef.current = mediaRecorder;
      })
      .catch(error => console.error("Error accessing audio devices:", error));
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      stream.getTracks().forEach(track => track.stop());  // Остановка всех треков потока
    }
    setIsRecording(false);
    clearInterval(intervalRef.current);
  };

  const resetRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== "inactive") {
      mediaRecorderRef.current.stop();
      stream.getTracks().forEach(track => track.stop());  // Остановка всех треков потока
    }
    clearInterval(intervalRef.current);
    setRecordingTime(0);
    setValue('');
    setAudioBlob(null);
  };

  const confirmRecording = () => {
    stopRecording();
  };

  const formatTime = (time) => {
    const minutes = String(Math.floor(time / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="ml" mb={6} color="white">
        Расскажите о компании/продукте:
      </Heading>
      {isRecording ? (
        <Flex
          width="full"
          maxW="sm"
          borderRadius="full"
          borderWidth="1px"
          p={3}
          alignItems="center"
          bg="blue.900"
          color="white"
          minWidth="227px"
        >
          <IconButton
            aria-label="Cancel"
            icon={<FiX />}
            variant="solid"
            borderRadius="full"
            size="sm"
            onClick={resetRecording}
            colorScheme="blue"
            bg="blue.600"
            flexShrink={0}
          />
          <Flex flex="1" mx={3} alignItems="center" justifyContent="center" minWidth="52px">
            <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="1rem">
              {Array.from({ length: 25 }).map((_, index) => (
                <Box
                  key={index}
                  width="2px"
                  height={`${Math.random() * 100}%`}
                  backgroundColor="blue.400"
                  mx="1px"
                />
              ))}
            </Box>
          </Flex>
          <Text ml={3} mr={3} size="sm" color="blue.400" minWidth="max-content" fontFamily="monospace" width="48px" textAlign="center">{formatTime(recordingTime)}</Text>
          <IconButton
            aria-label="Confirm"
            icon={<FiCheck />}
            variant="solid"
            borderRadius="full"
            size="sm"
            onClick={confirmRecording}
            colorScheme="blue"
            bg="blue.600"
            flexShrink={0}
          />
        </Flex>
      ) : (
        <InputGroup size="lg" width="full" maxW="md" position="relative">
          <Textarea
            ref={textareaRef}
            value={isAnimating ? animatedText : value}
            onChange={handleChange}
            placeholder="Сообщение"
            borderRadius={isMultiLine ? 'md' : 'full'}
            borderColor="gray.400"
            _placeholder={{ color: 'gray.500' }}
            resize="none"
            pr="4rem"
            minH="3rem"
            overflow="hidden"
            lineHeight="1.5"
            rows={1}
            _focus={{ boxShadow: "0 0 0 1px #3182ce" }}
          />
          <InputRightElement width="4rem">
            <IconButton
              aria-label="Microphone"
              icon={<FiMic />}
              variant="solid"
              borderRadius="full"
              size="lg"
              backgroundColor="transparent"
              _hover={{ backgroundColor: 'transparent' }}
              _active={{ backgroundColor: 'transparent' }}
              onClick={isRecording ? stopRecording : startRecording}
            />
          </InputRightElement>
        </InputGroup>
      )}
    </Box>
  );
};

export default AboutCompanyTab;


/*import React, { useState, useEffect, useRef } from 'react';
import { Box, InputGroup, Textarea, InputRightElement, IconButton, Heading, Flex, Text } from '@chakra-ui/react';
import { FiMic, FiCheck, FiX } from 'react-icons/fi';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const AboutCompanyTab = () => {
  const [value, setValue] = useState('');
  const [animatedText, setAnimatedText] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);
  const [isMultiLine, setIsMultiLine] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [audioBlob, setAudioBlob] = useState(null);
  const { isAuthenticated } = useAuth();
  const textareaRef = useRef(null);
  const intervalRef = useRef(null);
  const mediaRecorderRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      setIsMultiLine(textareaRef.current.scrollHeight > textareaRef.current.clientHeight);
    }
  }, [value, animatedText]);

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated) {
        try {
          const response = await axios.get(`${BACKEND_DOMAIN}/aboutCompany`, { withCredentials: true });
          setValue(response.data.companyInfo === 'none' ? '' : response.data.companyInfo);
        } catch (error) {
          console.error('Error fetching company info:', error);
        }
      }
    };
    fetchData();
  }, [isAuthenticated]);

  const animateText = (text) => {
    setIsAnimating(true);
    setAnimatedText('');
    let index = 0;
    const interval = setInterval(() => {
      if (index < text.length) {
        setAnimatedText(prev => prev + text.charAt(index));
        index++;
      } else {
        clearInterval(interval);
        setIsAnimating(false);
      }
    }, 50);
  };

  const handleChange = (event) => {
    if (!isAnimating) {
      const newValue = event.target.value;
      setValue(newValue);
      saveCompanyInfo(newValue === '' ? 'none' : newValue);
    }
  };

  const saveCompanyInfo = async (info) => {
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/aboutCompany`, { companyInfo: info }, { withCredentials: true });
      console.log('Save response:', response);
    } catch (error) {
      console.error('Error saving company info:', error);
    }
  };

  useEffect(() => {
    if (audioBlob && !isRecording) {
      const formData = new FormData();
      formData.append("audio", audioBlob, "audio.webm");
      axios.post(`${BACKEND_DOMAIN}/audioToText`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true
      })
      .then(response => {
          const transcribedText = response.data.transcription;
          setValue(''); // Очистить текущее значение
          setTimeout(() => setValue(transcribedText), 0); 
          animateText(transcribedText); // Запуск анимации после получения текста
      })
      .catch(error => {
          console.error("Error in audio transcription:", error.response ? error.response.data : error.message);
      });
    }
  }, [audioBlob, isRecording]);

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
        const audioChunks = [];
        mediaRecorder.ondataavailable = event => audioChunks.push(event.data);
        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
          console.log("Blob created:", audioBlob);
          setAudioBlob(audioBlob);
        };
        mediaRecorder.start();

        intervalRef.current = setInterval(() => {
          setRecordingTime(prevTime => prevTime + 1);
        }, 1000);
        setIsRecording(true);
        mediaRecorderRef.current = mediaRecorder;
      })
      .catch(error => console.error("Error accessing audio devices:", error));
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    setIsRecording(false);
    clearInterval(intervalRef.current);
  };

  const resetRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== "inactive") {
      mediaRecorderRef.current.stop();
    }
    clearInterval(intervalRef.current);
    setRecordingTime(0);
    setValue('');
    setAudioBlob(null);
  };

  const confirmRecording = () => {
    stopRecording();
  };

  const formatTime = (time) => {
    const minutes = String(Math.floor(time / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="ml" mb={6} color="white">
        Расскажите о компании/продукте:
      </Heading>
      {isRecording ? (
        <Flex
          width="full"
          maxW="sm"
          borderRadius="full"
          borderWidth="1px"
          p={3}
          alignItems="center"
          bg="blue.900"
          color="white"
          minWidth="227px"
        >
          <IconButton
            aria-label="Cancel"
            icon={<FiX />}
            variant="solid"
            borderRadius="full"
            size="sm"
            onClick={resetRecording}
            colorScheme="blue"
            bg="blue.600"
            flexShrink={0}
          />
          <Flex flex="1" mx={3} alignItems="center" justifyContent="center" minWidth="52px">
            <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="1rem">
              {Array.from({ length: 25 }).map((_, index) => (
                <Box
                  key={index}
                  width="2px"
                  height={`${Math.random() * 100}%`}
                  backgroundColor="blue.400"
                  mx="1px"
                />
              ))}
            </Box>
          </Flex>
          <Text ml={3} mr={3} size="sm" color="blue.400" minWidth="max-content" fontFamily="monospace" width="48px" textAlign="center">{formatTime(recordingTime)}</Text>
          <IconButton
            aria-label="Confirm"
            icon={<FiCheck />}
            variant="solid"
            borderRadius="full"
            size="sm"
            onClick={confirmRecording}
            colorScheme="blue"
            bg="blue.600"
            flexShrink={0}
          />
        </Flex>
      ) : (
        <InputGroup size="lg" width="full" maxW="md" position="relative">
          <Textarea
            ref={textareaRef}
            value={isAnimating ? animatedText : value}
            onChange={handleChange}
            placeholder="Сообщение"
            borderRadius={isMultiLine ? 'md' : 'full'}
            borderColor="gray.400"
            _placeholder={{ color: 'gray.500' }}
            resize="none"
            pr="4rem"
            minH="3rem"
            overflow="hidden"
            lineHeight="1.5"
            rows={1}
            _focus={{ boxShadow: "0 0 0 1px #3182ce" }}
          />
          <InputRightElement width="4rem">
            <IconButton
              aria-label="Microphone"
              icon={<FiMic />}
              variant="solid"
              borderRadius="full"
              size="lg"
              backgroundColor="transparent"
              _hover={{ backgroundColor: 'transparent' }}
              _active={{ backgroundColor: 'transparent' }}
              onClick={isRecording ? stopRecording : startRecording}
            />
          </InputRightElement>
        </InputGroup>
      )}
    </Box>
  );
};

export default AboutCompanyTab; */




/*import React, { useState, useEffect, useRef } from 'react';
import {
  Box, InputGroup, Textarea, InputRightElement, IconButton, Heading, Flex, Text
} from '@chakra-ui/react';
import { FiMic, FiCheck, FiX } from 'react-icons/fi';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const AboutCompanyTab = () => {
  const [value, setValue] = useState('');
  const [isMultiLine, setIsMultiLine] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [audioBlob, setAudioBlob] = useState(null);
  const { isAuthenticated } = useAuth();
  const textareaRef = useRef(null);
  const intervalRef = useRef(null);
  const mediaRecorderRef = useRef(null);


  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      setIsMultiLine(textareaRef.current.scrollHeight > textareaRef.current.clientHeight);
    }
  }, [value]);

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated) {
        try {
          const response = await axios.get(`${BACKEND_DOMAIN}/aboutCompany`, { withCredentials: true });
          setValue(response.data.companyInfo === 'none' ? '' : response.data.companyInfo);
        } catch (error) {
          console.error('Error fetching company info:', error);
        }
      }
    };
    fetchData();
  }, [isAuthenticated]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    saveCompanyInfo(newValue === '' ? 'none' : newValue);
  };


  const saveCompanyInfo = async (info) => {
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/aboutCompany`, { companyInfo: info }, { withCredentials: true });
      console.log('Save response:', response);
    } catch (error) {
      console.error('Error saving company info:', error);
    }
  };

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' }); // Изменено на audio/webm
        const audioChunks = [];
        mediaRecorder.ondataavailable = event => audioChunks.push(event.data);
        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunks, { type: 'audio/webm' }); // Изменено на audio/webm
          console.log("Blob created:", audioBlob);
          setAudioBlob(audioBlob);
        };
        mediaRecorder.start();

        intervalRef.current = setInterval(() => {
          setRecordingTime(prevTime => prevTime + 1);
        }, 1000);
        setIsRecording(true);
        mediaRecorderRef.current = mediaRecorder;
      })
      .catch(error => console.error("Error accessing audio devices:", error));
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    setIsRecording(false);
    clearInterval(intervalRef.current);
  };

  const resetRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== "inactive") {
        mediaRecorderRef.current.stop();
    }
    clearInterval(intervalRef.current);
    setRecordingTime(0);
    setValue('');
    setAudioBlob(null);
  };

  const confirmRecording = () => {
    stopRecording();
  };

  useEffect(() => {
    if (audioBlob && !isRecording) {
      const formData = new FormData();
      formData.append("audio", audioBlob, "audio.webm"); // Добавляем имя файла с расширением
      axios.post(`${BACKEND_DOMAIN}/audioToText`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true
      })
      .then(response => {
          const transcribedText = response.data.transcription;
          setValue(''); // Очистить текущее значение
          setTimeout(() => setValue(transcribedText), 0); 
          // Запускаем анимацию текста
          
          console.log('ответ сервера после транскрибации ' + transcribedText);
          
          
      })
      .catch(error => {
          console.error("Error in audio transcription:", error.response ? error.response.data : error.message);
      });
    }
  }, [audioBlob, isRecording]);

  const formatTime = (time) => {
    const minutes = String(Math.floor(time / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="ml" mb={6} color="white">
        Расскажите о компании/продукте:
      </Heading>
      {isRecording ? (
        <Flex
          width="full"
          maxW="sm"
          borderRadius="full"
          borderWidth="1px"
          p={3}
          alignItems="center"
          bg="blue.900"
          color="white"
          minWidth="227px"
        >
          <IconButton
            aria-label="Cancel"
            icon={<FiX />}
            variant="solid"
            borderRadius="full"
            size="sm"
            onClick={resetRecording}
            colorScheme="blue"
            bg="blue.600"
            flexShrink={0}
          />
          <Flex flex="1" mx={3} alignItems="center" justifyContent="center" minWidth="52px">
            <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="1rem">
              {Array.from({ length: 25 }).map((_, index) => (
                <Box
                  key={index}
                  width="2px"
                  height={`${Math.random() * 100}%`}
                  backgroundColor="blue.400"
                  mx="1px"
                />
              ))}
            </Box>
          </Flex>
          <Text ml={3} mr={3} size="sm" color="blue.400" minWidth="max-content" fontFamily="monospace" width="48px" textAlign="center">{formatTime(recordingTime)}</Text>
          <IconButton
            aria-label="Confirm"
            icon={<FiCheck />}
            variant="solid"
            borderRadius="full"
            size="sm"
            onClick={confirmRecording}
            colorScheme="blue"
            bg="blue.600"
            flexShrink={0}
          />
        </Flex>
      ) : (
        <InputGroup size="lg" width="full" maxW="md" position="relative">
          <Textarea
            ref={textareaRef}
            value={value}
            onChange={handleChange}
            placeholder="Сообщение"
            borderRadius={isMultiLine ? 'md' : 'full'}
            borderColor="gray.400"
            _placeholder={{ color: 'gray.500' }}
            resize="none"
            pr="4rem"
            minH="3rem"
            overflow="hidden"
            lineHeight="1.5"
            rows={1} // Ensures the initial height is that of a single line
            _focus={{ boxShadow: "0 0 0 1px #3182ce" }}
          />
          <InputRightElement width="4rem">
            <IconButton
              aria-label="Microphone"
              icon={<FiMic />}
              variant="solid"
              borderRadius="full"
              size="lg"
              backgroundColor="transparent"
              _hover={{ backgroundColor: 'transparent' }}
              _active={{ backgroundColor: 'transparent' }}
              onClick={isRecording ? stopRecording : startRecording}
            />
          </InputRightElement>
        </InputGroup>
      )}
    </Box>
  );
};

export default AboutCompanyTab;*/




/*import React, { useState, useEffect, useRef } from 'react';
import {
  Box, InputGroup, Textarea, InputRightElement, IconButton, Heading, Flex, Text
} from '@chakra-ui/react';
import { FiMic, FiCheck, FiX } from 'react-icons/fi';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const AboutCompanyTab = () => {
  const [value, setValue] = useState('');
  const [isMultiLine, setIsMultiLine] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [audioBlob, setAudioBlob] = useState(null);
  const { isAuthenticated } = useAuth();
  const textareaRef = useRef(null);
  const intervalRef = useRef(null);
  const mediaRecorderRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      setIsMultiLine(textareaRef.current.scrollHeight > textareaRef.current.clientHeight);
    }
  }, [value]);

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated) {
        try {
          const response = await axios.get(`${BACKEND_DOMAIN}/aboutCompany`, { withCredentials: true });
          setValue(response.data.companyInfo === 'none' ? '' : response.data.companyInfo);
        } catch (error) {
          console.error('Error fetching company info:', error);
        }
      }
    };
    fetchData();
  }, [isAuthenticated]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    saveCompanyInfo(newValue === '' ? 'none' : newValue);
  };

  const saveCompanyInfo = async (info) => {
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/aboutCompany`, { companyInfo: info }, { withCredentials: true });
      console.log('Save response:', response);
    } catch (error) {
      console.error('Error saving company info:', error);
    }
  };

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        const mediaRecorder = new MediaRecorder(stream);
        const audioChunks = [];
        mediaRecorder.ondataavailable = event => audioChunks.push(event.data);
        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunks, { type: 'audio/mp3' });
          console.log("Blob created:", audioBlob);
          setAudioBlob(audioBlob);
        };
        mediaRecorder.start();

        intervalRef.current = setInterval(() => {
          setRecordingTime(prevTime => prevTime + 1);
        }, 1000);
        setIsRecording(true);
        mediaRecorderRef.current = mediaRecorder;
      })
      .catch(error => console.error("Error accessing audio devices:", error));
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    setIsRecording(false);
    clearInterval(intervalRef.current);
  };

  const resetRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== "inactive") {
        mediaRecorderRef.current.stop();
    }
    clearInterval(intervalRef.current);
    setRecordingTime(0);
    setValue('');
    setAudioBlob(null);
  };

  const confirmRecording = () => {
    stopRecording();
  };

  useEffect(() => {
    if (audioBlob && !isRecording) {
      const formData = new FormData();
      formData.append("audio", audioBlob);
      axios.post(`${BACKEND_DOMAIN}/audioToText`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true
      })
      .then(response => {
          const transcribedText = response.data.text;
          setValue(transcribedText);
          saveCompanyInfo(transcribedText);
      })
      .catch(error => {
          console.error("Error in audio transcription:", error.response ? error.response.data : error.message);
      });
    }
  }, [audioBlob, isRecording]);

  const formatTime = (time) => {
    const minutes = String(Math.floor(time / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="ml" mb={6} color="white">
        Расскажите о компании/продукте:
      </Heading>
      {isRecording ? (
        <Flex
          width="full"
          maxW="sm"
          borderRadius="full"
          borderWidth="1px"
          p={3}
          alignItems="center"
          bg="blue.900"
          color="white"
          minWidth="227px"
        >
          <IconButton
            aria-label="Cancel"
            icon={<FiX />}
            variant="solid"
            borderRadius="full"
            size="sm"
            onClick={resetRecording}
            colorScheme="blue"
            bg="blue.600"
            flexShrink={0}
          />
          <Flex flex="1" mx={3} alignItems="center" justifyContent="center" minWidth="52px">
            <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="1rem">
              {Array.from({ length: 25 }).map((_, index) => (
                <Box
                  key={index}
                  width="2px"
                  height={`${Math.random() * 100}%`}
                  backgroundColor="blue.400"
                  mx="1px"
                />
              ))}
            </Box>
          </Flex>
          <Text ml={3} mr={3} size="sm" color="blue.400" minWidth="max-content" fontFamily="monospace" width="48px" textAlign="center">{formatTime(recordingTime)}</Text>
          <IconButton
            aria-label="Confirm"
            icon={<FiCheck />}
            variant="solid"
            borderRadius="full"
            size="sm"
            onClick={confirmRecording}
            colorScheme="blue"
            bg="blue.600"
            flexShrink={0}
          />
        </Flex>
      ) : (
        <InputGroup size="lg" width="full" maxW="md" position="relative">
          <Textarea
            ref={textareaRef}
            value={value}
            onChange={handleChange}
            placeholder="Сообщение"
            borderRadius={isMultiLine ? 'md' : 'full'}
            borderColor="gray.400"
            _placeholder={{ color: 'gray.500' }}
            resize="none"
            pr="4rem"
            minH="3rem"
            overflow="hidden"
            lineHeight="1.5"
            rows={1} // Ensures the initial height is that of a single line
            _focus={{ boxShadow: "0 0 0 1px #3182ce" }}
          />
          <InputRightElement width="4rem">
            <IconButton
              aria-label="Microphone"
              icon={<FiMic />}
              variant="solid"
              borderRadius="full"
              size="lg"
              backgroundColor="transparent"
              _hover={{ backgroundColor: 'transparent' }}
              _active={{ backgroundColor: 'transparent' }}
              onClick={isRecording ? stopRecording : startRecording}
            />
          </InputRightElement>
        </InputGroup>
      )}
    </Box>
  );
};

export default AboutCompanyTab;*/



/*import React, { useState, useEffect, useRef } from 'react';
import {
  Box, InputGroup, Textarea, InputRightElement, IconButton, Heading, Flex, Text
} from '@chakra-ui/react';
import { FiMic, FiCheck, FiX } from 'react-icons/fi';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const AboutCompanyTab = () => {
  const [value, setValue] = useState('');
  const [isMultiLine, setIsMultiLine] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const { isAuthenticated } = useAuth();
  const textareaRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

      if (textareaRef.current.scrollHeight > textareaRef.current.clientHeight) {
        setIsMultiLine(true);
      } else {
        setIsMultiLine(false);
      }
    }
  }, [value]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isAuthenticated) {
          const response = await axios.get(`${BACKEND_DOMAIN}/aboutCompany`, { withCredentials: true });
          setValue(response.data.companyInfo === 'none' ? '' : response.data.companyInfo);
        }
      } catch (error) {
        console.error('Error fetching company info:', error);
      }
    };

    fetchData();
  }, [isAuthenticated]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);

    // Check if the newValue is empty and set to 'none' if it is
    saveCompanyInfo(newValue === '' ? 'none' : newValue);
  };

  const saveCompanyInfo = async (info) => {
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/aboutCompany`, { companyInfo: info }, { withCredentials: true });
      console.log('Save response:', response);
    } catch (error) {
      console.error('Error saving company info:', error);
    }
  };

  const startRecording = () => {
    setIsRecording(true);
    setRecordingTime(0);
    intervalRef.current = setInterval(() => {
      setRecordingTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const stopRecording = () => {
    setIsRecording(false);
    clearInterval(intervalRef.current);
  };

  const resetRecording = () => {
    stopRecording();
    setRecordingTime(0);
    setValue('');
  };

  const confirmRecording = () => {
    stopRecording();
    // Here you would handle the recorded data
    setValue(''); // Reset the value for now
  };

  const formatTime = (time) => {
    const minutes = String(Math.floor(time / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="ml" mb={6} color="white">
        Расскажите о компании/продукте:
      </Heading>
      {isRecording ? (
        <Flex
          width="full"
          maxW="sm"
          borderRadius="full"
          borderWidth="1px"
          p={3}
          alignItems="center"
          bg="blue.900"
          color="white"
          minWidth="227px"
        >
          <IconButton
            aria-label="Cancel"
            icon={<FiX />}
            variant="solid"
            borderRadius="full"
            size="sm"
            onClick={resetRecording}
            colorScheme="blue"
            bg="blue.600"
            flexShrink={0}
          />
          <Flex flex="1" mx={3} alignItems="center" justifyContent="center" minWidth="52px">
            <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="1rem">
              {Array.from({ length: 25 }).map((_, index) => (
                <Box
                  key={index}
                  width="2px"
                  height={`${Math.random() * 100}%`}
                  backgroundColor="blue.400"
                  mx="1px"
                />
              ))}
            </Box>
          </Flex>
          <Text ml={3} mr={3} size="sm" color="blue.400" minWidth="max-content" fontFamily="monospace" width="48px" textAlign="center">{formatTime(recordingTime)}</Text>
          <IconButton
            aria-label="Confirm"
            icon={<FiCheck />}
            variant="solid"
            borderRadius="full"
            size="sm"
            onClick={confirmRecording}
            colorScheme="blue"
            bg="blue.600"
            flexShrink={0}
          />
        </Flex>
      ) : (
        <InputGroup size="lg" width="full" maxW="md" position="relative">
          <Textarea
            ref={textareaRef}
            value={value}
            onChange={handleChange}
            placeholder="Сообщение"
            borderRadius={isMultiLine ? 'md' : 'full'}
            borderColor="gray.400"
            _placeholder={{ color: 'gray.500' }}
            resize="none"
            pr="4rem"
            minH="3rem"
            overflow="hidden"
            lineHeight="1.5"
            rows={1} // Ensures the initial height is that of a single line
            _focus={{ boxShadow: "0 0 0 1px #3182ce" }}
          />
          <InputRightElement width="4rem">
            <IconButton
              aria-label="Microphone"
              icon={<FiMic />}
              variant="solid"
              borderRadius="full"
              size="lg"
              backgroundColor="transparent"
              _hover={{ backgroundColor: 'transparent' }}
              _active={{ backgroundColor: 'transparent' }}
              onClick={startRecording}
            />
          </InputRightElement>
        </InputGroup>
      )}
    </Box>
  );
};

export default AboutCompanyTab;*/






/*// src/pages/tabs/AboutCompanyTab.jsx
import React, { useState, useEffect, useRef } from 'react';
import {
  Box, InputGroup, Textarea, InputRightElement, IconButton, Heading, Flex, Text
} from '@chakra-ui/react';
import { FiMic, FiCheck, FiX } from 'react-icons/fi';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const AboutCompanyTab = () => {
  const [value, setValue] = useState('');
  const [isMultiLine, setIsMultiLine] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const { isAuthenticated } = useAuth();
  const textareaRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

      if (textareaRef.current.scrollHeight > textareaRef.current.clientHeight) {
        setIsMultiLine(true);
      } else {
        setIsMultiLine(false);
      }
    }
  }, [value]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isAuthenticated) {
          const response = await axios.get(`${BACKEND_DOMAIN}/aboutCompany`, { withCredentials: true });
          setValue(response.data.companyInfo);
        }
      } catch (error) {
        console.error('Error fetching company info:', error);
      }
    };

    fetchData();
  }, [isAuthenticated]);

  const handleChange = (event) => {
    setValue(event.target.value);
    saveCompanyInfo(event.target.value);
  };

  const saveCompanyInfo = async (info) => {
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/aboutCompany`, { companyInfo: info }, { withCredentials: true });
      console.log('Save response:', response);
    } catch (error) {
      console.error('Error saving company info:', error);
    }
  };

  const startRecording = () => {
    setIsRecording(true);
    setRecordingTime(0);
    intervalRef.current = setInterval(() => {
      setRecordingTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const stopRecording = () => {
    setIsRecording(false);
    clearInterval(intervalRef.current);
  };

  const resetRecording = () => {
    stopRecording();
    setRecordingTime(0);
    setValue('');
  };

  const confirmRecording = () => {
    stopRecording();
    // Here you would handle the recorded data
    setValue(''); // Reset the value for now
  };

  const formatTime = (time) => {
    const minutes = String(Math.floor(time / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="ml" mb={6} color="white">
        Расскажите о компании/продукте:
      </Heading>
      {isRecording ? (
        <Flex
          width="full"
          maxW="sm"
          borderRadius="full"
          borderWidth="1px"
          p={3}
          alignItems="center"
          bg="blue.900"
          color="white"
          minWidth="227px"
        >
          <IconButton
            aria-label="Cancel"
            icon={<FiX />}
            variant="solid"
            borderRadius="full"
            size="sm"
            onClick={resetRecording}
            colorScheme="blue"
            bg="blue.600"
            flexShrink={0}
          />
          <Flex flex="1" mx={3} alignItems="center" justifyContent="center" minWidth="52px">
            <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="1rem">
              {Array.from({ length: 25 }).map((_, index) => (
                <Box
                  key={index}
                  width="2px"
                  height={`${Math.random() * 100}%`}
                  backgroundColor="blue.400"
                  mx="1px"
                />
              ))}
            </Box>
          </Flex>
          <Text ml={3} mr={3} size="sm" color="blue.400" minWidth="max-content" fontFamily="monospace" width="48px" textAlign="center">{formatTime(recordingTime)}</Text>
          <IconButton
            aria-label="Confirm"
            icon={<FiCheck />}
            variant="solid"
            borderRadius="full"
            size="sm"
            onClick={confirmRecording}
            colorScheme="blue"
            bg="blue.600"
            flexShrink={0}
          />
        </Flex>
      ) : (
        <InputGroup size="lg" width="full" maxW="md" position="relative">
          <Textarea
            ref={textareaRef}
            value={value}
            onChange={handleChange}
            placeholder="Сообщение"
            borderRadius={isMultiLine ? 'md' : 'full'}
            borderColor="gray.400"
            _placeholder={{ color: 'gray.500' }}
            resize="none"
            pr="4rem"
            minH="3rem"
            overflow="hidden"
            lineHeight="1.5"
            rows={1} // Ensures the initial height is that of a single line
            _focus={{ boxShadow: "0 0 0 1px #3182ce" }}
          />
          <InputRightElement width="4rem">
            <IconButton
              aria-label="Microphone"
              icon={<FiMic />}
              variant="solid"
              borderRadius="full"
              size="lg"
              backgroundColor="transparent"
              _hover={{ backgroundColor: 'transparent' }}
              _active={{ backgroundColor: 'transparent' }}
              onClick={startRecording}
            />
          </InputRightElement>
        </InputGroup>
      )}
    </Box>
  );
};

export default AboutCompanyTab;*/




//до подключения базы данных
/*import React, { useState, useRef, useEffect } from 'react';
import { Box, InputGroup, Textarea, InputRightElement, IconButton, Heading, Flex, Text } from '@chakra-ui/react';
import { FiMic, FiCheck, FiX } from 'react-icons/fi';

const AboutCompanyTab = () => {
  const [value, setValue] = useState('');
  const [isMultiLine, setIsMultiLine] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const textareaRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

      if (textareaRef.current.scrollHeight > textareaRef.current.clientHeight) {
        setIsMultiLine(true);
      } else {
        setIsMultiLine(false);
      }
    }
  }, [value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const startRecording = () => {
    setIsRecording(true);
    setRecordingTime(0);
    intervalRef.current = setInterval(() => {
      setRecordingTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const stopRecording = () => {
    setIsRecording(false);
    clearInterval(intervalRef.current);
  };

  const resetRecording = () => {
    stopRecording();
    setRecordingTime(0);
    setValue('');
  };

  const confirmRecording = () => {
    stopRecording();
    // Here you would handle the recorded data
    setValue(''); // Reset the value for now
  };

  const formatTime = (time) => {
    const minutes = String(Math.floor(time / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="ml" mb={6} color="white">
        Расскажите о компании/продукте:
      </Heading>
      {isRecording ? (
        <Flex
          width="full"
          maxW="sm"
          borderRadius="full"
          borderWidth="1px"
          p={3}
          alignItems="center"
          bg="blue.900"
          color="white"
          minWidth="227px"
        >
          <IconButton
            aria-label="Cancel"
            icon={<FiX />}
            variant="solid"
            borderRadius="full"
            size="sm"
            onClick={resetRecording}
            colorScheme="blue"
            bg="blue.600"
            flexShrink={0}
          />
          <Flex flex="1" mx={3} alignItems="center" justifyContent="center" minWidth="52px">
            <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="1rem">
              {Array.from({ length: 25 }).map((_, index) => (
                <Box
                  key={index}
                  width="2px"
                  height={`${Math.random() * 100}%`}
                  backgroundColor="blue.400"
                  mx="1px"
                />
              ))}
            </Box>
          </Flex>
          <Text ml={3} mr={3} size="sm" color="blue.400" minWidth="max-content" fontFamily="monospace" width="48px" textAlign="center">{formatTime(recordingTime)}</Text>
          <IconButton
            aria-label="Confirm"
            icon={<FiCheck />}
            variant="solid"
            borderRadius="full"
            size="sm"
            onClick={confirmRecording}
            colorScheme="blue"
            bg="blue.600"
            flexShrink={0}
          />
        </Flex>
      ) : (
        <InputGroup size="lg" width="full" maxW="md" position="relative">
          <Textarea
            ref={textareaRef}
            value={value}
            onChange={handleChange}
            placeholder="Сообщение"
            borderRadius={isMultiLine ? 'md' : 'full'}
            borderColor="gray.400"
            _placeholder={{ color: 'gray.500' }}
            resize="none"
            pr="4rem"
            minH="3rem"
            overflow="hidden"
            lineHeight="1.5"
            rows={1} // Ensures the initial height is that of a single line
          />
          <InputRightElement width="4rem">
            <IconButton
              aria-label="Microphone"
              icon={<FiMic />}
              variant="solid"
              borderRadius="full"
              size="lg"
              backgroundColor="transparent"
              _hover={{ backgroundColor: 'transparent' }}
              _active={{ backgroundColor: 'transparent' }}
              onClick={startRecording}
            />
          </InputRightElement>
        </InputGroup>
      )}
    </Box>
  );
};

export default AboutCompanyTab;*/




/*import React, { useState, useRef, useEffect } from 'react';
import { Box, InputGroup, Textarea, InputRightElement, IconButton, Heading, Flex, Text } from '@chakra-ui/react';
import { FiMic, FiCheck, FiX } from 'react-icons/fi';

const AboutCompanyTab = () => {
  const [value, setValue] = useState('');
  const [isMultiLine, setIsMultiLine] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const textareaRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

      if (textareaRef.current.scrollHeight > textareaRef.current.clientHeight) {
        setIsMultiLine(true);
      } else {
        setIsMultiLine(false);
      }
    }
  }, [value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const startRecording = () => {
    setIsRecording(true);
    setRecordingTime(0);
    intervalRef.current = setInterval(() => {
      setRecordingTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const stopRecording = () => {
    setIsRecording(false);
    clearInterval(intervalRef.current);
  };

  const resetRecording = () => {
    stopRecording();
    setRecordingTime(0);
    setValue('');
  };

  const confirmRecording = () => {
    stopRecording();
    // Here you would handle the recorded data
    setValue(''); // Reset the value for now
  };

  const formatTime = (time) => {
    const minutes = String(Math.floor(time / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="xl" mb={6} color="white">
        Расскажите о компании/продукте:
      </Heading>
      {isRecording ? (
        <Flex
          width="full"
          maxW="md"
          borderRadius="full"
          borderColor="gray.400"
          borderWidth="1px"
          p={3}
          alignItems="center"
          bg="blue.900"
          color="white"
        >
          <IconButton
            aria-label="Cancel"
            icon={<FiX />}
            variant="solid"
            borderRadius="full"
            size="md"
            onClick={resetRecording}
            colorScheme="blue"
            bg="blue.600"
          />
          <Flex flex="1" mx={3} alignItems="center" minWidth="20px">
            <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="1rem">
              {Array.from({ length: 30 }).map((_, index) => (
                <Box
                  key={index}
                  width="2px"
                  height={`${Math.random() * 100}%`}
                  backgroundColor="blue.400"
                  mx="1px"
                />
              ))}
            </Box>
            <Text ml={3} minWidth="max-content">{formatTime(recordingTime)}</Text>
          </Flex>
          <IconButton
            aria-label="Confirm"
            icon={<FiCheck />}
            variant="solid"
            borderRadius="full"
            size="md"
            onClick={confirmRecording}
            colorScheme="blue"
            bg="blue.600"
          />
        </Flex>
      ) : (
        <InputGroup size="lg" width="full" maxW="md" position="relative">
          <Textarea
            ref={textareaRef}
            value={value}
            onChange={handleChange}
            placeholder="Сообщение"
            borderRadius={isMultiLine ? 'md' : 'full'}
            borderColor="gray.400"
            _placeholder={{ color: 'gray.500' }}
            resize="none"
            pr="4rem"
            minH="3rem"
            overflow="hidden"
            lineHeight="1.5"
            rows={1} // Ensures the initial height is that of a single line
          />
          <InputRightElement width="4rem">
            <IconButton
              aria-label="Microphone"
              icon={<FiMic />}
              variant="solid"
              borderRadius="full"
              size="lg"
              backgroundColor="transparent"
              _hover={{ backgroundColor: 'transparent' }}
              _active={{ backgroundColor: 'transparent' }}
              onClick={startRecording}
            />
          </InputRightElement>
        </InputGroup>
      )}
    </Box>
  );
};

export default AboutCompanyTab; */



/*import React, { useState, useRef, useEffect } from 'react';
import { Box, InputGroup, Textarea, InputRightElement, IconButton, Heading, Flex, Text, Progress } from '@chakra-ui/react';
import { FiMic, FiCheck, FiX } from 'react-icons/fi';

const AboutCompanyTab = () => {
  const [value, setValue] = useState('');
  const [isMultiLine, setIsMultiLine] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const textareaRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

      if (textareaRef.current.scrollHeight > textareaRef.current.clientHeight) {
        setIsMultiLine(true);
      } else {
        setIsMultiLine(false);
      }
    }
  }, [value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const startRecording = () => {
    setIsRecording(true);
    setRecordingTime(0);
    intervalRef.current = setInterval(() => {
      setRecordingTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const stopRecording = () => {
    setIsRecording(false);
    clearInterval(intervalRef.current);
  };

  const resetRecording = () => {
    stopRecording();
    setRecordingTime(0);
    setValue('');
  };

  const confirmRecording = () => {
    stopRecording();
    // Here you would handle the recorded data
    setValue(''); // Reset the value for now
  };

  const formatTime = (time) => {
    const minutes = String(Math.floor(time / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="xl" mb={6} color="white">
        Расскажите о компании/продукте:
      </Heading>
      {isRecording ? (
        <Flex
          width="full"
          maxW="md"
          borderRadius="full"
          borderColor="gray.400"
          borderWidth="1px"
          p={3}
          alignItems="center"
          justifyContent="space-between"
          bg="blue.900"
          color="white"
        >
          <IconButton
            aria-label="Cancel"
            icon={<FiX />}
            variant="solid"
            borderRadius="full"
            size="md"
            onClick={resetRecording}
            colorScheme="blue"
            bg="blue.600"
          />
          <Flex flex="1" mx={3} alignItems="center">
           
            <Box display="flex" alignItems="center" justifyContent="center" flex="1" height="1rem">
              {Array.from({ length: 30 }).map((_, index) => (
                <Box
                  key={index}
                  width="2px"
                  height={`${Math.random() * 100}%`}
                  backgroundColor="blue.400"
                  mx="1px"
                />
              ))}
            </Box>
            <Text ml={3}>{formatTime(recordingTime)}</Text>
          </Flex>
          <IconButton
            aria-label="Confirm"
            icon={<FiCheck />}
            variant="solid"
            borderRadius="full"
            size="md"
            onClick={confirmRecording}
            colorScheme="blue"
            bg="blue.600"
          />
        </Flex>
      ) : (
        <InputGroup size="lg" width="full" maxW="md" position="relative">
          <Textarea
            ref={textareaRef}
            value={value}
            onChange={handleChange}
            placeholder="Сообщение"
            borderRadius={isMultiLine ? 'md' : 'full'}
            borderColor="gray.400"
            _placeholder={{ color: 'gray.500' }}
            resize="none"
            pr="4rem"
            minH="3rem"
            overflow="hidden"
            lineHeight="1.5"
            rows={1} // Ensures the initial height is that of a single line
          />
          <InputRightElement width="4rem">
            <IconButton
              aria-label="Microphone"
              icon={<FiMic />}
              variant="solid"
              borderRadius="full"
              size="lg"
              backgroundColor="transparent"
              _hover={{ backgroundColor: 'transparent' }}
              _active={{ backgroundColor: 'transparent' }}
              onClick={startRecording}
            />
          </InputRightElement>
        </InputGroup>
      )}
    </Box>
  );
};

export default AboutCompanyTab; */


/*import React, { useState, useRef, useEffect } from 'react';
import { Box, InputGroup, Textarea, InputRightElement, IconButton, Heading, Flex, Text, Progress } from '@chakra-ui/react';
import { FiMic, FiCheck, FiX } from 'react-icons/fi';

const AboutCompanyTab = () => {
  const [value, setValue] = useState('');
  const [isMultiLine, setIsMultiLine] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const textareaRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

      if (textareaRef.current.scrollHeight > textareaRef.current.clientHeight) {
        setIsMultiLine(true);
      } else {
        setIsMultiLine(false);
      }
    }
  }, [value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const startRecording = () => {
    setIsRecording(true);
    setRecordingTime(0);
    intervalRef.current = setInterval(() => {
      setRecordingTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const stopRecording = () => {
    setIsRecording(false);
    clearInterval(intervalRef.current);
  };

  const resetRecording = () => {
    stopRecording();
    setRecordingTime(0);
    setValue('');
  };

  const confirmRecording = () => {
    stopRecording();
    // Here you would handle the recorded data
    setValue(''); // Reset the value for now
  };

  const formatTime = (time) => {
    const minutes = String(Math.floor(time / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="xl" mb={6} color="white">
        Расскажите о компании/продукте:
      </Heading>
      {isRecording ? (
        <Flex
          width="full"
          maxW="md"
          borderRadius="full"
          borderColor="gray.400"
          borderWidth="1px"
          p={3}
          alignItems="center"
          justifyContent="space-between"
          bg="blue.900"
          color="white"
        >
          <IconButton
            aria-label="Cancel"
            icon={<FiX />}
            variant="solid"
            borderRadius="full"
            size="lg"
            onClick={resetRecording}
            colorScheme="blue"
            bg="blue.600"
          />
          <Flex flex="1" mx={3} alignItems="center">
           
            <Box display="flex" alignItems="center" justifyContent="center" flex="1" height="1rem">
              {Array.from({ length: 30 }).map((_, index) => (
                <Box
                  key={index}
                  width="2px"
                  height={`${Math.random() * 100}%`}
                  backgroundColor="blue.400"
                  mx="1px"
                />
              ))}
            </Box>
            <Text ml={3}>{formatTime(recordingTime)}</Text>
          </Flex>
          <IconButton
            aria-label="Confirm"
            icon={<FiCheck />}
            variant="solid"
            borderRadius="full"
            size="lg"
            onClick={confirmRecording}
            colorScheme="blue"
            bg="blue.600"
          />
        </Flex>
      ) : (
        <InputGroup size="lg" width="full" maxW="md" position="relative">
          <Textarea
            ref={textareaRef}
            value={value}
            onChange={handleChange}
            placeholder="Сообщение"
            borderRadius={isMultiLine ? 'md' : 'full'}
            borderColor="gray.400"
            _placeholder={{ color: 'gray.500' }}
            resize="none"
            pr="4rem"
            minH="3rem"
            overflow="hidden"
            lineHeight="1.5"
            rows={1} // Ensures the initial height is that of a single line
          />
          <InputRightElement width="4rem">
            <IconButton
              aria-label="Microphone"
              icon={<FiMic />}
              variant="solid"
              borderRadius="full"
              size="lg"
              backgroundColor="transparent"
              _hover={{ backgroundColor: 'transparent' }}
              _active={{ backgroundColor: 'transparent' }}
              onClick={startRecording}
            />
          </InputRightElement>
        </InputGroup>
      )}
    </Box>
  );
};

export default AboutCompanyTab;*/


/*import React, { useState, useRef, useEffect } from 'react';
import { Box, InputGroup, Textarea, InputRightElement, IconButton, Heading, Flex, Text, Progress } from '@chakra-ui/react';
import { FiMic, FiCheck, FiX } from 'react-icons/fi';

const AboutCompanyTab = () => {
  const [value, setValue] = useState('');
  const [isMultiLine, setIsMultiLine] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const textareaRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

      if (textareaRef.current.scrollHeight > textareaRef.current.clientHeight) {
        setIsMultiLine(true);
      } else {
        setIsMultiLine(false);
      }
    }
  }, [value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const startRecording = () => {
    setIsRecording(true);
    setRecordingTime(0);
    intervalRef.current = setInterval(() => {
      setRecordingTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const stopRecording = () => {
    setIsRecording(false);
    clearInterval(intervalRef.current);
  };

  const resetRecording = () => {
    stopRecording();
    setRecordingTime(0);
    setValue('');
  };

  const confirmRecording = () => {
    stopRecording();
    // Here you would handle the recorded data
    setValue(''); // Reset the value for now
  };

  const formatTime = (time) => {
    const minutes = String(Math.floor(time / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="ml" mb={6}>
        Расскажите о компании/продукте:
      </Heading>
      {isRecording ? (
        <Flex
          width="full"
          maxW="md"
          borderRadius="full"
          borderColor="gray.400"
          borderWidth="1px"
          p={3}
          alignItems="center"
          justifyContent="space-between"
          bg="gray.800"
        >
          <IconButton
            aria-label="Cancel"
            icon={<FiX />}
            variant="solid"
            borderRadius="full"
            size="lg"
            onClick={resetRecording}
            colorScheme="red"
          />
          <Progress
            size="sm"
            value={Math.random() * 100} // Placeholder for random audio levels
            flex="1"
            mx={3}
          />
          <Text color="white" mx={3}>
            {formatTime(recordingTime)}
          </Text>
          <IconButton
            aria-label="Confirm"
            icon={<FiCheck />}
            variant="solid"
            borderRadius="full"
            size="lg"
            onClick={confirmRecording}
            colorScheme="green"
          />
        </Flex>
      ) : (
        <InputGroup size="lg" width="full" maxW="md" position="relative">
          <Textarea
            ref={textareaRef}
            value={value}
            onChange={handleChange}
            placeholder="Сообщение"
            borderRadius={isMultiLine ? 'md' : 'full'}
            borderColor="gray.400"
            _placeholder={{ color: 'gray.500' }}
            resize="none"
            pr="4rem"
            minH="3rem"
            overflow="hidden"
            lineHeight="1.5"
            rows={1} // Ensures the initial height is that of a single line
          />
          <InputRightElement width="4rem">
            <IconButton
              aria-label="Microphone"
              icon={<FiMic />}
              variant="solid"
              borderRadius="full"
              size="lg"
              backgroundColor="transparent"
              _hover={{ backgroundColor: 'transparent' }}
              _active={{ backgroundColor: 'transparent' }}
              onClick={startRecording}
            />
          </InputRightElement>
        </InputGroup>
      )}
    </Box>
  );
};

export default AboutCompanyTab;*/


/*import React, { useState, useRef, useEffect } from 'react';
import { Box, InputGroup, Textarea, InputRightElement, IconButton, Heading } from '@chakra-ui/react';
import { FiMic } from 'react-icons/fi';

const AboutCompanyTab = () => {
  const [value, setValue] = useState('');
  const textareaRef = useRef(null);
  const [isMultiLine, setIsMultiLine] = useState(false);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

      // Проверка, является ли Textarea многострочным
      if (textareaRef.current.scrollHeight > textareaRef.current.clientHeight) {
        setIsMultiLine(true);
      } else {
        setIsMultiLine(false);
      }
    }
  }, [value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="xl" mb={6}>
        Расскажите о компании/продукте:
      </Heading>
      <InputGroup size="lg" width="full" maxW="md" position="relative">
        <Textarea
          ref={textareaRef}
          value={value}
          onChange={handleChange}
          placeholder="Сообщение"
          borderRadius={isMultiLine ? 'md' : 'full'}
          borderColor="gray.400"
          _placeholder={{ color: 'gray.500' }}
          resize="none"
          pr="4rem"
          minH="3rem"
          overflow="hidden"
          lineHeight="1.5"
          rows={1} // Ensures the initial height is that of a single line
        />
        <InputRightElement width="4rem">
          <IconButton
            aria-label="Microphone"
            icon={<FiMic />}
            variant="solid"
            borderRadius="full"
            size="lg"
            backgroundColor="gray.200"
            _hover={{ backgroundColor: 'gray.300' }}
          />
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};

export default AboutCompanyTab;*/



/*import React, { useState, useRef, useEffect } from 'react';
import { Box, InputGroup, Textarea, InputRightElement, IconButton, Heading } from '@chakra-ui/react';
import { FiMic } from 'react-icons/fi';

const AboutCompanyTab = () => {
  const [value, setValue] = useState('');
  const textareaRef = useRef(null);
  const [isMultiLine, setIsMultiLine] = useState(false);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

      // Проверка, является ли Textarea многострочным
      if (textareaRef.current.scrollHeight > textareaRef.current.clientHeight) {
        setIsMultiLine(true);
      } else {
        setIsMultiLine(false);
      }
    }
  }, [value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="xl" mb={6}>
        Расскажите о компании/продукте:
      </Heading>
      <InputGroup size="lg" width="full" maxW="md" position="relative">
        <Textarea
          ref={textareaRef}
          value={value}
          onChange={handleChange}
          placeholder="Сообщение"
          borderRadius={isMultiLine ? 'md' : 'full'}
          borderColor="gray.400"
          _placeholder={{ color: 'gray.500' }}
          resize="none"
          pr="4rem"
          minH="3rem"
          overflow="hidden"
          lineHeight="1.5"
          rows={1} // Ensures the initial height is that of a single line
        />
        <InputRightElement width="4rem">
          <IconButton
            aria-label="Microphone"
            icon={<FiMic />}
            variant="solid"
            borderRadius="full"
            size="lg"
            backgroundColor="gray.200"
            _hover={{ backgroundColor: 'gray.300' }}
          />
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};

export default AboutCompanyTab;*/



/*import React, { useState, useRef, useEffect } from 'react';
import { Box, InputGroup, Textarea, InputRightElement, IconButton, Heading } from '@chakra-ui/react';
import { FiMic } from 'react-icons/fi';

const AboutCompanyTab = () => {
  const [value, setValue] = useState('');
  const textareaRef = useRef(null);
  const [isMultiLine, setIsMultiLine] = useState(false);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

      // Проверка, является ли Textarea многострочным
      if (textareaRef.current.scrollHeight > textareaRef.current.clientHeight) {
        setIsMultiLine(true);
      } else {
        setIsMultiLine(false);
      }
    }
  }, [value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="xl" mb={6}>
        Расскажите о компании/продукте:
      </Heading>
      <InputGroup size="lg" width="full" maxW="md" position="relative">
        <Textarea
          ref={textareaRef}
          value={value}
          onChange={handleChange}
          placeholder="Сообщение"
          borderRadius={isMultiLine ? 'md' : 'full'}
          borderColor="gray.400"
          _placeholder={{ color: 'gray.500' }}
          resize="none"
          pr="3rem"
          minH="3rem"
          overflow="hidden"
          lineHeight="1.5"
          rows={1} // Ensures the initial height is that of a single line
        />
        <InputRightElement width="3rem">
          <IconButton
            aria-label="Microphone"
            icon={<FiMic />}
            variant="ghost"
            pointerEvents="none"
            size="lg"
          />
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};

export default AboutCompanyTab;*/


/*import React, { useState, useRef, useEffect } from 'react';
import { Box, InputGroup, Textarea, InputRightElement, IconButton, Heading } from '@chakra-ui/react';
import { FiMic } from 'react-icons/fi';

const AboutCompanyTab = () => {
  const [value, setValue] = useState('');
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="xl" mb={6}>
        Расскажите о компании/продукте:
      </Heading>
      <InputGroup size="lg" width="full" maxW="md" position="relative">
        <Textarea
          ref={textareaRef}
          value={value}
          onChange={handleChange}
          placeholder="Сообщение"
          borderRadius="full"
          borderColor="gray.400"
          _placeholder={{ color: 'gray.500' }}
          resize="none"
          pr="3rem"
          minH="3rem"
          overflow="hidden"
          lineHeight="1.5"
          rows={1} // Ensures the initial height is that of a single line
        />
        <InputRightElement width="3rem">
          <IconButton
            aria-label="Microphone"
            icon={<FiMic />}
            variant="ghost"
            pointerEvents="none"
            size="lg"
          />
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};

export default AboutCompanyTab;*/





/*import React from 'react';
import { Box, InputGroup, Input, InputRightElement, IconButton, Heading } from '@chakra-ui/react';
import { FiMic } from 'react-icons/fi';

const AboutCompanyTab = () => {
  return (
    <Box mt={5} mb={5} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Heading as="h1" size="ml" mb={6}>
        Расскажите о компании/продукте:
      </Heading>
      <InputGroup size="lg">
        <Input 
          placeholder="Сообщение" 
          borderRadius="full" 
          borderColor="gray.400" 
          _placeholder={{ color: 'gray.500' }}
          resize="none"
        />
        <InputRightElement>
          <IconButton
            aria-label="Microphone"
            icon={<FiMic />}
            variant="ghost"
          />
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};

export default AboutCompanyTab;*/
