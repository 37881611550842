// PasswordRecoveryPage.js
import React, { useState } from 'react';
import { Box, Input, Button, useToast,Text } from '@chakra-ui/react';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const PasswordRecoveryPage = () => {
  const [userEmail, setEmail] = useState('');
  const toast = useToast();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      console.log('Восстановление пароля 1 этап');
      const response = await fetch(`${BACKEND_DOMAIN}/auth/password-recovery`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userEmail }),
      });
      if (response.ok) {
        toast({
          title: 'Success',
          description: 'Please check your email for password reset instructions.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'There was an issue submitting your request.');
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box p={8}>
      <form onSubmit={handleSubmit}>
        <Text color="red.500" fontSize="md" mb="4">Если вы забыли свой пароль, введите email адрес электронной почты, указанный при регистрации, и на него будет отправлено письмо с инструкциями по сбросу пароля.</Text>
        <Text color="yellow.500" fontSize="md" mb="4">Пожалуйста, проверьте папку со спамом.</Text>
        <Input
          type="email"
          placeholder="Enter your email"
          value={userEmail}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Button type="submit" colorScheme="blue" mt={4}>
          Восстановить
        </Button>
      </form>
    </Box>
  );
};

export default PasswordRecoveryPage;
