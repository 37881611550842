import React from 'react';
import { Button } from '@chakra-ui/react';

const TelegramButton = ({ phone, message }) => {
  const handleSendTelegram = () => {
    if (!phone || !message) {
      alert('Введите номер телефона и сообщение');
      return;
    }

    const telegramAppUrl = `tg://msg?to=${phone}&text=${encodeURIComponent(message)}`;
    const telegramWebUrl = `https://t.me/${phone}?text=${encodeURIComponent(message)}`;

    // Попытка открыть приложение Telegram
    window.location.href = telegramAppUrl;

    // Если приложение не откроется, то откроется веб версия через 2 секунды
    setTimeout(() => {
      window.open(telegramWebUrl, '_blank');
    }, 2000);
  };

  return (
    <Button
      backgroundColor="#0088CC"
      onClick={handleSendTelegram}
      size="lg"
      fontSize="lg"
      color="white"
    >
      Отправить в Telegram
    </Button>
  );
};

export default TelegramButton;





/*import React from 'react';
import { Button } from '@chakra-ui/react';

const TelegramButton = () => {
  const handleSendTelegram = () => {
    const username = "novergeme"; // username в Telegram
    const message = "Привет как дела";
    const telegramAppUrl = `tg://resolve?domain=${username}&text=${encodeURIComponent(message)}`;
    const telegramWebUrl = `https://t.me/${username}?text=${encodeURIComponent(message)}`;

    // Попытка открыть приложение Telegram
    window.location.href = telegramAppUrl;

    // Если приложение не откроется, то откроется веб версия через 2 секунды
    setTimeout(() => {
      window.open(telegramWebUrl, '_blank');
    }, 2000);
  };

  return (
    <Button
      backgroundColor="#0088CC"
      onClick={handleSendTelegram}
      size="lg"
      fontSize="lg"
      color="white"
    >
      Отправить в Telegram
    </Button>
  );
};

export default TelegramButton;*/
