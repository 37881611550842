import React, { useState, useContext } from 'react';
import {
  Box, Stack, Heading, Input, Button, Text, Flex, FormControl,
  FormErrorMessage, useToast, AlertDialog, AlertDialogBody,
  AlertDialogFooter, AlertDialogHeader, AlertDialogContent,
  AlertDialogOverlay, useDisclosure, Link as ChakraLink
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext'; 

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

console.log('BACKEND_DOMAIN ' + BACKEND_DOMAIN); 

const RegistrationPage = () => {
  const { setIsAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalContent, setModalContent] = useState({ title: '', body: '' });

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    checkPasswordMatch(e.target.value, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    checkPasswordMatch(password, e.target.value);
  };

  const checkPasswordMatch = (password, confirmPassword) => {
    setIsPasswordMatch(password === confirmPassword || (!password && !confirmPassword));
  };

  const handleSubmit = async () => {
    if (!isEmailValid) {
      toast({
        title: "Ошибка",
        description: "Ваш адрес электронной почты неверен.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!isPasswordMatch) {
      toast({
        title: "Ошибка",
        description: "Пароли не совпадают.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await fetch(`${BACKEND_DOMAIN}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userEmail: email, userPass: password }),
        credentials: 'include'
      });

      const text = await response.text(); // Получаем текстовый ответ сервера для отладки
      console.log('Response text:', text); // Выводим текстовый ответ сервера для отладки

      const data = JSON.parse(text); // Разбираем текст в JSON

      if (response.status === 200) {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        
        setIsAuthenticated(true);
        navigate('/dashboard'); // Перенаправление на страницу /dashboard

      } else {
        toast({
          title: "Error",
          description: data.msg,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }

    } catch (error) {
      console.error("Ошибка во время регистрации:", error);
      toast({
        title: "Ошибка",
        description: "Во время регистрации произошла ошибка. Пожалуйста, попробуйте еще раз.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const openModal = async (title, filePath) => {
    try {
      const response = await fetch(filePath);
      const text = await response.text();
      setModalContent({ title, body: text });
      onOpen();
    } catch (error) {
      console.error("Ошибка загрузки модального контента:", error);
      toast({
        title: "Ощибкаr",
        description: "Не удалось загрузить контент. Повторите попытку..",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex direction="column" h="100vh">
      <Box flex="1" textAlign="center" p="10" pt="100px" bg="gray.800">
        <Stack spacing="6" maxW="md" mx="auto">
          <Heading color="customGreen" mb="2">AirAds.Ai</Heading>
          <Text color="gray.400" fontSize="lg" mb="6">Регистрация</Text>
          <Input
            placeholder="Email"
            variant="filled"
            mb="1"
            value={email}
            onChange={handleEmailChange}
            isInvalid={!isEmailValid && email !== ''}
            errorBorderColor="red.500"
          />
          <Input
            placeholder="Придумайте пароль"
            variant="filled"
            mb="1"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            borderColor={password && confirmPassword ? (isPasswordMatch ? 'green.500' : 'red.500') : null}
          />
          <FormControl isInvalid={!isPasswordMatch && password && confirmPassword}>
            <Input
              placeholder="Повторите пароль"
              variant="filled"
              mb="1"
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              borderColor={password && confirmPassword ? (isPasswordMatch ? 'green.500' : 'red.500') : null}
            />
            <FormErrorMessage>Пароли не совпадают</FormErrorMessage>
          </FormControl>
          <Button colorScheme="yellow" size="lg" mb="2" onClick={handleSubmit}>
            Зарегистрироваться
          </Button>
          <Text>— или —</Text>
          <Button as={Link} to="/login" colorScheme="green" size="lg" mt="2">
            Войти
          </Button>
          <Text fontSize="sm" color="gray.400" mb="2">
             Нажимая кнопку «Зарегистрироваться», вы соглашаетесь с
            <ChakraLink
              ml={1}
              color="blue.300"
              onClick={() => openModal('Условия использования', '/terms.html')}
              style={{ cursor: 'pointer' }}
            >
              Условиями использования
            </ChakraLink>
            {' '}и
            <ChakraLink
              ml={1}
              color="blue.300"
              onClick={() => openModal('Политика конфиденциальности', '/privacy.html')}
              style={{ cursor: 'pointer' }}
            >
              Политикой конфиденциальности
            </ChakraLink>
          </Text>
        </Stack>
      </Box>
      <Box bg="gray.800" p="6">
      </Box>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={undefined}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {modalContent.title}
            </AlertDialogHeader>
            <AlertDialogBody>
              <div dangerouslySetInnerHTML={{ __html: modalContent.body }} />
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onClose}>
                Назад
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};

export default RegistrationPage;









/*import React, { useState, useContext } from 'react';
import {
  Box, Stack, Heading, Input, Button, Text, Flex, FormControl,
  FormErrorMessage, useToast, AlertDialog, AlertDialogBody,
  AlertDialogFooter, AlertDialogHeader, AlertDialogContent,
  AlertDialogOverlay, useDisclosure, Link as ChakraLink
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext'; 

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

console.log('BACKEND_DOMAIN ' + BACKEND_DOMAIN); 

const RegistrationPage = () => {
  const { setIsAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalContent, setModalContent] = useState({ title: '', body: '' });

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    checkPasswordMatch(e.target.value, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    checkPasswordMatch(password, e.target.value);
  };

  const checkPasswordMatch = (password, confirmPassword) => {
    setIsPasswordMatch(password === confirmPassword || (!password && !confirmPassword));
  };

  const handleSubmit = async () => {
    if (!isEmailValid) {
      toast({
        title: "Error",
        description: "Your email is not correct.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!isPasswordMatch) {
      toast({
        title: "Error",
        description: "Passwords do not match.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await fetch(`${BACKEND_DOMAIN}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userEmail: email, userPass: password }),
        credentials: 'include'
      });

      const text = await response.text(); // Получаем текстовый ответ сервера для отладки
      console.log('Response text:', text); // Выводим текстовый ответ сервера для отладки

      const data = JSON.parse(text); // Разбираем текст в JSON

      if (response.status === 200) {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        
        setIsAuthenticated(true);
        navigate('/dashboard'); // Перенаправление на страницу /dashboard

      } else {
        toast({
          title: "Error",
          description: data.msg,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }

    } catch (error) {
      console.error("Error during registration:", error);
      toast({
        title: "Error",
        description: "An error occurred during registration. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const openModal = (title, body) => {
    setModalContent({ title, body });
    onOpen();
  };

  return (
    <Flex direction="column" h="100vh">
      <Box flex="1" textAlign="center" p="10" pt="100px" bg="gray.800">
        <Stack spacing="6" maxW="md" mx="auto">
          <Heading color="customGreen" mb="2">AirAds.Ai</Heading>
          <Text color="gray.400" fontSize="lg" mb="6">Регистрация</Text>
          <Input
            placeholder="Email"
            variant="filled"
            mb="1"
            value={email}
            onChange={handleEmailChange}
            isInvalid={!isEmailValid && email !== ''}
            errorBorderColor="red.500"
          />
          <Input
            placeholder="Придумайте пароль"
            variant="filled"
            mb="1"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            borderColor={password && confirmPassword ? (isPasswordMatch ? 'green.500' : 'red.500') : null}
          />
          <FormControl isInvalid={!isPasswordMatch && password && confirmPassword}>
            <Input
              placeholder="Повторите пароль"
              variant="filled"
              mb="1"
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              borderColor={password && confirmPassword ? (isPasswordMatch ? 'green.500' : 'red.500') : null}
            />
            <FormErrorMessage>Пароли не совпадают</FormErrorMessage>
          </FormControl>
          <Button colorScheme="yellow" size="lg" mb="2" onClick={handleSubmit}>
            Зарегистрироваться
          </Button>
          <Text>— или —</Text>
          <Button as={Link} to="/login" colorScheme="green" size="lg" mt="2">
            Войти
          </Button>
          <Text fontSize="sm" color="gray.400" mb="2">
             Нажимая кнопку «Зарегистрироваться», вы соглашаетесь с
            <ChakraLink
              ml={1}
              color="blue.300"
              onClick={() => openModal('Terms of Use', 'Some text for terms of use.')}
              style={{ cursor: 'pointer' }}
            >
              Условиями использования
            </ChakraLink>
            {' '}и
            <ChakraLink
              ml={1}
              color="blue.300"
              onClick={() => openModal('Privacy Policy', 'Some text for privacy policy.')}
              style={{ cursor: 'pointer' }}
            >
              Политикой конфиденциальности
            </ChakraLink>
          </Text>
        </Stack>
      </Box>
      <Box bg="gray.800" p="6">
       {*//* <Text textAlign="center" color="gray.400">
          <ChakraLink
            onClick={() => openModal('Privacy Policy', 'Some text for privacy policy.')}
            style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}
          >
            Privacy Policy
          </ChakraLink>
          <span> • </span>
          <ChakraLink
            onClick={() => openModal('Terms of Use', 'Some text for terms of use.')}
            style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}
          >
            Terms of Use
          </ChakraLink>
          </Text> *//*}
      </Box>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={undefined}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {modalContent.title}
            </AlertDialogHeader>
            <AlertDialogBody>
              {modalContent.body}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onClose}>
                Назад
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};

export default RegistrationPage;*/





/*import React, { useState } from 'react';
import {
  Box, Stack, Heading, Input, Button, Text, Flex, FormControl,
  FormErrorMessage, useToast, AlertDialog, AlertDialogBody,
  AlertDialogFooter, AlertDialogHeader, AlertDialogContent,
  AlertDialogOverlay, useDisclosure, Link as ChakraLink
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const RegistrationPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalContent, setModalContent] = useState({ title: '', body: '' });

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    checkPasswordMatch(e.target.value, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    checkPasswordMatch(password, e.target.value);
  };

  const checkPasswordMatch = (password, confirmPassword) => {
    setIsPasswordMatch(password === confirmPassword || (!password && !confirmPassword));
  };

  const handleSubmit = async () => {
    if (!isEmailValid) {
      toast({
        title: "Error",
        description: "Your email is not correct.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!isPasswordMatch) {
      toast({
        title: "Error",
        description: "Passwords do not match.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await fetch('https://server.postbackrouter.com/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userEmail: email, userPass: password })
      });

      const data = await response.json();

      if (response.status === 200) {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        // You may navigate to login or perform other actions
      } else {
        toast({
          title: "Error",
          description: data.msg,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }

    } catch (error) {
      console.error("Error during registration:", error);
      toast({
        title: "Error",
        description: "An error occurred during registration. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const openModal = (title, body) => {
    setModalContent({ title, body });
    onOpen();
  };

  return (
    <Flex direction="column" h="100vh">
      <Box flex="1" textAlign="center" p="10" pt="100px" bg="gray.800">
        <Stack spacing="6" maxW="md" mx="auto">
          <Heading color="customGreen" mb="2">PostBackRouter</Heading>
          <Text color="gray.400" fontSize="lg" mb="6">Registration</Text>
          <Input
            placeholder="Email"
            variant="filled"
            mb="1"
            value={email}
            onChange={handleEmailChange}
            isInvalid={!isEmailValid && email !== ''}
            errorBorderColor="red.500"
          />
          <Input
            placeholder="Password"
            variant="filled"
            mb="1"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            borderColor={password && confirmPassword ? (isPasswordMatch ? 'green.500' : 'red.500') : null}
          />
          <FormControl isInvalid={!isPasswordMatch && password && confirmPassword}>
            <Input
              placeholder="Repeat your password"
              variant="filled"
              mb="1"
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              borderColor={password && confirmPassword ? (isPasswordMatch ? 'green.500' : 'red.500') : null}
            />
            <FormErrorMessage>Password doesn't match</FormErrorMessage>
          </FormControl>
          <Button colorScheme="yellow" size="lg" mb="2" onClick={handleSubmit}>
            Register
          </Button>
          <Text>— OR —</Text>
          <Button as={Link} to="/login" colorScheme="green" size="lg" mt="2">
            Log in
          </Button>
          <Text fontSize="sm" color="gray.400" mb="2">
            By clicking the "Register" button, you agree to our
            <ChakraLink
              ml={1}
              color="blue.300"
              onClick={() => openModal('Terms of Use', 'Some text for terms of use.')}
              style={{ cursor: 'pointer' }}
            >
              Terms of Use
            </ChakraLink>
            {' '}and
            <ChakraLink
              ml={1}
              color="blue.300"
              onClick={() => openModal('Privacy Policy', 'Some text for privacy policy.')}
              style={{ cursor: 'pointer' }}
            >
              Privacy Policy
            </ChakraLink>
          </Text>
        </Stack>
      </Box>
      <Box bg="gray.800" p="6">
       {/* <Text textAlign="center" color="gray.400">
          <ChakraLink
            onClick={() => openModal('Privacy Policy', 'Some text for privacy policy.')}
            style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}
          >
            Privacy Policy
          </ChakraLink>
          <span> • </span>
          <ChakraLink
            onClick={() => openModal('Terms of Use', 'Some text for terms of use.')}
            style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}
          >
            Terms of Use
          </ChakraLink>
          </Text> *//*}
      </Box>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={undefined}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {modalContent.title}
            </AlertDialogHeader>
            <AlertDialogBody>
              {modalContent.body}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onClose}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};

export default RegistrationPage; */






/*import React, { useState } from 'react';
import {
  Box,
  Stack,
  Heading,
  Input,
  Button,
  Text,
  Flex,
  FormControl,
  FormErrorMessage,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const RegistrationPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalContent, setModalContent] = useState({ title: '', body: '' });

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsEmailValid(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    checkPasswordMatch(e.target.value, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    checkPasswordMatch(password, e.target.value);
  };

  const checkPasswordMatch = (password, confirmPassword) => {
    setIsPasswordMatch(password === confirmPassword);
  };

  const handleSubmit = async () => {
    if (!isPasswordMatch || !isEmailValid) {
      toast({
        title: "Error",
        description: isEmailValid ? "Passwords do not match." : "Email is not valid.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await fetch('https://server.postbackrouter.com/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userEmail: email, userPass: password })
      });

      const data = await response.json();

      if (response.status === 200) {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        // Optionally: Redirect to login page or other actions
      } else {
        toast({
          title: "Error",
          description: data.msg,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }

    } catch (error) {
      console.error("Error during registration:", error);
      toast({
        title: "Error",
        description: "An error occurred during registration. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

  };

  const openModal = (title, body) => {
    setModalContent({ title, body });
    onOpen();
  };

  return (
    <Flex direction="column" h="100vh">
      <Box flex="1" textAlign="center" p="10" pt="100px" bg="gray.800">
        <Stack spacing="6" maxW="md" mx="auto">
          <Heading color="customGreen" mb="2">PostBackRouter</Heading>
          <Text color="gray.400" fontSize="lg" mb="6">Registration</Text>
          <FormControl isInvalid={!isEmailValid}>
            <Input placeholder="Email" variant="filled" mb="1" onChange={handleEmailChange} isInvalid={!isEmailValid} errorBorderColor="red.500" />
            <FormErrorMessage>Email is not valid</FormErrorMessage>
          </FormControl>
          <Input placeholder="Password" variant="filled" mb="1" type="password" value={password} onChange={handlePasswordChange} borderColor={password && confirmPassword ? (isPasswordMatch ? 'green.500' : 'red.500') : null} />
          <FormControl isInvalid={!isPasswordMatch && password && confirmPassword}>
            <Input placeholder="Repeat your password" variant="filled" mb="1" type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} borderColor={password && confirmPassword ? (isPasswordMatch ? 'green.500' : 'red.500') : null} />
            <FormErrorMessage>Password doesn't match</FormErrorMessage>
          </FormControl>
          <Button colorScheme="yellow" size="lg" mb="2" onClick={handleSubmit}>
            Register
          </Button>
          <Text fontSize="sm" color="gray.400" mb="2">
            By clicking the "Register" button, you agree to our 
            <Button variant="link" color="gray.400" onClick={() => openModal('Terms of Use', 'Some text for Terms of Use')}>
              Terms of Use
            </Button> 
            and 
            <Button variant="link" color="gray.400" onClick={() => openModal('Privacy Policy', 'Some text for Privacy Policy')}>
              Privacy Policy
            </Button>
          </Text>
          <Button as={Link} to="/login" colorScheme="green" size="lg">
            Log in
          </Button>
        </Stack>
      </Box>
      <Box bg="gray.800" p="6">
        <Text textAlign="center" color="gray.400">
          <Button variant="link" color="gray.400" onClick={() => openModal('Privacy Policy', 'Some text for Privacy Policy')}>
            Privacy Policy
          </Button>
          <span> • </span>
          <Button variant="link" color="gray.400" onClick={() => openModal('Terms of Use', 'Some text for Terms of Use')}>
            Terms of Use
          </Button>
        </Text>
      </Box>
      <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {modalContent.title}
            </AlertDialogHeader>
            <AlertDialogBody>
              {modalContent.body}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onClose}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};

export default RegistrationPage; */


/*import React, { useState } from 'react';
import {
  Box,
  Stack,
  Heading,
  Input,
  Button,
  Text,
  Flex,
  FormControl,
  FormErrorMessage,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const RegistrationPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalContent, setModalContent] = useState({ title: '', body: '' });

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsEmailValid(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    checkPasswordMatch(e.target.value, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    checkPasswordMatch(password, e.target.value);
  };

  const checkPasswordMatch = (password, confirmPassword) => {
    setIsPasswordMatch(password === confirmPassword);
  };

  const handleSubmit = async () => {
    if (!isPasswordMatch || !isEmailValid) {
      toast({
        title: "Error",
        description: isEmailValid ? "Passwords do not match." : "Email is not valid.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await fetch('https://server.postbackrouter.com/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userEmail: email, userPass: password })
      });

      const data = await response.json();

      if (response.status === 200) {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        // Optionally: Redirect to login page or other actions
      } else {
        toast({
          title: "Error",
          description: data.msg,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }

    } catch (error) {
      console.error("Error during registration:", error);
      toast({
        title: "Error",
        description: "An error occurred during registration. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const openModal = (title, body) => {
    setModalContent({ title, body });
    onOpen();
  };

  return (
    <Flex direction="column" h="100vh">
      <Box flex="1" textAlign="center" p="10" pt="100px" bg="gray.800">
        <Stack spacing="6" maxW="md" mx="auto">
          <Heading color="customGreen" mb="2">PostBackRouter</Heading>
          <Text color="gray.400" fontSize="lg" mb="6">Registration</Text>
          <FormControl isInvalid={!isEmailValid}>
            <Input placeholder="Email" variant="filled" mb="1" onChange={handleEmailChange} isInvalid={!isEmailValid} errorBorderColor="red.500" />
            <FormErrorMessage>Email is not valid</FormErrorMessage>
          </FormControl>
          <Input placeholder="Password" variant="filled" mb="1" type="password" value={password} onChange={handlePasswordChange} borderColor={password && confirmPassword ? (isPasswordMatch ? 'green.500' : 'red.500') : null} />
          <FormControl isInvalid={!isPasswordMatch && password && confirmPassword}>
            <Input placeholder="Repeat your password" variant="filled" mb="1" type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} borderColor={password && confirmPassword ? (isPasswordMatch ? 'green.500' : 'red.500') : null} />
            <FormErrorMessage>Password doesn't match</FormErrorMessage>
          </FormControl>
          <Button colorScheme="yellow" size="lg" mb="2" onClick={handleSubmit}>
            Register
          </Button>
          <Text fontSize="sm" mb="2">
            By clicking the "Register" button, you agree to our 
            <Button variant="link" color="blue.500" onClick={() => openModal('Terms of Use', 'Some text for Terms of Use')}>
              Terms of Use
            </Button> 
            and 
            <Button variant="link" color="blue.500" onClick={() => openModal('Privacy Policy', 'Some text for Privacy Policy')}>
              Privacy Policy
            </Button>
          </Text>
          <Button as={Link} to="/login" colorScheme="green" size="lg">
            Log in
          </Button>
        </Stack>
      </Box>
      <Box bg="gray.800" p="6">
        <Text textAlign="center" color="gray.400">
          <Button variant="link" color="gray.400" onClick={() => openModal('Privacy Policy', 'Some text for Privacy Policy')}>
            Privacy Policy
          </Button>
          <span> • </span>
          <Button variant="link" color="gray.400" onClick={() => openModal('Terms of Use', 'Some text for Terms of Use')}>
            Terms of Use
          </Button>
        </Text>
      </Box>
      <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {modalContent.title}
            </AlertDialogHeader>
            <AlertDialogBody>
              {modalContent.body}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onClose}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};

export default RegistrationPage;*/


/*import React, { useState } from 'react';
import {
  Box,
  Stack,
  Heading,
  Input,
  Button,
  Text,
  Flex,
  FormControl,
  FormErrorMessage,
  useToast
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const RegistrationPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const toast = useToast();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsEmailValid(validateEmail(e.target.value));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    checkPasswordMatch(e.target.value, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    checkPasswordMatch(password, e.target.value);
  };

  const checkPasswordMatch = (password, confirmPassword) => {
    setIsPasswordMatch(password === confirmPassword || (!password && !confirmPassword));
  };

  const handleSubmit = async () => {
    if (!isPasswordMatch || !isEmailValid) {
      toast({
        title: "Error",
        description: !isEmailValid ? "Your email is not correct." : "Passwords do not match.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await fetch('https://server.postbackrouter.com/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userEmail: email, userPass: password })
      });

      const data = await response.json();

      if (response.status === 200) {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: data.msg,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }

    } catch (error) {
      console.error("Error during registration:", error);
      toast({
        title: "Error",
        description: "An error occurred during registration. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex direction="column" h="100vh">
      <Box flex="1" textAlign="center" p="10" pt="100px" bg="gray.800">
        <Stack spacing="6" maxW="md" mx="auto">
          <Heading color="customGreen" mb="2">PostBackRouter</Heading>
          <Text color="gray.400" fontSize="lg" mb="6">Registration</Text>
          <Input 
            placeholder="Email" 
            variant="filled" 
            mb="1" 
            value={email} 
            onChange={handleEmailChange} 
            isInvalid={!isEmailValid}
          />
          <Input 
            placeholder="Password" 
            variant="filled" 
            mb="1" 
            type="password" 
            value={password} 
            onChange={handlePasswordChange} 
            borderColor={password && confirmPassword ? (isPasswordMatch ? 'green.500' : 'red.500') : null} 
          />
          <FormControl isInvalid={!isPasswordMatch && password && confirmPassword}>
            <Input 
              placeholder="Repeat your password" 
              variant="filled" 
              mb="1" 
              type="password" 
              value={confirmPassword} 
              onChange={handleConfirmPasswordChange} 
              borderColor={password && confirmPassword ? (isPasswordMatch ? 'green.500' : 'red.500') : null} 
            />
            <FormErrorMessage>Password doesn't match</FormErrorMessage>
          </FormControl>
          <Button colorScheme="yellow" size="lg" mb="2" onClick={handleSubmit}>
            Register
          </Button>
          <Box textAlign="center">
            <Text>— OR —</Text>
          </Box>
          <Button as={Link} to="/login" colorScheme="green" size="lg" mt="2">
            Log in
          </Button>
        </Stack>
      </Box>
      <Box bg="gray.800" p="6">
        <Text textAlign="center" color="gray.400">
          <a href="#" style={{ textDecoration: 'none', color: 'inherit' }}>
            Privacy Policy
          </a>
        </Text>
      </Box>
    </Flex>
  );
};

export default RegistrationPage; */