import React from 'react';
import { Button } from '@chakra-ui/react';

const VkButton = ({ nickname, message }) => {
  const handleSendVk = () => {
    if (!nickname || !message) {
      alert('Введите никнейм и сообщение');
      return;
    }

    // Создание временного input элемента для копирования текста
    const tempInput = document.createElement('input');
    tempInput.value = message;
    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // Для мобильных устройств

    // Выполнение команды копирования
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    //alert('Сообщение скопировано в буфер обмена.');

    const vkAppUrl = `vk://vk.com/${nickname}`;
    const vkWebUrl = `https://vk.com/${nickname}`;

    // Попытка открыть приложение VK
    window.location.href = vkAppUrl;

    // Если приложение не откроется, то откроется веб версия через 2 секунды
    setTimeout(() => {
      window.open(vkWebUrl, '_blank');
    }, 2000);
  };

  return (
    <Button
      backgroundColor="#4680C2"
      onClick={handleSendVk}
      size="lg"
      fontSize="lg"
      color="white"
    >
      Отправить в VK
    </Button>
  );
};

export default VkButton;





/*import React from 'react';
import { Button } from '@chakra-ui/react';

const VkButton = ({ nickname, message }) => {
  const handleSendVk = () => {
    if (!nickname || !message) {
      alert('Введите никнейм и сообщение');
      return;
    }

    const vkAppUrl = `vk://vk.com/write${nickname}`;
    const vkWebUrl = `https://vk.com/im?sel=${nickname}&msg=${encodeURIComponent(message)}`;

    // Попытка открыть приложение VK
    window.location.href = vkAppUrl;

    // Если приложение не откроется, то откроется веб версия через 2 секунды
    setTimeout(() => {
      window.open(vkWebUrl, '_blank');
    }, 2000);
  };

  return (
    <Button
      backgroundColor="#4680C2"
      onClick={handleSendVk}
      size="lg"
      fontSize="lg"
      color="white"
    >
      Отправить в VK
    </Button>
  );
};

export default VkButton;*/





/*import React from 'react';
import { Button } from '@chakra-ui/react';

const VkButton = () => {
  const handleSendVk = () => {
    const userId = "136629719"; // ID пользователя VK
    const message = "Привет как дела";
    const vkAppUrl = `vk://vk.com/write${userId}`;
    const vkWebUrl = `https://vk.com/im?sel=${userId}&msg=${encodeURIComponent(message)}`;

    // Попытка открыть приложение VK
    window.location.href = vkAppUrl;

    // Если приложение не откроется, то откроется веб версия через 2 секунды
    setTimeout(() => {
      window.open(vkWebUrl, '_blank');
    }, 2000);
  };

  return (
    <Button
      backgroundColor="#4680C2"
      onClick={handleSendVk}
      size="lg"
      fontSize="lg"
      color="white"
    >
      Отправить в VK
    </Button>
  );
};

export default VkButton;*/
