import React from 'react';
import { Button } from '@chakra-ui/react';

const InstagramButton = ({ nickname, message }) => {
  const handleSendInstagram = () => {
    if (!nickname || !message) {
      alert('Введите никнейм и сообщение');
      return;
    }

    // Создание временного input элемента для копирования текста
    const tempInput = document.createElement('input');
    tempInput.value = message;
    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // Для мобильных устройств

    // Выполнение команды копирования
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    //alert('Сообщение скопировано в буфер обмена.');

    const instagramAppUrl = `instagram://user?username=${nickname}`;
    const instagramWebUrl = `https://www.instagram.com/${nickname}/`;

    // Попытка открыть приложение Instagram
    window.location.href = instagramAppUrl;

    // Если приложение не откроется, то откроется веб версия через 2 секунды
    setTimeout(() => {
      window.open(instagramWebUrl, '_blank');
    }, 2000);
  };

  return (
    <Button
      backgroundColor="#E1306C"
      onClick={handleSendInstagram}
      size="lg"
      fontSize="lg"
      color="white"
    >
      Отправить в *Instagram
    </Button>
  );
};

export default InstagramButton;




/*import React from 'react';
import { Button } from '@chakra-ui/react';

const InstagramButton = () => {
  const handleSendInstagram = () => {
    const username = "pashkov.films"; // username в Instagram
    const message = "Привет как дела";

    // Создание временного input элемента для копирования текста
    const tempInput = document.createElement('input');
    tempInput.value = message;
    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // Для мобильных устройств

    // Выполнение команды копирования
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    //alert('Сообщение скопировано в буфер обмена.');

    const instagramAppUrl = `instagram://user?username=${username}`;
    const instagramWebUrl = `https://www.instagram.com/${username}/`;

    // Попытка открыть приложение Instagram
    window.location.href = instagramAppUrl;

    // Если приложение не откроется, то откроется веб версия через 2 секунды
    setTimeout(() => {
      window.open(instagramWebUrl, '_blank');
    }, 2000);
  };

  return (
    <Button
      backgroundColor="#E1306C"
      onClick={handleSendInstagram}
      size="lg"
      fontSize="lg"
      color="white"
    >
      Отправить в *Instagram
    </Button>
  );
};

export default InstagramButton;*/
