import React from 'react';
import { Button } from '@chakra-ui/react';

const SmsButton = ({ phone, message }) => {
  const handleSendSms = () => {
    if (!phone || !message) {
      alert('Введите номер телефона и сообщение');
      return;
    }

    let smsUrl = '';

    // Определение типа устройства
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      // Для iPhone
      smsUrl = `sms:${phone}&body=${encodeURIComponent(message)}`;
    } else if (/Android/i.test(navigator.userAgent)) {
      // Для Android
      smsUrl = `sms:${phone}?body=${encodeURIComponent(message)}`;
    } else {
      alert('Ваше устройство не поддерживается для отправки SMS');
      return;
    }

    // Открытие приложения для отправки SMS
    window.open(smsUrl, '_blank');
  };

  return (
    <Button
      backgroundColor="#32CD32"
      onClick={handleSendSms}
      size="lg"
      fontSize="lg"
      color="white"
    >
      Отправить в SMS
    </Button>
  );
};

export default SmsButton;



/*import React from 'react';
import { Button } from '@chakra-ui/react';

const SmsButton = () => {
  const handleSendSms = () => {
    const phoneNumber = "+79287759977";
    const message = "Привет как дела";
    let smsUrl = '';

    // Определение типа устройства
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      // Для iPhone
      smsUrl = `sms:${phoneNumber}&body=${encodeURIComponent(message)}`;
    } else if (/Android/i.test(navigator.userAgent)) {
      // Для Android
      smsUrl = `sms:${phoneNumber}?body=${encodeURIComponent(message)}`;
    } else {
      alert('Ваше устройство не поддерживается для отправки SMS');
      return;
    }

    // Открытие приложения для отправки SMS
    window.open(smsUrl, '_blank');
  };

  return (
    <Button
      backgroundColor="#32CD32"
      onClick={handleSendSms}
      size="lg"
      fontSize="lg"
      color="white"
    >
      Отправить в SMS
    </Button>
  );
};

export default SmsButton;*/
