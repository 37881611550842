import React, { useState } from 'react';
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalHeader,
  useDisclosure,
  Link,
  VStack
} from '@chakra-ui/react';
import {
    WarningIcon,
    ViewIcon,
    DragHandleIcon,
    CopyIcon,
    UnlockIcon
} from '@chakra-ui/icons';
import { FaCoins } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; 
import ProfileUser from './ProfileUser';
import TransactionsPage from '../pages/TransactionsPage';

const Header = () => {
    const { logOut } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isInstructionOpen, onOpen: onInstructionOpen, onClose: onInstructionClose } = useDisclosure();
    const { isOpen: isDonateOpen, onOpen: onDonateOpen, onClose: onDonateClose } = useDisclosure();
    const [modalContent, setModalContent] = useState(null);

    const handleLogout = () => {
        logOut();
    };

    const handleMenuItemClick = (content) => {
        setModalContent(content);
        onOpen();
    };

    return (
        <Box 
            bg="gray.800"
            p={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            position="relative"
            h="60px"
            mt={5}
            px={{ base: 2, md: 10 }}
            mb={10}
        >
            <Box display="flex" alignItems="flex-start" flexDir={{ base: 'column', sm: 'row' }} textAlign={{ base: 'left', sm: 'left' }}>
                <Heading 
                    as="h1" 
                    size="md" 
                    color="customGreen"
                    fontSize="18px"
                >
                    AirAds.Ai
                </Heading>
                <Button 
                    variant="register"
                    leftIcon={<WarningIcon />} 
                    onClick={onInstructionOpen}
                    size="xs"
                    ml={{ base: 0, sm: 10 }}
                    mt={{ base: 2, sm: 0 }}
                >
                    Инструкция
                </Button>
            </Box>

            <Box display="flex" alignItems="center">
                <Button 
                    leftIcon={<FaCoins color="red.500" />} 
                    variant="ghost"
                    color="red.500"
                    onClick={onDonateOpen}
                    size="xs"
                    mr={4}
                >
                    Донат
                </Button>

                <Menu>
                    <MenuButton 
                        as={IconButton}
                        isRound={true}
                        variant='solid'
                        colorScheme='green'
                        aria-label='Profile Menu'
                        fontSize='20px'
                        icon={<ViewIcon />}
                    />
                    <MenuList color="green.400">
                        <MenuItem icon={<DragHandleIcon />} onClick={() => handleMenuItemClick(<ProfileUser />)}>Профиль</MenuItem>
                        {/*<MenuItem icon={<CopyIcon />} onClick={() => handleMenuItemClick(<TransactionsPage />)}>Оплаты</MenuItem>*/}
                        <MenuItem icon={<UnlockIcon />} onClick={handleLogout}>Выход</MenuItem>
                    </MenuList>
                </Menu>
            </Box>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        {modalContent}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Назад</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isInstructionOpen} onClose={onInstructionClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Инструкция</ModalHeader>
                    <ModalBody>
                        <Box as="iframe" 
                             src="https://www.youtube.com/embed/QpPd1lqY5bc?si=RbyfBRiLY73yWH72" 
                             width="100%" 
                             height="315" 
                             frameBorder="0" 
                             allowFullScreen
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onInstructionClose}>Закрыть</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isDonateOpen} onClose={onDonateClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Задонать на развитие проекта</ModalHeader>
                    <ModalBody>
                        <VStack spacing={4}>
                            <Button 
                                as={Link} 
                                href="https://yoomoney.ru/to/41001761587111" 
                                colorScheme="blue" 
                                width="100%"
                                target="_blank"
                            >
                                Отправить в рублях ₽
                            </Button>
                            <Button 
                                as={Link} 
                                href="https://paypal.me/noverlife" 
                                colorScheme="green" 
                                width="100%"
                                target="_blank"
                            >
                                Отправить в долларах $
                            </Button>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onDonateClose}>Закрыть</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default Header;




/*import React, { useState } from 'react';
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalHeader,
  useDisclosure,
  Link,
  VStack
} from '@chakra-ui/react';
import {
    WarningIcon,
    ViewIcon,
    DragHandleIcon,
    CopyIcon,
    UnlockIcon
} from '@chakra-ui/icons';
import { FaCoins } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; 
import ProfileUser from './ProfileUser';
import TransactionsPage from '../pages/TransactionsPage';

const Header = () => {
    const { logOut } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isInstructionOpen, onOpen: onInstructionOpen, onClose: onInstructionClose } = useDisclosure();
    const { isOpen: isDonateOpen, onOpen: onDonateOpen, onClose: onDonateClose } = useDisclosure();
    const [modalContent, setModalContent] = useState(null);

    const handleLogout = () => {
        logOut();
    };

    const handleMenuItemClick = (content) => {
        setModalContent(content);
        onOpen();
    };

    return (
        <Box 
            bg="gray.800"
            p={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            position="relative"
            h="60px"
            mt={5}
            px={{ base: 2, md: 10 }}
            mb={10}
        >
            <Box display="flex" alignItems="flex-start" flexDir={{ base: 'column', sm: 'row' }} textAlign={{ base: 'left', sm: 'left' }}>
                <Heading 
                    as="h1" 
                    size="md" 
                    color="customGreen"
                    fontSize="18px"
                >
                    AirAds.Ai
                </Heading>
                <Button 
                    variant="register"
                    leftIcon={<WarningIcon />} 
                    onClick={onInstructionOpen}
                    size="xs"
                    ml={{ base: 0, sm: 10 }}
                    mt={{ base: 2, sm: 0 }}
                >
                    Инструкция
                </Button>
            </Box>

            <Box display="flex" alignItems="center">
                <Button 
                    leftIcon={<FaCoins color="red.500" />} 
                    variant="ghost"
                    color="red.500"
                    onClick={onDonateOpen}
                    size="xs"
                    mr={4}
                >
                    Донат
                </Button>

                <Menu>
                    <MenuButton 
                        as={IconButton}
                        isRound={true}
                        variant='solid'
                        colorScheme='green'
                        aria-label='Profile Menu'
                        fontSize='20px'
                        icon={<ViewIcon />}
                    />
                    <MenuList color="green.400">
                        <MenuItem icon={<DragHandleIcon />} onClick={() => handleMenuItemClick(<ProfileUser />)}>Профиль</MenuItem>
                        <MenuItem icon={<CopyIcon />} onClick={() => handleMenuItemClick(<TransactionsPage />)}>Оплаты</MenuItem>
                        <MenuItem icon={<UnlockIcon />} onClick={handleLogout}>Выход</MenuItem>
                    </MenuList>
                </Menu>
            </Box>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        {modalContent}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Назад</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isInstructionOpen} onClose={onInstructionClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Инструкция</ModalHeader>
                    <ModalBody>
                        <Box as="iframe" 
                             src="https://www.youtube.com/embed/a8sM59-8DFw" 
                             width="100%" 
                             height="315" 
                             frameBorder="0" 
                             allowFullScreen
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onInstructionClose}>Закрыть</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isDonateOpen} onClose={onDonateClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Донат</ModalHeader>
                    <ModalBody>
                        <VStack spacing={4}>
                            <Button as={Link} href="http://test.com" colorScheme="blue" width="100%">
                                Задонатить в рублях
                            </Button>
                            <Button as={Link} href="http://test.com" colorScheme="green" width="100%">
                                Задонатить в долларах
                            </Button>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onDonateClose}>Закрыть</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default Header;*/



/*import React, { useState } from 'react';
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalHeader,
  useDisclosure,
  Link,
  VStack
} from '@chakra-ui/react';
import {
    WarningIcon,
    ViewIcon,
    DragHandleIcon,
    CopyIcon,
    UnlockIcon,
    InfoIcon
} from '@chakra-ui/icons';
import { FaCoins } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; 
import ProfileUser from './ProfileUser';
import TransactionsPage from '../pages/TransactionsPage';

const Header = () => {
    const { logOut } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isInstructionOpen, onOpen: onInstructionOpen, onClose: onInstructionClose } = useDisclosure();
    const { isOpen: isDonateOpen, onOpen: onDonateOpen, onClose: onDonateClose } = useDisclosure();
    const [modalContent, setModalContent] = useState(null);

    const handleLogout = () => {
        logOut();
    };

    const handleMenuItemClick = (content) => {
        setModalContent(content);
        onOpen();
    };

    return (
        <Box 
            bg="gray.800"
            p={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            position="relative"
            h="60px"
            mt={5}
            px={{ base: 2, md: 10 }}
            mb={10}
        >
            <Box display="flex" alignItems="flex-start" flexDir={{ base: 'column', sm: 'row' }} textAlign={{ base: 'left', sm: 'left' }}>
                <Heading 
                    as="h1" 
                    size="md" 
                    color="customGreen"
                    fontSize="18px"
                >
                    AirAds.Ai
                </Heading>
                <Button 
                    variant="register"
                    leftIcon={<WarningIcon />} 
                    onClick={onInstructionOpen}
                    size="xs"
                    ml={{ base: 0, sm: 10 }}
                    mt={{ base: 2, sm: 0 }}
                >
                    Инструкция
                </Button>
            </Box>

            <Box display="flex" alignItems="center">
                <Button 
                    leftIcon={<FaCoins />} 
                    variant="solid" 
                    colorScheme="yellow" 
                    onClick={onDonateOpen}
                    size="sm"
                    mr={4}
                >
                    Донат
                </Button>

                <Menu>
                    <MenuButton 
                        as={IconButton}
                        isRound={true}
                        variant='solid'
                        colorScheme='green'
                        aria-label='Profile Menu'
                        fontSize='20px'
                        icon={<ViewIcon />}
                    />
                    <MenuList color="green.400">
                        <MenuItem icon={<DragHandleIcon />} onClick={() => handleMenuItemClick(<ProfileUser />)}>Профиль</MenuItem>
                        <MenuItem icon={<CopyIcon />} onClick={() => handleMenuItemClick(<TransactionsPage />)}>Оплаты</MenuItem>
                        <MenuItem icon={<UnlockIcon />} onClick={handleLogout}>Выход</MenuItem>
                    </MenuList>
                </Menu>
            </Box>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        {modalContent}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Назад</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isInstructionOpen} onClose={onInstructionClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Инструкция</ModalHeader>
                    <ModalBody>
                        <Box as="iframe" 
                             src="https://www.youtube.com/embed/a8sM59-8DFw" 
                             width="100%" 
                             height="315" 
                             frameBorder="0" 
                             allowFullScreen
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onInstructionClose}>Закрыть</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isDonateOpen} onClose={onDonateClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Донат</ModalHeader>
                    <ModalBody>
                        <VStack spacing={4}>
                            <Button as={Link} href="http://test.com" colorScheme="blue" width="100%">
                                Задонатить в рублях
                            </Button>
                            <Button as={Link} href="http://test.com" colorScheme="green" width="100%">
                                Задонатить в долларах
                            </Button>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onDonateClose}>Закрыть</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default Header;*/




//мобильная версия с инструкцией
/*import React, { useState } from 'react';
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalHeader,
  useDisclosure
} from '@chakra-ui/react';
import {
    WarningIcon,
    ViewIcon,
    DragHandleIcon,
    CopyIcon,
    UnlockIcon
} from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; 
import ProfileUser from './ProfileUser';
import TransactionsPage from '../pages/TransactionsPage';

const Header = () => {
    const [value, setValue] = useState(0);
    const { logOut } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isInstructionOpen, onOpen: onInstructionOpen, onClose: onInstructionClose } = useDisclosure();
    const [modalContent, setModalContent] = useState(null);

    const handleLogout = () => {
        logOut();
    };

    const openProfileModal = () => {
        onOpen();
    };

    const handleMenuItemClick = (content) => {
        setModalContent(content);
        onOpen();
    };

    const balanceColor = value > 100 
        ? "white"
        : (value > 30 && value < 100) 
            ? "orange.400"
            : "red.500";

    return (
        <Box 
            bg="gray.800"
            p={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            position="relative"
            h="60px"
            mt={5}
            px={{ base: 2, md: 10 }}
            mb={10}
        >
            <Box display="flex" alignItems="flex-start" flexDir={{ base: 'column', sm: 'row' }} textAlign={{ base: 'left', sm: 'left' }}>
                <Heading 
                    as="h1" 
                    size="md" 
                    color="customGreen"
                    fontSize="18px"
                >
                    AirAds.Ai
                </Heading>
                <Button 
                    variant="register"
                    leftIcon={<WarningIcon />} 
                    onClick={onInstructionOpen}
                    size="xs"
                    ml={{ base: 0, sm: 10 }}
                    mt={{ base: 2, sm: 0 }}
                >
                    Инструкция
                </Button>
            </Box>

            <Box display="flex" alignItems="center">
                <Text 
                    color={balanceColor} 
                    mr={4} 
                    fontSize="16px" 
                    fontWeight="bold"
                    pr={4}
                >
                    ₽ {value}
                </Text>

                <Menu>
                    <MenuButton 
                        as={IconButton}
                        isRound={true}
                        variant='solid'
                        colorScheme='green'
                        aria-label='Profile Menu'
                        fontSize='20px'
                        icon={<ViewIcon />}
                    />
                    <MenuList color="green.400">
                        <MenuItem icon={<DragHandleIcon />} onClick={() => handleMenuItemClick(<ProfileUser />)}>Профиль</MenuItem>
                        <MenuItem icon={<CopyIcon />} onClick={() => handleMenuItemClick(<TransactionsPage />)}>Оплаты</MenuItem>
                        <MenuItem icon={<UnlockIcon />} onClick={handleLogout}>Выход</MenuItem>
                    </MenuList>
                </Menu>
            </Box>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        {modalContent}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Назад</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isInstructionOpen} onClose={onInstructionClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Инструкция</ModalHeader>
                    <ModalBody>
                        <Box as="iframe" 
                             src="https://www.youtube.com/embed/a8sM59-8DFw" 
                             width="100%" 
                             height="315" 
                             frameBorder="0" 
                             allowFullScreen
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onInstructionClose}>Закрыть</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default Header;*/



//с минималистично мобильной версией 
/*import React, { useState } from 'react';
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  useDisclosure
} from '@chakra-ui/react';
import {
    WarningIcon,
    ViewIcon,
    DragHandleIcon,
    PlusSquareIcon,
    CopyIcon,
    UnlockIcon
} from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; 
import ProfileUser from './ProfileUser';
import AddSubuserAccessPage from '../pages/AddSubuserAccessPage';
import TransactionsPage from '../pages/TransactionsPage';

const Header = () => {
    const [value, setValue] = useState(0);
    const { logOut } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isSubuserModalOpen, setSubuserModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const handleLogout = () => {
        logOut();
    };

    const openProfileModal = () => {
        onOpen();
    };

    const openSubuserModal = () => {
        setSubuserModalOpen(true);
    };

    const handleMenuItemClick = (content) => {
        setModalContent(content);
        onOpen();
    };

    const balanceColor = value > 100 
        ? "white"
        : (value > 30 && value < 100) 
            ? "orange.400"
            : "red.500";

    return (
        <Box 
            bg="gray.800"
            p={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            position="relative"
            h="60px"
            mt={5}
            px={{ base: 2, md: 10 }}
            mb={10}
        >
            <Box display="flex" alignItems="flex-start" flexDir={{ base: 'column', sm: 'row' }} textAlign={{ base: 'left', sm: 'left' }}>
                <Heading 
                    as="h1" 
                    size="md" 
                    color="customGreen"
                    fontSize="18px"
                >
                    AirAds.Ai
                </Heading>
                <Button 
                    variant="register"
                    leftIcon={<WarningIcon />} 
                    as={RouterLink} 
                    to="/instructions"
                    size="xs"
                    ml={{ base: 0, sm: 10 }}
                    mt={{ base: 2, sm: 0 }}
                >
                    Инструкция
                </Button>
            </Box>

            <Box display="flex" alignItems="center">
                <Text 
                    color={balanceColor} 
                    mr={4} 
                    fontSize="16px" 
                    fontWeight="bold"
                    pr={4}
                >
                    ₽ {value}
                </Text>

                <Menu>
                    <MenuButton 
                        as={IconButton}
                        isRound={true}
                        variant='solid'
                        colorScheme='green'
                        aria-label='Profile Menu'
                        fontSize='20px'
                        icon={<ViewIcon />}
                    />
                    <MenuList color="green.400">
                        <MenuItem icon={<DragHandleIcon />} onClick={() => handleMenuItemClick(<ProfileUser />)}>Профиль</MenuItem>
                       {/* <MenuItem icon={<CopyIcon />} onClick={() => handleMenuItemClick(<TransactionsPage />)}>Оплаты</MenuItem> *//*}
                    <MenuItem icon={<UnlockIcon />} onClick={handleLogout}>Выход</MenuItem>
                    </MenuList>
                </Menu>
            </Box>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        {modalContent}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Назад</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default Header;*/ 





//рабочий
/*import React, { useState } from 'react';
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  useDisclosure
} from '@chakra-ui/react';
import {
    WarningIcon,
    ViewIcon,
    DragHandleIcon,
    PlusSquareIcon,
    CopyIcon,
    UnlockIcon
} from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; 
import ProfileUser from './ProfileUser';
import AddSubuserAccessPage from '../pages/AddSubuserAccessPage';
import TransactionsPage from '../pages/TransactionsPage';

const Header = () => {
    const [value, setValue] = useState(0);
    const { logOut } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isSubuserModalOpen, setSubuserModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const handleLogout = () => {
        logOut();
    };

    const openProfileModal = () => {
        onOpen();
    };

    const openSubuserModal = () => {
        setSubuserModalOpen(true);
    };

    const handleMenuItemClick = (content) => {
        setModalContent(content);
        onOpen();
    };

    const balanceColor = value > 100 
        ? "white"
        : (value > 30 && value < 100) 
            ? "orange.400"
            : "red.500";

    return (
        <Box 
            bg="gray.800"
            p={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            position="relative"
            h="60px"
            mt={5}
            px={10}
            mb={10}
        >
            <Box display="flex" alignItems="flex-start" flexDir={{ base: 'column', sm: 'row' }} textAlign={{ base: 'left', sm: 'left' }}>
    <Heading 
        as="h1" 
        size="md" 
        color="customGreen"
        fontSize="18px"
    >
        AirAds.Ai
    </Heading>
    <Button 
        variant="register"
        leftIcon={<WarningIcon />} 
        as={RouterLink} 
        to="/instructions"
        size="xs"
        ml={{ base: 0, sm: 10 }}
        mt={{ base: 2, sm: 0 }}
    >
        Инструкция
    </Button>
</Box>


            <Box display="flex" alignItems="center">
                <Text 
                    color={balanceColor} 
                    mr={4} 
                    fontSize="16px" 
                    fontWeight="bold"
                    pr={4}
                >
                    ₽ {value}
                </Text>

                <Menu>
                    <MenuButton 
                        as={IconButton}
                        isRound={true}
                        variant='solid'
                        colorScheme='green'
                        aria-label='Profile Menu'
                        fontSize='20px'
                        icon={<ViewIcon />}
                    />
                    <MenuList color="green.400">
                        <MenuItem icon={<DragHandleIcon />} onClick={() => handleMenuItemClick(<ProfileUser />)}>Профиль</MenuItem>
                        <MenuItem icon={<CopyIcon />} onClick={() => handleMenuItemClick(<TransactionsPage />)}>Оплаты</MenuItem>
                        <MenuItem icon={<UnlockIcon />} onClick={handleLogout}>Выход</MenuItem>
                    </MenuList>
                </Menu>
            </Box>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        {modalContent}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Назад</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default Header;*/







/*import React, { useState } from 'react';
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure
} from '@chakra-ui/react';
import {
    WarningIcon,
    ViewIcon,
    DragHandleIcon,
    PlusSquareIcon,
    CopyIcon,
    UnlockIcon
} from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; 
import ProfileUser from './ProfileUser'; // Подразумевается, что компонент импортирован
import AddSubuserAccessPage from '../pages/AddSubuserAccessPage'; // Импортируйте компонент AddSubuserAccess
import TransactionsPage from '../pages/TransactionsPage';


const Header = () => {
    const [value, setValue] = useState(0);
    const [serverStatus, setServerStatus] = useState("offline");
    const { logOut } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isSubuserModalOpen, setSubuserModalOpen] = useState(false);


    const handleLogout = () => {
        logOut(); // Вызывается только при клике на кнопку
      };

      const openProfileModal = () => {
        onOpen(); // Функция для открытия модального окна
      };

      const openSubuserModal = () => {
        setSubuserModalOpen(true);
      };

    const balanceColor = value > 100 
        ? "white"
        : (value > 30 && value < 100) 
            ? "orange.400"
            : "red.500"; 


    const [modalContent, setModalContent] = useState(null);

    const handleMenuItemClick = (content) => {
            setModalContent(content);
            onOpen();
         };

    return (
        <Box 
            bg="gray.800" // Используем цвет из вашей темы
            p={4} 
            display="flex" 
            alignItems="center" 
            justifyContent="space-between" 
            position="relative" 
            h="60px"
            mt={5}
            //ml={5}
            //mr={5}
            px={10}
            mb={10}
        >
            <Box display="flex" alignItems="center">
                <Heading 
                    as="h1" 
                    size="md" 
                    color="customGreen" // Custom color from theme
                    fontSize="18px"
                >
                    PostBackRouter
                </Heading>

                <Button 
                    variant="register" // Custom variant from theme
                    leftIcon={<WarningIcon />} 
                    as={RouterLink} 
                    to="/instructions"
                    size="xs"
                    ml={10}
                >
                    Instructions
                </Button>
            </Box>

            <Box display="flex" alignItems="center">
                <Text 
                    color={balanceColor} 
                    mr={4} 
                    fontSize="16px" 
                    fontWeight="bold"
                    pr={4}
                >
                    Balance: ${value}
                </Text>

                <Menu>
                    <MenuButton 
                        as={IconButton}
                        isRound={true}
                        variant='solid'
                        colorScheme='green'
                        aria-label='Profile Menu'
                        fontSize='20px'
                        icon={<ViewIcon />}
                    />
                    <MenuList color="green.400">
                          <MenuItem icon={<DragHandleIcon />} onClick={() => handleMenuItemClick(<ProfileUser />)}>Profile</MenuItem>
                          <MenuItem icon={<PlusSquareIcon />} onClick={() => handleMenuItemClick(<AddSubuserAccessPage />)}>Access</MenuItem>
                          <MenuItem icon={<CopyIcon />} onClick={() => handleMenuItemClick(<TransactionsPage />)}>Payment</MenuItem>
                          <MenuItem icon={<UnlockIcon />} onClick={handleLogout}>Log out</MenuItem>
                    </MenuList>
                </Menu>
            </Box>
        
 
      <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        {modalContent}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default Header; */




/*import React, { useState } from 'react';
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure
} from '@chakra-ui/react';
import {
    WarningIcon,
    ViewIcon,
    DragHandleIcon,
    PlusSquareIcon,
    CopyIcon,
    UnlockIcon
} from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; 
import ProfileUser from './ProfileUser'; // Подразумевается, что компонент импортирован
import AddSubuserAccessPage from '../pages/AddSubuserAccessPage'; // Импортируйте компонент AddSubuserAccess
import TransactionsPage from '../pages/TransactionsPage';


const Header = () => {
    const [value, setValue] = useState(0);
    const [serverStatus, setServerStatus] = useState("offline");
    const { logOut } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isSubuserModalOpen, setSubuserModalOpen] = useState(false);


    const handleLogout = () => {
        logOut(); // Вызывается только при клике на кнопку
      };

      const openProfileModal = () => {
        onOpen(); // Функция для открытия модального окна
      };

      const openSubuserModal = () => {
        setSubuserModalOpen(true);
      };

    const balanceColor = value > 100 
        ? "white"
        : (value > 30 && value < 100) 
            ? "orange.400"
            : "red.500"; 


    const [modalContent, setModalContent] = useState(null);

    const handleMenuItemClick = (content) => {
            setModalContent(content);
            onOpen();
         };

    return (
        <Box 
            bg="gray.800" // Используем цвет из вашей темы
            p={4} 
            display="flex" 
            alignItems="center" 
            justifyContent="space-between" 
            position="relative" 
            h="60px"
            mt={5}
            //ml={5}
            //mr={5}
            px={10}
            mb={10}
        >
            <Box display="flex" alignItems="center">
                <Heading 
                    as="h1" 
                    size="md" 
                    color="customGreen" // Custom color from theme
                    fontSize="18px"
                >
                    PostBackRouter
                </Heading>

                <Button 
                    variant="register" // Custom variant from theme
                    leftIcon={<WarningIcon />} 
                    as={RouterLink} 
                    to="/instructions"
                    size="xs"
                    ml={10}
                >
                    Instructions
                </Button>
            </Box>

            <Text 
                color={serverStatus === "online" ? "green.400" : "red.500"} 
                fontWeight="bold" 
                fontSize="16px"
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
            >
                {serverStatus === "online" ? "• Server online" : "• Server offline"}
            </Text>

            <Box display="flex" alignItems="center">
                <Text 
                    color={balanceColor} 
                    mr={4} 
                    fontSize="16px" 
                    fontWeight="bold"
                    pr={4}
                >
                    Balance: ${value}
                </Text>

                <Menu>
                    <MenuButton 
                        as={IconButton}
                        isRound={true}
                        variant='solid'
                        colorScheme='green'
                        aria-label='Profile Menu'
                        fontSize='20px'
                        icon={<ViewIcon />}
                    />
                    <MenuList color="green.400">
                          <MenuItem icon={<DragHandleIcon />} onClick={() => handleMenuItemClick(<ProfileUser />)}>Profile</MenuItem>
                          <MenuItem icon={<PlusSquareIcon />} onClick={() => handleMenuItemClick(<AddSubuserAccessPage />)}>Access</MenuItem>
                          <MenuItem icon={<CopyIcon />} onClick={() => handleMenuItemClick(<TransactionsPage />)}>Payment</MenuItem>
                          <MenuItem icon={<UnlockIcon />} onClick={handleLogout}>Log out</MenuItem>
                    </MenuList>
                </Menu>
            </Box>
        
    
      <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        {modalContent}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default Header;*/

