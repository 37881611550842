import React from 'react';
import { Button } from '@chakra-ui/react';

const WhatsappButton = ({ phone, message }) => {
  const handleSendWhatsapp = () => {
    if (!phone || !message) {
      alert('Введите номер телефона и сообщение');
      return;
    }

    const whatsappUrl = `https://wa.me/${phone}?text=${encodeURIComponent(message)}`;

    // Открытие WhatsApp с заранее подготовленным сообщением
    window.open(whatsappUrl, '_blank');
  };

  return (
    <Button
      backgroundColor="#25D366"
      onClick={handleSendWhatsapp}
      size="lg"
      fontSize="lg"
      color="white"
    >
      Отправить в WhatsApp
    </Button>
  );
};

export default WhatsappButton;





/*import React from 'react';
import { Button } from '@chakra-ui/react';

const WhatsappButton = () => {
  const handleSendWhatsapp = () => {
    const phoneNumber = "+79287759977";
    const message = "Привет как дела";
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    // Открытие WhatsApp с заранее подготовленным сообщением
    window.open(whatsappUrl, '_blank');
  };

  return (
    <Button
      backgroundColor="#25D366"
      onClick={handleSendWhatsapp}
      size="lg"
      fontSize="lg"
      color="white"
    >
      Отправить в WhatsApp
    </Button>
  );
};

export default WhatsappButton;*/
