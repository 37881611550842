// AppWithAuth.jsx или там, где вы объявляете корень вашего приложения
import React from 'react';
import { AuthProvider } from './contexts/AuthContext';
import App from './App';

const AppWithAuth = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default AppWithAuth;
