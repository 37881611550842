import React, { useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Input,
  Textarea,
  useToast,
  Flex,
  VStack,
  HStack,
  Spinner,
} from '@chakra-ui/react';
import { FaPaperclip, FaPaperPlane, FaDownload } from 'react-icons/fa';
import axios from 'axios';

const FileUploadTab = () => {
  const [files, setFiles] = useState([]);
  const [textInput, setTextInput] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const handleTextChange = (e) => {
    setTextInput(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const formData = new FormData();
    Array.from(files).forEach(file => {
      formData.append('files', file);
    });
    formData.append('text', textInput);

    try {
      const response = await axios.post('/api/openai-endpoint', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setResponse(response.data);
      toast({
        title: 'Success',
        description: 'Data sent successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error sending data:', error);
      toast({
        title: 'Error',
        description: 'There was an error sending the data.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = (fileUrl) => {
    window.open(fileUrl, '_blank');
  };

  return (
    <Box p={5}>
      <VStack spacing={4}>
        <Textarea
          placeholder="Response from OpenAI will appear here..."
          value={response}
          height="500px"
          isReadOnly
          bg="gray.700"
          color="white"
          borderRadius="md"
          p={4}
        />
        <IconButton
          icon={<FaDownload />}
          aria-label="Download files"
          alignSelf="flex-end"
          onClick={() => handleDownload('/path-to-file')}
          colorScheme="blue"
          variant="outline"
        />
      </VStack>

      <HStack mt={4} spacing={4}>
        <IconButton
          icon={<FaPaperclip />}
          aria-label="Attach files"
          variant="outline"
          colorScheme="blue"
          as="label"
          htmlFor="file-upload"
        />
        <Input
          type="file"
          id="file-upload"
          multiple
          onChange={handleFileChange}
          display="none"
        />
        <Input
          placeholder="Enter your text here"
          value={textInput}
          onChange={handleTextChange}
          flex="1"
        />
        <Button
          rightIcon={loading ? <Spinner size="sm" /> : <FaPaperPlane />}
          colorScheme="green"
          onClick={handleSubmit}
          isLoading={loading}
        >
          Send
        </Button>
      </HStack>
    </Box>
  );
};

export default FileUploadTab;
