import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Input,
  Textarea,
  Heading,
  Spinner,
  useToast,
  useBoolean,
  useBreakpointValue
} from '@chakra-ui/react';
import { FaTrash, FaPlus } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const ContactsTablesTab = () => {
  const [phoneContacts, setPhoneContacts] = useState([]);
  const [nicknameContacts, setNicknameContacts] = useState([]);
  const [loadingFields, setLoadingFields] = useState({});
  const [isAddingPhone, setIsAddingPhone] = useBoolean();
  const [isAddingNickname, setIsAddingNickname] = useBoolean();
  const toast = useToast();
  const inputFontSize = useBreakpointValue({ base: 'xs', md: 'md' });

  useEffect(() => {
    // Fetch phone contacts
    axios.get(`${BACKEND_DOMAIN}/phoneContacts`, { withCredentials: true })
      .then(response => setPhoneContacts(response.data))
      .catch(error => console.error('Error fetching phone contacts:', error));

    // Fetch nickname contacts
    axios.get(`${BACKEND_DOMAIN}/nicknameContacts`, { withCredentials: true })
      .then(response => setNicknameContacts(response.data))
      .catch(error => console.error('Error fetching nickname contacts:', error));
  }, []);

  const handleAddRow = async (type) => {
    if (type === 'phone') {
      setIsAddingPhone.on();
      const newContact = { phone: 'none', info: 'none' };
      try {
        const response = await axios.post(`${BACKEND_DOMAIN}/phoneContacts`, newContact, { withCredentials: true });
        setPhoneContacts([...phoneContacts, response.data]);
      } catch (error) {
        console.error('Error adding phone contact:', error);
      } finally {
        setIsAddingPhone.off();
      }
    } else {
      setIsAddingNickname.on();
      const newContact = { nickname: 'none', info: 'none' };
      try {
        const response = await axios.post(`${BACKEND_DOMAIN}/nicknameContacts`, newContact, { withCredentials: true });
        setNicknameContacts([...nicknameContacts, response.data]);
      } catch (error) {
        console.error('Error adding nickname contact:', error);
      } finally {
        setIsAddingNickname.off();
      }
    }
  };

  const handleRemoveRow = async (type, index) => {
    const id = type === 'phone' ? phoneContacts[index]._id : nicknameContacts[index]._id;
    const url = type === 'phone' ? `${BACKEND_DOMAIN}/phoneContacts/${id}` : `${BACKEND_DOMAIN}/nicknameContacts/${id}`;

    setLoadingFields(prev => ({ ...prev, [`${type}-${index}-delete`]: true }));

    try {
      await axios.delete(url, { withCredentials: true });
      toast({
        title: 'Contact deleted',
        description: 'The contact has been deleted successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      if (type === 'phone') {
        setPhoneContacts(phoneContacts.filter((_, i) => i !== index));
      } else {
        setNicknameContacts(nicknameContacts.filter((_, i) => i !== index));
      }
    } catch (error) {
      console.error('Error deleting contact:', error);
      toast({
        title: 'Error',
        description: 'There was an error deleting the contact.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${type}-${index}-delete`]: false }));
    }
  };

  const handleChange = (type, index, field, value) => {
    if (type === 'phone') {
      const updatedContacts = [...phoneContacts];
      updatedContacts[index][field] = value === '' ? 'none' : value;
      setPhoneContacts(updatedContacts);

      axios.put(`${BACKEND_DOMAIN}/phoneContacts/${updatedContacts[index]._id}`, updatedContacts[index], { withCredentials: true })
        .catch(error => console.error('Error updating phone contact:', error));
    } else {
      const updatedContacts = [...nicknameContacts];
      updatedContacts[index][field] = value === '' ? 'none' : value;
      setNicknameContacts(updatedContacts);

      axios.put(`${BACKEND_DOMAIN}/nicknameContacts/${updatedContacts[index]._id}`, updatedContacts[index], { withCredentials: true })
        .catch(error => console.error('Error updating nickname contact:', error));
    }
  };

  const renderTable = (contacts, type) => (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th color="green.200" minWidth="150px">{type === 'phone' ? 'Номер телефона' : 'Никнейм'}</Th>
          <Th color="green.200" minWidth="150px">Информация</Th>
          <Th color="green.200" width="10%"></Th>
        </Tr>
      </Thead>
      <Tbody>
        <AnimatePresence>
          {contacts.map((contact, index) => (
            <motion.tr
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.5 }}
            >
              <Td>
                <Input
                  value={type === 'phone' ? (contact.phone === 'none' ? '' : contact.phone) : (contact.nickname === 'none' ? '' : contact.nickname)}
                  onChange={(e) => handleChange(type, index, type === 'phone' ? 'phone' : 'nickname', e.target.value)}
                  placeholder={type === 'phone' ? '79287759988 введите номер в международном формате' : 'Введите никнейм'}
                  border="none"
                  p={0}
                  fontSize={inputFontSize}
                  _focus={{ boxShadow: "none", border: "none" }}
                />
              </Td>
              <Td>
                <Textarea
                  value={contact.info === 'none' ? '' : contact.info}
                  onChange={(e) => handleChange(type, index, 'info', e.target.value)}
                  placeholder="Введите информацию о клиенте"
                  border="none"
                  p={0}
                  fontSize={inputFontSize}
                  _focus={{ boxShadow: "none", border: "none" }}
                />
              </Td>
              <Td>
                <IconButton
                  icon={loadingFields[`${type}-${index}-delete`] ? <Spinner size="sm" /> : <FaTrash />}
                  aria-label="Delete"
                  onClick={() => handleRemoveRow(type, index)}
                  color="red.200"
                  size="sm"
                  isDisabled={loadingFields[`${type}-${index}-delete`]}
                />
              </Td>
            </motion.tr>
          ))}
        </AnimatePresence>
      </Tbody>
    </Table>
  );

  return (
    <Box p={5}>
      <Heading as="h1" size="ml" mb={6} color="white">Телефонные контакты</Heading>
      <Box overflowX="auto">
        {renderTable(phoneContacts, 'phone')}
      </Box>
      <Box overflowX="hidden" mt={4}>
        <Button
          leftIcon={isAddingPhone ? <Spinner size="sm" /> : <FaPlus />}
          onClick={() => handleAddRow('phone')}
          mt={4}
          color="yellow.200"
          variant="outline"
          borderColor="yellow.200"
          borderWidth="1px"
          borderStyle="dashed"
          width="full"
          mb={10}
          isDisabled={isAddingPhone}
        >
          {isAddingPhone ? 'Добавление...' : 'Добавить контакт'}
        </Button>
      </Box>

      <Heading as="h1" size="ml" mt={8} mb={6} color="white">Никнейм контакты</Heading>
      <Box overflowX="auto">
        {renderTable(nicknameContacts, 'nickname')}
      </Box>
      <Box overflowX="hidden" mt={4}>
        <Button
          leftIcon={isAddingNickname ? <Spinner size="sm" /> : <FaPlus />}
          onClick={() => handleAddRow('nickname')}
          mt={4}
          color="yellow.200"
          variant="outline"
          borderColor="yellow.200"
          borderWidth="1px"
          borderStyle="dashed"
          width="full"
          mb={20}
          isDisabled={isAddingNickname}
        >
          {isAddingNickname ? 'Добавление...' : 'Добавить контакт'}
        </Button>
      </Box>
    </Box>
  );
};

export default ContactsTablesTab;








// src/pages/tabs/ContactsTablesTab.jsx
//рабочий со всеми анимацией и базой данных
/*import React, { useState, useEffect } from 'react';
import { Box, Button, Table, Thead, Tbody, Tr, Th, Td, IconButton, Input, Textarea, Heading, Spinner, useToast, useBoolean } from '@chakra-ui/react';
import { FaTrash, FaPlus } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const ContactsTablesTab = () => {
  const [phoneContacts, setPhoneContacts] = useState([]);
  const [nicknameContacts, setNicknameContacts] = useState([]);
  const [loadingFields, setLoadingFields] = useState({});
  const [isAddingPhone, setIsAddingPhone] = useBoolean();
  const [isAddingNickname, setIsAddingNickname] = useBoolean();
  const toast = useToast();

  useEffect(() => {
    // Fetch phone contacts
    axios.get(`${BACKEND_DOMAIN}/phoneContacts`, { withCredentials: true })
      .then(response => setPhoneContacts(response.data))
      .catch(error => console.error('Error fetching phone contacts:', error));

    // Fetch nickname contacts
    axios.get(`${BACKEND_DOMAIN}/nicknameContacts`, { withCredentials: true })
      .then(response => setNicknameContacts(response.data))
      .catch(error => console.error('Error fetching nickname contacts:', error));
  }, []);

  const handleAddRow = async (type) => {
    if (type === 'phone') {
      setIsAddingPhone.on();
      const newContact = { phone: 'none', info: 'none' };
      try {
        const response = await axios.post(`${BACKEND_DOMAIN}/phoneContacts`, newContact, { withCredentials: true });
        setPhoneContacts([...phoneContacts, response.data]);
      } catch (error) {
        console.error('Error adding phone contact:', error);
      } finally {
        setIsAddingPhone.off();
      }
    } else {
      setIsAddingNickname.on();
      const newContact = { nickname: 'none', info: 'none' };
      try {
        const response = await axios.post(`${BACKEND_DOMAIN}/nicknameContacts`, newContact, { withCredentials: true });
        setNicknameContacts([...nicknameContacts, response.data]);
      } catch (error) {
        console.error('Error adding nickname contact:', error);
      } finally {
        setIsAddingNickname.off();
      }
    }
  };

  const handleRemoveRow = async (type, index) => {
    const id = type === 'phone' ? phoneContacts[index]._id : nicknameContacts[index]._id;
    const url = type === 'phone' ? `${BACKEND_DOMAIN}/phoneContacts/${id}` : `${BACKEND_DOMAIN}/nicknameContacts/${id}`;

    setLoadingFields(prev => ({ ...prev, [`${type}-${index}-delete`]: true }));

    try {
      await axios.delete(url, { withCredentials: true });
      toast({
        title: 'Contact deleted',
        description: 'The contact has been deleted successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      if (type === 'phone') {
        setPhoneContacts(phoneContacts.filter((_, i) => i !== index));
      } else {
        setNicknameContacts(nicknameContacts.filter((_, i) => i !== index));
      }
    } catch (error) {
      console.error('Error deleting contact:', error);
      toast({
        title: 'Error',
        description: 'There was an error deleting the contact.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${type}-${index}-delete`]: false }));
    }
  };

  const handleChange = (type, index, field, value) => {
    if (type === 'phone') {
      const updatedContacts = [...phoneContacts];
      updatedContacts[index][field] = value === '' ? 'none' : value;
      setPhoneContacts(updatedContacts);

      axios.put(`${BACKEND_DOMAIN}/phoneContacts/${updatedContacts[index]._id}`, updatedContacts[index], { withCredentials: true })
        .catch(error => console.error('Error updating phone contact:', error));
    } else {
      const updatedContacts = [...nicknameContacts];
      updatedContacts[index][field] = value === '' ? 'none' : value;
      setNicknameContacts(updatedContacts);

      axios.put(`${BACKEND_DOMAIN}/nicknameContacts/${updatedContacts[index]._id}`, updatedContacts[index], { withCredentials: true })
        .catch(error => console.error('Error updating nickname contact:', error));
    }
  };

  const renderTable = (contacts, type) => (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th color="green.200">{type === 'phone' ? 'Номер телефона' : 'Никнейм'}</Th>
          <Th color="green.200">Информация</Th>
          <Th color="green.200"></Th>
        </Tr>
      </Thead>
      <Tbody>
        <AnimatePresence>
          {contacts.map((contact, index) => (
            <motion.tr
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.5 }}
            >
              <Td>
                <Input
                  value={type === 'phone' ? (contact.phone === 'none' ? '' : contact.phone) : (contact.nickname === 'none' ? '' : contact.nickname)}
                  onChange={(e) => handleChange(type, index, type === 'phone' ? 'phone' : 'nickname', e.target.value)}
                  placeholder={type === 'phone' ? '79287759988 введите номер в международном формате' : 'Введите никнейм'}
                  border="none"
                  p={0}
                  _focus={{ boxShadow: "none", border: "none" }}
                />
              </Td>
              <Td>
                <Textarea
                  value={contact.info === 'none' ? '' : contact.info}
                  onChange={(e) => handleChange(type, index, 'info', e.target.value)}
                  placeholder="Введите информацию о клиенте"
                  border="none"
                  p={0}
                  _focus={{ boxShadow: "none", border: "none" }}
                />
              </Td>
              <Td>
                <IconButton
                  icon={loadingFields[`${type}-${index}-delete`] ? <Spinner size="sm" /> : <FaTrash />}
                  aria-label="Delete"
                  onClick={() => handleRemoveRow(type, index)}
                  color="red.200"
                  size="sm"
                  isDisabled={loadingFields[`${type}-${index}-delete`]}
                />
              </Td>
            </motion.tr>
          ))}
        </AnimatePresence>
      </Tbody>
    </Table>
  );

  return (
    <Box p={5}>
      <Heading as="h1" size="ml" mb={6} color="white">Телефонные контакты</Heading>
      {renderTable(phoneContacts, 'phone')}
      <Box overflowX="hidden" mt={4}>
        <Button
          leftIcon={isAddingPhone ? <Spinner size="sm" /> : <FaPlus />}
          onClick={() => handleAddRow('phone')}
          mt={4}
          color="yellow.200"
          variant="outline"
          borderColor="yellow.200"
          borderWidth="1px"
          borderStyle="dashed"
          width="full"
          mb={10}
          isDisabled={isAddingPhone}
        >
          {isAddingPhone ? 'Добавление...' : 'Добавить контакт'}
        </Button>
      </Box>

      <Heading as="h1" size="ml" mt={8} mb={6} color="white">Никнейм контакты</Heading>
      {renderTable(nicknameContacts, 'nickname')}
      <Box overflowX="hidden" mt={4}>
        <Button
          leftIcon={isAddingNickname ? <Spinner size="sm" /> : <FaPlus />}
          onClick={() => handleAddRow('nickname')}
          mt={4}
          color="yellow.200"
          variant="outline"
          borderColor="yellow.200"
          borderWidth="1px"
          borderStyle="dashed"
          width="full"
          mb={20}
          isDisabled={isAddingNickname}
        >
          {isAddingNickname ? 'Добавление...' : 'Добавить контакт'}
        </Button>
      </Box>
    </Box>
  );
};

export default ContactsTablesTab;*/



// src/pages/tabs/ContactsTablesTab.jsx
/*//база данных и состояние кнопок и анимация добавления новой строки
import React, { useState, useEffect } from 'react';
import { Box, Button, Table, Thead, Tbody, Tr, Th, Td, IconButton, Input, Textarea, Heading, Spinner, useToast, useBoolean } from '@chakra-ui/react';
import { FaTrash, FaPlus } from 'react-icons/fa';
import { motion } from 'framer-motion';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const ContactsTablesTab = () => {
  const [phoneContacts, setPhoneContacts] = useState([]);
  const [nicknameContacts, setNicknameContacts] = useState([]);
  const [loadingFields, setLoadingFields] = useState({});
  const [isAddingPhone, setIsAddingPhone] = useBoolean();
  const [isAddingNickname, setIsAddingNickname] = useBoolean();
  const toast = useToast();

  useEffect(() => {
    // Fetch phone contacts
    axios.get(`${BACKEND_DOMAIN}/phoneContacts`, { withCredentials: true })
      .then(response => setPhoneContacts(response.data))
      .catch(error => console.error('Error fetching phone contacts:', error));

    // Fetch nickname contacts
    axios.get(`${BACKEND_DOMAIN}/nicknameContacts`, { withCredentials: true })
      .then(response => setNicknameContacts(response.data))
      .catch(error => console.error('Error fetching nickname contacts:', error));
  }, []);

  const handleAddRow = async (type) => {
    if (type === 'phone') {
      setIsAddingPhone.on();
      const newContact = { phone: 'none', info: 'none' };
      try {
        const response = await axios.post(`${BACKEND_DOMAIN}/phoneContacts`, newContact, { withCredentials: true });
        setPhoneContacts([...phoneContacts, response.data]);
      } catch (error) {
        console.error('Error adding phone contact:', error);
      } finally {
        setIsAddingPhone.off();
      }
    } else {
      setIsAddingNickname.on();
      const newContact = { nickname: 'none', info: 'none' };
      try {
        const response = await axios.post(`${BACKEND_DOMAIN}/nicknameContacts`, newContact, { withCredentials: true });
        setNicknameContacts([...nicknameContacts, response.data]);
      } catch (error) {
        console.error('Error adding nickname contact:', error);
      } finally {
        setIsAddingNickname.off();
      }
    }
  };

  const handleRemoveRow = async (type, index) => {
    const id = type === 'phone' ? phoneContacts[index]._id : nicknameContacts[index]._id;
    const url = type === 'phone' ? `${BACKEND_DOMAIN}/phoneContacts/${id}` : `${BACKEND_DOMAIN}/nicknameContacts/${id}`;

    setLoadingFields(prev => ({ ...prev, [`${type}-${index}-delete`]: true }));

    try {
      await axios.delete(url, { withCredentials: true });
      toast({
        title: 'Contact deleted',
        description: 'The contact has been deleted successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      if (type === 'phone') {
        setPhoneContacts(phoneContacts.filter((_, i) => i !== index));
      } else {
        setNicknameContacts(nicknameContacts.filter((_, i) => i !== index));
      }
    } catch (error) {
      console.error('Error deleting contact:', error);
      toast({
        title: 'Error',
        description: 'There was an error deleting the contact.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${type}-${index}-delete`]: false }));
    }
  };

  const handleChange = (type, index, field, value) => {
    if (type === 'phone') {
      const updatedContacts = [...phoneContacts];
      updatedContacts[index][field] = value === '' ? 'none' : value;
      setPhoneContacts(updatedContacts);

      axios.put(`${BACKEND_DOMAIN}/phoneContacts/${updatedContacts[index]._id}`, updatedContacts[index], { withCredentials: true })
        .catch(error => console.error('Error updating phone contact:', error));
    } else {
      const updatedContacts = [...nicknameContacts];
      updatedContacts[index][field] = value === '' ? 'none' : value;
      setNicknameContacts(updatedContacts);

      axios.put(`${BACKEND_DOMAIN}/nicknameContacts/${updatedContacts[index]._id}`, updatedContacts[index], { withCredentials: true })
        .catch(error => console.error('Error updating nickname contact:', error));
    }
  };

  const renderTable = (contacts, type) => (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th color="green.200">{type === 'phone' ? 'Номер телефона' : 'Никнейм'}</Th>
          <Th color="green.200">Информация</Th>
          <Th color="green.200"></Th>
        </Tr>
      </Thead>
      <Tbody>
        {contacts.map((contact, index) => (
          <motion.tr
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.5 }}
          >
            <Td>
              <Input
                value={type === 'phone' ? (contact.phone === 'none' ? '' : contact.phone) : (contact.nickname === 'none' ? '' : contact.nickname)}
                onChange={(e) => handleChange(type, index, type === 'phone' ? 'phone' : 'nickname', e.target.value)}
                placeholder={type === 'phone' ? 'Введите номер телефона' : 'Введите никнейм'}
                border="none"
                p={0}
                _focus={{ boxShadow: "none", border: "none" }}
              />
            </Td>
            <Td>
              <Textarea
                value={contact.info === 'none' ? '' : contact.info}
                onChange={(e) => handleChange(type, index, 'info', e.target.value)}
                placeholder="Введите информацию о клиенте"
                border="none"
                p={0}
                _focus={{ boxShadow: "none", border: "none" }}
              />
            </Td>
            <Td>
              <IconButton
                icon={loadingFields[`${type}-${index}-delete`] ? <Spinner size="sm" /> : <FaTrash />}
                aria-label="Delete"
                onClick={() => handleRemoveRow(type, index)}
                color="red.200"
                size="sm"
                isDisabled={loadingFields[`${type}-${index}-delete`]}
              />
            </Td>
          </motion.tr>
        ))}
      </Tbody>
    </Table>
  );

  return (
    <Box p={5}>
      <Heading as="h1" size="ml" mb={6} color="white">Телефонные контакты</Heading>
      {renderTable(phoneContacts, 'phone')}
      <Box overflowX="hidden" mt={4}>
        <Button
          leftIcon={isAddingPhone ? <Spinner size="sm" /> : <FaPlus />}
          onClick={() => handleAddRow('phone')}
          mt={4}
          color="yellow.200"
          variant="outline"
          borderColor="yellow.200"
          borderWidth="1px"
          borderStyle="dashed"
          width="full"
          mb={10}
          isDisabled={isAddingPhone}
        >
          {isAddingPhone ? 'Добавление...' : 'Добавить контакт'}
        </Button>
      </Box>

      <Heading as="h1" size="ml" mt={8} mb={6} color="white">Никнейм контакты</Heading>
      {renderTable(nicknameContacts, 'nickname')}
      <Box overflowX="hidden" mt={4}>
        <Button
          leftIcon={isAddingNickname ? <Spinner size="sm" /> : <FaPlus />}
          onClick={() => handleAddRow('nickname')}
          mt={4}
          color="yellow.200"
          variant="outline"
          borderColor="yellow.200"
          borderWidth="1px"
          borderStyle="dashed"
          width="full"
          mb={20}
          isDisabled={isAddingNickname}
        >
          {isAddingNickname ? 'Добавление...' : 'Добавить контакт'}
        </Button>
      </Box>
    </Box>
  );
};

export default ContactsTablesTab;*/




//src/pages/tabs/ContactsTablesTab.jsx
//с рабочей базой данных
/*import React, { useState, useEffect } from 'react';
import { Box, Button, Table, Thead, Tbody, Tr, Th, Td, IconButton, Input, Textarea, Heading } from '@chakra-ui/react';
import { FaTrash, FaPlus } from 'react-icons/fa';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const ContactsTablesTab = () => {
  const [phoneContacts, setPhoneContacts] = useState([]);
  const [nicknameContacts, setNicknameContacts] = useState([]);

  useEffect(() => {
    // Fetch phone contacts
    axios.get(`${BACKEND_DOMAIN}/phoneContacts`, { withCredentials: true })
      .then(response => setPhoneContacts(response.data))
      .catch(error => console.error('Error fetching phone contacts:', error));

    // Fetch nickname contacts
    axios.get(`${BACKEND_DOMAIN}/nicknameContacts`, { withCredentials: true })
      .then(response => setNicknameContacts(response.data))
      .catch(error => console.error('Error fetching nickname contacts:', error));
  }, []);

  const handleAddRow = (type) => {
    if (type === 'phone') {
      const newContact = { phone: 'none', info: 'none' };
      axios.post(`${BACKEND_DOMAIN}/phoneContacts`, newContact, { withCredentials: true })
        .then(response => setPhoneContacts([...phoneContacts, response.data]))
        .catch(error => console.error('Error adding phone contact:', error));
    } else {
      const newContact = { nickname: 'none', info: 'none' };
      axios.post(`${BACKEND_DOMAIN}/nicknameContacts`, newContact, { withCredentials: true })
        .then(response => setNicknameContacts([...nicknameContacts, response.data]))
        .catch(error => console.error('Error adding nickname contact:', error));
    }
  };

  const handleRemoveRow = (type, index) => {
    const id = type === 'phone' ? phoneContacts[index]._id : nicknameContacts[index]._id;
    const url = type === 'phone' ? `${BACKEND_DOMAIN}/phoneContacts/${id}` : `${BACKEND_DOMAIN}/nicknameContacts/${id}`;

    axios.delete(url, { withCredentials: true })
      .then(() => {
        if (type === 'phone') {
          setPhoneContacts(phoneContacts.filter((_, i) => i !== index));
        } else {
          setNicknameContacts(nicknameContacts.filter((_, i) => i !== index));
        }
      })
      .catch(error => console.error('Error deleting contact:', error));
  };

  const handleChange = (type, index, field, value) => {
    if (type === 'phone') {
      const updatedContacts = [...phoneContacts];
      updatedContacts[index][field] = value === '' ? 'none' : value;
      setPhoneContacts(updatedContacts);

      axios.put(`${BACKEND_DOMAIN}/phoneContacts/${updatedContacts[index]._id}`, updatedContacts[index], { withCredentials: true })
        .catch(error => console.error('Error updating phone contact:', error));
    } else {
      const updatedContacts = [...nicknameContacts];
      updatedContacts[index][field] = value === '' ? 'none' : value;
      setNicknameContacts(updatedContacts);

      axios.put(`${BACKEND_DOMAIN}/nicknameContacts/${updatedContacts[index]._id}`, updatedContacts[index], { withCredentials: true })
        .catch(error => console.error('Error updating nickname contact:', error));
    }
  };

  const renderTable = (contacts, type) => (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th color="green.200">{type === 'phone' ? 'Номер телефона' : 'Никнейм'}</Th>
          <Th color="green.200">Информация</Th>
          <Th color="green.200"></Th>
        </Tr>
      </Thead>
      <Tbody>
        {contacts.map((contact, index) => (
          <Tr key={index}>
            <Td>
              <Input
                value={type === 'phone' ? (contact.phone === 'none' ? '' : contact.phone) : (contact.nickname === 'none' ? '' : contact.nickname)}
                onChange={(e) => handleChange(type, index, type === 'phone' ? 'phone' : 'nickname', e.target.value)}
                placeholder={type === 'phone' ? 'Введите номер телефона' : 'Введите никнейм'}
                border="none"
                p={0}
                _focus={{ boxShadow: "none", border: "none" }}
              />
            </Td>
            <Td>
              <Textarea
                value={contact.info === 'none' ? '' : contact.info}
                onChange={(e) => handleChange(type, index, 'info', e.target.value)}
                placeholder="Введите информацию о клиенте"
                border="none"
                p={0}
                _focus={{ boxShadow: "none", border: "none" }}
              />
            </Td>
            <Td>
              <IconButton
                icon={<FaTrash />}
                aria-label="Delete"
                onClick={() => handleRemoveRow(type, index)}
                color="red.200"
                size="sm"
              />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );

  return (
    <Box p={5}>
      <Heading as="h1" size="ml" mb={6} color="white">Телефонные контакты</Heading>
      {renderTable(phoneContacts, 'phone')}
      <Box overflowX="hidden" mt={4}>
        <Button
          leftIcon={<FaPlus />}
          onClick={() => handleAddRow('phone')}
          mt={4}
          color="yellow.200"
          variant="outline"
          borderColor="yellow.200"
          borderWidth="1px"
          borderStyle="dashed"
          width="full"
          mb={10}
        >
          Добавить контакт
        </Button>
      </Box>

      <Heading as="h1" size="ml" mt={8} mb={6} color="white">Никнейм контакты</Heading>
      {renderTable(nicknameContacts, 'nickname')}
      <Box overflowX="hidden" mt={4}>
        <Button
          leftIcon={<FaPlus />}
          onClick={() => handleAddRow('nickname')}
          mt={4}
          color="yellow.200"
          variant="outline"
          borderColor="yellow.200"
          borderWidth="1px"
          borderStyle="dashed"
          width="full"
          mb={20}
        >
          Добавить контакт
        </Button>
      </Box>
    </Box>
  );
};

export default ContactsTablesTab;*/



/*import React, { useState, useEffect } from 'react';
import { Box, Button, Table, Thead, Tbody, Tr, Th, Td, IconButton, Input, Textarea, Heading } from '@chakra-ui/react';
import { FaTrash, FaPlus } from 'react-icons/fa';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const ContactsTablesTab = () => {
  const [phoneContacts, setPhoneContacts] = useState([]);
  const [nicknameContacts, setNicknameContacts] = useState([]);

  useEffect(() => {
    // Fetch phone contacts
    axios.get(`${BACKEND_DOMAIN}/phoneContacts`, { withCredentials: true })
      .then(response => setPhoneContacts(response.data))
      .catch(error => console.error('Error fetching phone contacts:', error));

    // Fetch nickname contacts
    axios.get(`${BACKEND_DOMAIN}/nicknameContacts`, { withCredentials: true })
      .then(response => setNicknameContacts(response.data))
      .catch(error => console.error('Error fetching nickname contacts:', error));
  }, []);

  const handleAddRow = (type) => {
    if (type === 'phone') {
      const newContact = { phone: '', info: '' };
      axios.post(`${BACKEND_DOMAIN}/phoneContacts`, newContact, { withCredentials: true })
        .then(response => setPhoneContacts([...phoneContacts, response.data]))
        .catch(error => console.error('Error adding phone contact:', error));
    } else {
      const newContact = { nickname: '', info: '' };
      axios.post(`${BACKEND_DOMAIN}/nicknameContacts`, newContact, { withCredentials: true })
        .then(response => setNicknameContacts([...nicknameContacts, response.data]))
        .catch(error => console.error('Error adding nickname contact:', error));
    }
  };

  const handleRemoveRow = (type, index) => {
    const id = type === 'phone' ? phoneContacts[index]._id : nicknameContacts[index]._id;
    const url = type === 'phone' ? `${BACKEND_DOMAIN}/phoneContacts/${id}` : `${BACKEND_DOMAIN}/nicknameContacts/${id}`;

    axios.delete(url, { withCredentials: true })
      .then(() => {
        if (type === 'phone') {
          setPhoneContacts(phoneContacts.filter((_, i) => i !== index));
        } else {
          setNicknameContacts(nicknameContacts.filter((_, i) => i !== index));
        }
      })
      .catch(error => console.error('Error deleting contact:', error));
  };

  const handleChange = (type, index, field, value) => {
    if (type === 'phone') {
      const updatedContacts = [...phoneContacts];
      updatedContacts[index][field] = value === '' ? 'none' : value;
      setPhoneContacts(updatedContacts);

      axios.put(`${BACKEND_DOMAIN}/phoneContacts/${updatedContacts[index]._id}`, updatedContacts[index], { withCredentials: true })
        .catch(error => console.error('Error updating phone contact:', error));
    } else {
      const updatedContacts = [...nicknameContacts];
      updatedContacts[index][field] = value === '' ? 'none' : value;
      setNicknameContacts(updatedContacts);

      axios.put(`${BACKEND_DOMAIN}/nicknameContacts/${updatedContacts[index]._id}`, updatedContacts[index], { withCredentials: true })
        .catch(error => console.error('Error updating nickname contact:', error));
    }
  };

  const renderTable = (contacts, type) => (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th color="green.200">{type === 'phone' ? 'Номер телефона' : 'Никнейм'}</Th>
          <Th color="green.200">Информация</Th>
          <Th color="green.200"></Th>
        </Tr>
      </Thead>
      <Tbody>
        {contacts.map((contact, index) => (
          <Tr key={index}>
            <Td>
              <Input
                value={type === 'phone' ? (contact.phone === 'none' ? '' : contact.phone) : (contact.nickname === 'none' ? '' : contact.nickname)}
                onChange={(e) => handleChange(type, index, type === 'phone' ? 'phone' : 'nickname', e.target.value)}
                placeholder={type === 'phone' ? 'Введите номер телефона' : 'Введите никнейм'}
                border="none"
                p={0}
                _focus={{ boxShadow: "none", border: "none" }}
              />
            </Td>
            <Td>
              <Textarea
                value={contact.info === 'none' ? '' : contact.info}
                onChange={(e) => handleChange(type, index, 'info', e.target.value)}
                placeholder="Введите информацию о клиенте"
                border="none"
                p={0}
                _focus={{ boxShadow: "none", border: "none" }}
              />
            </Td>
            <Td>
              <IconButton
                icon={<FaTrash />}
                aria-label="Delete"
                onClick={() => handleRemoveRow(type, index)}
                color="red.200"
                size="sm"
              />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );

  return (
    <Box p={5}>
      <Heading as="h1" size="ml" mb={6} color="white">Телефонные контакты</Heading>
      {renderTable(phoneContacts, 'phone')}
      <Box overflowX="hidden" mt={4}>
        <Button
          leftIcon={<FaPlus />}
          onClick={() => handleAddRow('phone')}
          mt={4}
          color="yellow.200"
          variant="outline"
          borderColor="yellow.200"
          borderWidth="1px"
          borderStyle="dashed"
          width="full"
          mb={10}
        >
          Добавить контакт
        </Button>
      </Box>

      <Heading as="h1" size="ml" mt={8} mb={6} color="white">Никнейм контакты</Heading>
      {renderTable(nicknameContacts, 'nickname')}
      <Box overflowX="hidden" mt={4}>
        <Button
          leftIcon={<FaPlus />}
          onClick={() => handleAddRow('nickname')}
          mt={4}
          color="yellow.200"
          variant="outline"
          borderColor="yellow.200"
          borderWidth="1px"
          borderStyle="dashed"
          width="full"
          mb={20}
        >
          Добавить контакт
        </Button>
      </Box>
    </Box>
  );
};

export default ContactsTablesTab;*/



// src/pages/tabs/ContactsTablesTab.jsx
/*//последний рабочий голый UI
import React, { useState } from 'react';
import { Box, Button, Table, Thead, Tbody, Tr, Th, Td, IconButton, Input, Textarea, Heading } from '@chakra-ui/react';
import { FaTrash, FaPlus } from 'react-icons/fa';

const ContactsTablesTab = () => {
  const [phoneContacts, setPhoneContacts] = useState([{ phone: '', info: '' }]);
  const [nicknameContacts, setNicknameContacts] = useState([{ nickname: '', info: '' }]);

  const handleAddRow = (type) => {
    if (type === 'phone') {
      setPhoneContacts([...phoneContacts, { phone: '', info: '' }]);
    } else {
      setNicknameContacts([...nicknameContacts, { nickname: '', info: '' }]);
    }
  };

  const handleRemoveRow = (type, index) => {
    if (type === 'phone') {
      setPhoneContacts(phoneContacts.filter((_, i) => i !== index));
    } else {
      setNicknameContacts(nicknameContacts.filter((_, i) => i !== index));
    }
  };

  const handleChange = (type, index, field, value) => {
    if (type === 'phone') {
      const updatedContacts = [...phoneContacts];
      updatedContacts[index][field] = value;
      setPhoneContacts(updatedContacts);
    } else {
      const updatedContacts = [...nicknameContacts];
      updatedContacts[index][field] = value;
      setNicknameContacts(updatedContacts);
    }
  };

  const renderTable = (contacts, type) => (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th color="green.200">{type === 'phone' ? 'Номер телефона' : 'Никнейм'}</Th>
          <Th color="green.200">Информация</Th>
          <Th color="green.200"></Th>
        </Tr>
      </Thead>
      <Tbody>
        {contacts.map((contact, index) => (
          <Tr key={index}>
            <Td>
              <Input
                value={type === 'phone' ? contact.phone : contact.nickname}
                onChange={(e) => handleChange(type, index, type === 'phone' ? 'phone' : 'nickname', e.target.value)}
                placeholder={type === 'phone' ? 'Введите номер телефона' : 'Введите никнейм'}
                border="none"
                p={0}
                _focus={{ boxShadow: "none", border: "none" }}
              />
            </Td>
            <Td>
              <Textarea
                value={contact.info}
                onChange={(e) => handleChange(type, index, 'info', e.target.value)}
                placeholder="Введите информацию о клиенте"
                border="none"
                p={0}
                _focus={{ boxShadow: "none", border: "none" }}
              />
            </Td>
            <Td>
              <IconButton
                icon={<FaTrash />}
                aria-label="Delete"
                onClick={() => handleRemoveRow(type, index)}
                color="red.200"
                size="sm"
              />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );

  return (
    <Box p={5}>
      <Heading as="h1" size="ml" mb={6} color="white">Телефонные контакты</Heading>
      {renderTable(phoneContacts, 'phone')}
      <Box overflowX="hidden" mt={4}>
      <Button
        leftIcon={<FaPlus />}
        onClick={() => handleAddRow('phone')}
        mt={4}
        color="yellow.200"
        variant="outline"
        borderColor="yellow.200"
        borderWidth="1px"
        borderStyle="dashed"
        width="full"
        mb={10}
      >
        Добавить контакт
      </Button>
      </Box>
      
      <Heading as="h1" size="ml" mt={8} mb={6} color="white">Никнейм контакты</Heading>
      {renderTable(nicknameContacts, 'nickname')}
      <Box overflowX="hidden" mt={4}>
      <Button
        leftIcon={<FaPlus />}
        onClick={() => handleAddRow('nickname')}
        mt={4}
        color="yellow.200"
        variant="outline"
        borderColor="yellow.200"
        borderWidth="1px"
        borderStyle="dashed"
        width="full"
        mb={20}
      >
        Добавить контакт
      </Button>
      </Box>
    </Box>
  );
};

export default ContactsTablesTab;*/