import React, { useState, useContext } from 'react';
import {
    Box,
    Stack,
    Heading,
    Input,
    Button,
    Text,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    useDisclosure,
    Link as ChakraLink
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext'; 
import PasswordRecoveryPage from './PasswordRecoveryPage';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;


const LoginPage = () => {
    const { setIsAuthenticated } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        userEmail: '',
        userPass: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const openPasswordRecoveryPage = () => {
        onOpen(); // Функция для открытия модального окна
      };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);
        try {
            const response = await axios.post(`${BACKEND_DOMAIN}/login`, formData, {
             withCredentials: true
           });

            // Если сервер устанавливает HTTPOnly cookie, нам не нужно сохранять или обрабатывать JWT на стороне клиента
            if (response.status === 200) {
                setIsAuthenticated(true);
                navigate('/dashboard'); // перенаправляем на дашборд при успешной авторизации
            } else {
                setError(response.data.message || 'Invalid credentials'); // обработка сообщений об ошибке с сервера
            }
        } catch (err) {
            setError('Invalid credentials');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Flex direction="column" h="100vh">
        <Box flex="1" textAlign="center" p="10" pt="100px" bg="gray.800">
            <Stack spacing="6" maxW="md" mx="auto">
                <Heading color="customGreen" mb="2">AirAds.Ai</Heading>
                <Text color="gray.400" fontSize="lg" mb="6">Вход</Text>
                {error && (
                    <Text color="red.500" mb="6" textAlign="center"> 
                        {error}
                    </Text>
                )}
                <form onSubmit={handleSubmit}>
                    <Input
                        placeholder="Email"
                        variant="filled"
                        mb="3"  
                        name="userEmail"
                        value={formData.userEmail}
                        onChange={handleChange}
                    />
                    <Input
                        placeholder="Пароль"
                        variant="filled"
                        mb="3"  
                        type="password"
                        name="userPass"
                        value={formData.userPass}
                        onChange={handleChange}
                    />
                    <Box textAlign="right" mb="3" mt="1">  
                        <ChakraLink color="gray.400" onClick={openPasswordRecoveryPage}>
                            Забыли пароль?
                        </ChakraLink>
                    </Box>
                    <Button colorScheme="green" width="full" size="lg" mb="2" type="submit" isLoading={isLoading}>
                   
                        Войти
                    </Button>
                </form>
                <Box textAlign="center">
                    <Text>
                    — или —
                    </Text>
                </Box>
                <Button as={Link} to="/registration" colorScheme="yellow" size="lg" mt="2" width="full">
      
                    Зарегистрироваться
                </Button>
            </Stack>
            <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
             <ModalContent>
               <ModalBody>
                 <PasswordRecoveryPage />
               </ModalBody>
             <ModalFooter>
               <Button onClick={onClose}>
                 Назад
               </Button>
             </ModalFooter>
            </ModalContent>
            </Modal>
        </Box>
        <Box bg="gray.800" p="6">
            {/*<Text textAlign="center" color="gray.400">
                <a href="#" style={{ textDecoration: 'none', color: 'inherit' }}>
                   ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ
                </a>
            </Text>*/}
        </Box>
    </Flex>
    );
};

export default LoginPage;




/*import React, { useState, useContext } from 'react';
import {
    Box,
    Stack,
    Heading,
    Input,
    Button,
    Text,
    Flex,
    Link as ChakraLink
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext'; 


const LoginPage = () => {
    const { setIsAuthenticated } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        userEmail: '',
        userPass: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);
        try {
            const response = await axios.post('https://server.postbackrouter.com/login', formData, {
             withCredentials: true
           });

            // Если сервер устанавливает HTTPOnly cookie, нам не нужно сохранять или обрабатывать JWT на стороне клиента
            if (response.status === 200) {
                setIsAuthenticated(true);
                navigate('/dashboard'); // перенаправляем на дашборд при успешной авторизации
            } else {
                setError(response.data.message || 'Invalid credentials'); // обработка сообщений об ошибке с сервера
            }
        } catch (err) {
            setError('Invalid credentials');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Flex direction="column" h="100vh">
        <Box flex="1" textAlign="center" p="10" pt="100px" bg="gray.800">
            <Stack spacing="6" maxW="md" mx="auto">
                <Heading color="customGreen" mb="6">PostBackRouter</Heading>
                {error && (
                    <Text color="red.500" mb="6" textAlign="center"> 
                        {error}
                    </Text>
                )}
                <form onSubmit={handleSubmit}>
                    <Input
                        placeholder="Email"
                        variant="filled"
                        mb="3"  
                        name="userEmail"
                        value={formData.userEmail}
                        onChange={handleChange}
                    />
                    <Input
                        placeholder="Password"
                        variant="filled"
                        mb="3"  
                        type="password"
                        name="userPass"
                        value={formData.userPass}
                        onChange={handleChange}
                    />
                    <Box textAlign="right" mb="3" mt="1">  
                        <ChakraLink color="gray.400">
                            Forgot password
                        </ChakraLink>
                    </Box>
                    <Button colorScheme="green" width="full" size="lg" mb="2" type="submit" isLoading={isLoading}>
                   
                        Log in
                    </Button>
                </form>
                <Box textAlign="center">
                    <Text>
                        𛰍 OR 𛰍
                    </Text>
                </Box>
                <Button as={Link} to="/registration" colorScheme="yellow" size="lg" mt="2" width="full">
      
                    Register
                </Button>
            </Stack>
        </Box>
        <Box bg="gray.800" p="6">
            <Text textAlign="center" color="gray.400">
                <a href="#" style={{ textDecoration: 'none', color: 'inherit' }}>
                    Privacy Policy
                </a>
            </Text>
        </Box>
    </Flex>
    );
};

export default LoginPage;*/




/*import React, { useState } from 'react';
import {
    Box,
    Stack,
    Heading,
    Input,
    Button,
    Text,
    Flex,
    Link as ChakraLink
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'; // Убедитесь, что axios установлен в вашем проекте

const LoginPage = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);
        try {
            // Отправьте данные формы на сервер
            await axios.post('https://server.postbackrouter.com/login', formData);
            // Перенаправьте пользователя на /dashboard при успешной авторизации
            navigate.push('/dashboard');
        } catch (err) {
            // Обработайте ошибку авторизации
            setError('Invalid credentials');
        } finally {
            setIsLoading(false);
        }
    };

    return (
      <Flex direction="column" h="100vh">
          <Box flex="1" textAlign="center" p="10" pt="100px" bg="gray.800">
              <Stack spacing="6" maxW="md" mx="auto">
                  <Heading color="customGreen" mb="6">PostBackRouter</Heading>
                  {error && (
                      <Text color="red.500" mb="6" textAlign="center"> 
                          {error}
                      </Text>
                  )}
                  <form onSubmit={handleSubmit}>
                      <Input
                          placeholder="Email"
                          variant="filled"
                          mb="3"  
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                      />
                      <Input
                          placeholder="Password"
                          variant="filled"
                          mb="3"  
                          type="password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                      />
                      <Box textAlign="right" mb="3" mt="1">  
                          <ChakraLink color="gray.400">
                              Forgot password
                          </ChakraLink>
                      </Box>
                      <Button colorScheme="green" width="full" size="lg" mb="2" type="submit" isLoading={isLoading}>
                     
                          Log in
                      </Button>
                  </form>
                  <Box textAlign="center">
                      <Text>
                          𛰍 OR 𛰍
                      </Text>
                  </Box>
                  <Button as={Link} to="/registration" colorScheme="yellow" size="lg" mt="2" width="full">
        
                      Register
                  </Button>
              </Stack>
          </Box>
          <Box bg="gray.800" p="6">
              <Text textAlign="center" color="gray.400">
                  <a href="#" style={{ textDecoration: 'none', color: 'inherit' }}>
                      Privacy Policy
                  </a>
              </Text>
          </Box>
      </Flex>
  );
};

export default LoginPage; */








/*import React from 'react';
import { 
    Box, 
    Stack, 
    Heading, 
    Input, 
    Button, 
    Text, 
    Flex, 
    Link as ChakraLink 
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const LoginPage = () => {
  return (
      <Flex direction="column" h="100vh">
        <Box flex="1" textAlign="center" p="10" pt="100px" bg="gray.800">
          <Stack spacing="6" maxW="md" mx="auto">
            <Heading color="customGreen" mb="6">PostBackRouter</Heading>
            <Input placeholder="Email" variant="filled" mb="1" />
            <Input placeholder="Password" variant="filled" mb="1" type="password" />
            <Box textAlign="right" mb="2" mt="-3">
              <ChakraLink color="gray.400">
                Forgot password
              </ChakraLink>
            </Box>
            <Button colorScheme="green" size="lg" mb="2">
              Log in
            </Button>
            <Box textAlign="center">
              <Text>
              𛰍 OR 𛰍
              </Text>
            </Box>
            <Button as={Link} to="/registration" colorScheme="yellow" size="lg" mt="2">
              Register
            </Button>
          </Stack>
        </Box>
        <Box bg="gray.800" p="6">
          <Text textAlign="center" color="gray.400">
            <a href="#" style={{ textDecoration: 'none', color: 'inherit' }}>
              Privacy Policy
            </a>
          </Text>
        </Box>
      </Flex>
  );
};

export default LoginPage;  */

