import React, { useState } from 'react';
import {
  Box,
  Text,
  Grid,
  IconButton,
  Input,
  Button,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';

const TransactionsPage = () => {
  const [balance, setBalance] = useState(0); // This will be set up later
  const [transactions, setTransactions] = useState([
    // Test data, replace with real data later
    { date: '2023-11-22 11:34', value: '+$500', },
    { date: '2023-11-21 16:47', value: '+$300', },
    // Add more transactions as needed
  ]);


  const balanceColor = balance > 100 
        ? "white"
        : (balance > 30 && balance < 100) 
            ? "orange.400"
            : "red.500"; 

  return (
    <Box p={8} width="100%">
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        mb="12"
        mt="4"
      >
        <Text fontSize="16px" fontWeight="bold" mr="8" color={balanceColor} >
          Баланс: ₽ {balance}
        </Text>
        <Button colorScheme="green">Пополнить</Button>
      </Box>

      <Text fontSize="14px" mb="4" textAlign="left">
        История:
      </Text>

      <Box 
  overflowY="auto"
  overflowX="hidden"
  height="300px"
  border="1px dashed" 
  borderColor="#5DA8DE"
  borderRadius="3px"
  p="4"
  width="100%"
>
  <Grid 
    templateColumns="5fr 3fr 2fr" 
    gap="3" 
    mb="4"
  >
    {transactions.map((transaction, index) => (
      <React.Fragment key={index}>
        <Box display="flex" alignItems="center" height="100%">
          <Text>{transaction.date}</Text>
        </Box>
        <Box display="flex" alignItems="center" height="100%">
          <Text color="green.500">{transaction.value}</Text>
        </Box>
        <Box height="100%">
          <IconButton
            aria-label="Download Check"
            icon={<CopyIcon />}
            size="md"
            width="100%"
            onClick={() => {/* Functionality to download check */}}
          />
        </Box>
      </React.Fragment>
    ))}
  </Grid>
</Box>



    </Box>
  );
};

export default TransactionsPage;
